import { Link } from "react-router-dom"
import { Box, Button, ListItem } from "@mui/material"
import { FC, ReactNode } from "react"

interface NavItemProps {
  href: string
  icon?: ReactNode
  title: string
  active: boolean
}

const NavItem: FC<NavItemProps> = ({ href, icon, title, active }) => {
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Button
        component={Link}
        to={href}
        startIcon={icon}
        disableRipple
        sx={{
          borderRadius: 1,
          px: 3,
          justifyContent: "flex-start",
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          backgroundColor: active ? "rgba(255,255,255, 0.08)" : "",
          color: active ? "secondary.main" : "neutral.300",
          // fontWeight: active ? "fontWeightBold" : "",
          "& .MuiButton-startIcon": {
            color: active ? "secondary.main" : "neutral.400",
          },
          "&:hover": {
            backgroundColor: "rgba(255,255,255, 0.08)",
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  )
}

export default NavItem
