import { combineReducers, configureStore } from "@reduxjs/toolkit"

import { supportApi } from "../services/supportService"
import { instituteApi } from "../services/instituteService"
import { newsApi } from "../services/newsService"
import { userApi } from "../services/userService"
import { staticApi } from "../services/staticService"
import { authApi } from "../services/authService"
import { fileApi } from "../services/fileService"
import { innovationApi } from "../services/innovationService"
import { miscApi } from "../services/miscService"

import { modalReducer } from "./reducers/modalSlice"
import { filterSupportReducer } from "./reducers/filterSupportSlice"
import { filterInstituteReducer } from "./reducers/filterInstituteSlice"
import { filterNewsReducer } from "./reducers/filterNewsSlice"
import { filterStaticReducer } from "./reducers/filterStaticSlice"
import { selectReducer } from "./reducers/selectSlice"
import { filterUserReducer } from "./reducers/filterUserSlice"
import { filterInnovationReducer } from "./reducers/filterInnovationSlice"

const rootReducer = combineReducers({
  modalReducer,
  selectReducer,
  filterSupportReducer,
  filterInstituteReducer,
  filterNewsReducer,
  filterStaticReducer,
  filterUserReducer,
  filterInnovationReducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [instituteApi.reducerPath]: instituteApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [staticApi.reducerPath]: staticApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [innovationApi.reducerPath]: innovationApi.reducer,
  [miscApi.reducerPath]: miscApi.reducer,
})

export const initStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        supportApi.middleware,
        instituteApi.middleware,
        newsApi.middleware,
        userApi.middleware,
        staticApi.middleware,
        authApi.middleware,
        fileApi.middleware,
        innovationApi.middleware,
        miscApi.middleware,
      ),
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof initStore>
export type AppDispatch = AppStore["dispatch"]
