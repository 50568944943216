import { FC } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Typography } from "@mui/material"

import InputGroup from "../groupedInput/InputGroup"
import { Innovation } from "../../types/innovations"
import LogoInputGroup from "../groupedInput/LogoInputGroup"
import { useFileSelect } from "../../hooks/useFileSelect"
import { innovationApi } from "../../services/innovationService"

import InnovationSelect from "./Inputs/InnovationSelect"

interface ShortInfoProps {
  innovation?: Innovation
}

const ShortInfo: FC<ShortInfoProps> = ({ innovation }) => {
  const { id } = useParams()

  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedStages } = innovationApi.useGetStagesQuery()
  const [deleteWallpaper] = innovationApi.useDeleteWallpaperMutation()
  const [deleteLogo] = innovationApi.useDeleteLogoMutation()

  const {
    logoFileName: wallpaperFileName,
    onFileSelect: onWallpaperSelect,
    setLogoFileName: setWallpaperFileName,
    setLogoFile: setWallpaperFile,
  } = useFileSelect(innovation?.wallpaper?.split("/").at(-1))

  const { logoFileName, onFileSelect, setLogoFileName, setLogoFile } =
    useFileSelect(innovation?.logo?.split("/").at(-1))

  const onWallpaperDelete = (input: HTMLInputElement | null) => {
    if (input) input.value = ""

    if (!id) return

    if (innovation?.wallpaper) {
      deleteWallpaper(+id).then(() => {
        toast.warning("Успешно удалено")
        setWallpaperFile(null)
        setWallpaperFileName("")
      })

      return
    }

    if (wallpaperFileName) {
      setWallpaperFileName("")
      setWallpaperFile(null)
    }
  }

  const onLogoDelete = (input: HTMLInputElement | null) => {
    if (input) input.value = ""

    if (!id) return

    if (innovation?.logo) {
      deleteLogo(+id).then(() => {
        toast.warning("Успешно удалено")
        setLogoFile(null)
        setLogoFileName("")
      })

      return
    }

    if (logoFileName) {
      setLogoFileName("")
      setLogoFile(null)
    }
  }

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Краткая информация о проекте
      </Typography>

      <InputGroup
        title="Название проекта"
        textFieldProps={{
          name: "innovation_name",
          defaultValue: innovation?.innovation_name,
        }}
      />

      <LogoInputGroup
        title="Заставка проекта"
        fetchedLogo={innovation?.wallpaper}
        logoFileName={wallpaperFileName}
        handleFileSelect={onWallpaperSelect}
        onLogoDelete={onWallpaperDelete}
        name="wallpaper"
      />

      <LogoInputGroup
        title="Логотип проекта или компании"
        fetchedLogo={innovation?.logo}
        logoFileName={logoFileName}
        handleFileSelect={onFileSelect}
        onLogoDelete={onLogoDelete}
        name="logo"
      />

      {defaultFields?.description?.active && (
        <InputGroup
          title={defaultFields?.description.label}
          textFieldProps={{
            name: "description",
            defaultValue: innovation?.description,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      {defaultFields?.keywords?.active && (
        <InputGroup
          title={defaultFields?.keywords.label}
          textFieldProps={{
            name: "keywords",
            defaultValue: innovation?.keywords,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      {fetchedStages?.data && (
        <InnovationSelect
          title="Стадия проекта"
          name="id_stages"
          defaultValue={innovation?.id_stages}
          values={fetchedStages.data}
          idProp="id_stages"
          valueProp="stage_name"
        />
      )}

      {defaultFields?.site?.active && (
        <InputGroup
          title={defaultFields.site.label}
          textFieldProps={{
            name: "site",
            defaultValue: innovation?.site,
            type: "url",
          }}
        />
      )}
    </>
  )
}

export default ShortInfo
