import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button, Paper, Typography } from "@mui/material"

import { innovationApi } from "../../services/innovationService"
import { InnovationDefaultField } from "../../types/innovations"
import Spinner from "../Spinner"

import DefaultFieldEditor from "./DefaultFieldEditor"

const DefaultFieldList = () => {
  const { data: fetchedDefaultFields, isLoading } =
    innovationApi.useGetDefaultFieldsQuery()
  const [
    saveDefaultFields,
    { data: saveDefaultFieldsResponse, isLoading: saveFieldsLoading },
  ] = innovationApi.useSaveDefaultFieldsMutation()

  const [defaultFields, setDefaultFields] = useState<
    InnovationDefaultField[] | null
  >(null)

  const onDefaultFieldsSave = () => {
    defaultFields &&
      saveDefaultFields(
        defaultFields.map((field) => ({
          id_default_fields: field.id_default_fields,
          visible: field.visible,
          active: field.active,
          required: field.required,
          public: field.public,
          tooltip: field.tooltip,
        })),
      )
  }

  const onDefaultFieldEdit = useCallback(
    ({
      id,
      value,
      key,
    }: {
      id: number
      key: keyof InnovationDefaultField
      value: boolean | string
    }) => {
      if (!defaultFields) return

      setDefaultFields(
        defaultFields.map((field) => {
          if (field.id_default_fields === id) {
            return { ...field, [key]: value }
          }

          return field
        }),
      )
    },
    [defaultFields],
  )

  useEffect(() => {
    if (!fetchedDefaultFields) return

    setDefaultFields(Object.values(fetchedDefaultFields))
  }, [fetchedDefaultFields])

  useEffect(() => {
    if (!saveDefaultFieldsResponse) return

    if (saveDefaultFieldsResponse.status === "error") {
      toast.error("Неизвестная ошибка")

      return
    }

    if (saveDefaultFieldsResponse.status === "successful") {
      toast.success("Успешно сохранено")
    }
  }, [saveDefaultFieldsResponse])

  return (
    <Paper sx={{ p: 2, textAlign: "center" }}>
      <Typography
        variant="h6"
        component="h6"
        sx={{ textAlign: "center", mb: 2, fontWeight: 700 }}
      >
        Настройка стандартных полей формы
      </Typography>
      {isLoading ? (
        <Spinner sx={{}} />
      ) : (
        <>
          {defaultFields?.map((field) => (
            <DefaultFieldEditor
              key={field.id_default_fields}
              field={field}
              onChange={onDefaultFieldEdit}
            />
          ))}
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            disabled={saveFieldsLoading}
            onClick={onDefaultFieldsSave}
          >
            Сохранить
          </Button>
        </>
      )}
    </Paper>
  )
}

export default DefaultFieldList
