import { FC, useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { toast } from "react-toastify"

import { InnovationAdditionalField } from "../../types/innovations"
import { innovationApi } from "../../services/innovationService"
import DeleteModal from "../modals/DeleteModal"

interface AdditionalFieldEditorProps {
  field: InnovationAdditionalField
  onEditModalOpen: () => void
}

const AdditionalFieldEditor: FC<AdditionalFieldEditorProps> = ({
  field,
  onEditModalOpen,
}) => {
  const [deleteField, { data: deleteFieldResponse }] =
    innovationApi.useDeleteAdditionalFieldMutation()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const onDelete = () => {
    deleteField(field.id_innovation_fields)
  }

  useEffect(() => {
    if (!deleteFieldResponse) return

    if (deleteFieldResponse.status === "error") {
      toast.error("Неизвестная ошибка")

      return
    }

    if (deleteFieldResponse.status === "successful") {
      toast.error("Поле успешно удалено")
    }
  }, [deleteFieldResponse])

  return (
    <Box
      sx={{
        backgroundColor: "neutral.100",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
        borderRadius: "8px",
        mt: 1,
      }}
    >
      <Typography sx={{ fontWeight: 700 }} component="span">
        {field.label}
      </Typography>
      <Box>
        <Button
          color="warning"
          variant="contained"
          size="small"
          onClick={onEditModalOpen}
          sx={{ mr: 2 }}
        >
          Редактировать
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Удалить
        </Button>
      </Box>
      <DeleteModal
        onDelete={onDelete}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        text="Вы действительно хотите удалить данное поле?"
      />
    </Box>
  )
}

export default AdditionalFieldEditor
