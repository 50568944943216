import { FC } from "react"
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"

import { InnovationDefaultField } from "../../types/innovations"
import InputGroup from "../groupedInput/InputGroup"

interface DefaultFieldEditorProps {
  field: InnovationDefaultField
  onChange: (params: {
    id: number
    key: keyof InnovationDefaultField
    value: boolean | string
  }) => void
}

const DefaultFieldEditor: FC<DefaultFieldEditorProps> = ({
  field,
  onChange,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ fontWeight: 700, textAlign: "left" }}>
        {field.label}
      </Typography>
      <Box>
        <FormGroup sx={{ flexDirection: "row", gap: "30px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={field.public}
                onChange={(e) =>
                  onChange({
                    id: field.id_default_fields,
                    key: "public",
                    value: e.target.checked,
                  })
                }
              />
            }
            label="публичное"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={field.visible}
                onChange={(e) =>
                  onChange({
                    id: field.id_default_fields,
                    key: "visible",
                    value: e.target.checked,
                  })
                }
              />
            }
            label="видимое для всех"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={field.required}
                onChange={(e) =>
                  onChange({
                    id: field.id_default_fields,
                    key: "required",
                    value: e.target.checked,
                  })
                }
              />
            }
            label="обязательное"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={field.active}
                onChange={(e) =>
                  onChange({
                    id: field.id_default_fields,
                    key: "active",
                    value: e.target.checked,
                  })
                }
              />
            }
            label="вкл"
            labelPlacement="start"
          />
        </FormGroup>
        <InputGroup
          placeholder="Подсказка"
          value={field.tooltip}
          onChange={(e) =>
            onChange({
              id: field.id_default_fields,
              key: "tooltip",
              value: e.target.value,
            })
          }
        />
      </Box>
    </Box>
  )
}

export default DefaultFieldEditor
