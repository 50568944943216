import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { Box, Container, Typography } from "@mui/material"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import StaticToolbar from "../components/toolbars/StaticsToolbar"
import ItemsTable from "../components/ItemsTable"
import CreateModal from "../components/modals/CreateModal"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { staticApi } from "../services/staticService"
import { STATICS_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import { Static, STATIC_SORTS, StaticSortsKeys } from "../types/statics"
import ItemsManage from "../components/ItemsManage"
import Spinner from "../components/Spinner"
import DeleteModal from "../components/modals/DeleteModal"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"

const StaticsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchStatics, { data: fetchedStatics, isFetching }] =
    staticApi.useLazyGetStaticsQuery()
  const { data: fetchedStatuses } = staticApi.useGetStatusesQuery()
  const [createStatic, { data: createdStatic }] =
    staticApi.useCreateStaticMutation()
  const [deleteStatic] = staticApi.useDeleteStaticMutation()
  const [changeStatuses] = staticApi.useChangeStatusesMutation()

  const filter = useTypedSelector((state) => state.filterStaticReducer)
  const selectedStatics = useTypedSelector(
    (state) => state.selectReducer.statics,
  )
  const { deleteModalOpen, createModalOpen } = useTypedSelector(
    (state) => state.modalReducer.statics,
  )
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: STATIC_SORTS.id_static_pages, headerName: "ID" },
      { field: STATIC_SORTS.title, headerName: "Заголовок" },
      { field: STATIC_SORTS.status_name, headerName: "Статус" },
      { field: STATIC_SORTS.date_creation, headerName: "Добавлено" },
    ],
    [],
  )

  const rowTransform = (statics: Static[]) => {
    return statics.map((s) => ({
      ...s,
      id: s.id_static_pages,
      date_creation: <SpanNoWrap>{s.date_creation}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedStatics) return []

    return rowTransform(fetchedStatics.list)
  }, [fetchedStatics])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id_static_pages)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedStatics?.count ?? 0) / filter.page_size),
    [fetchedStatics?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${STATIC_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(() => getQueryString(filter), [filter])

  const onAllRowsSelect = () => {
    if (selectedStatics.length !== rows.length) {
      actions.setSelectAll({ key: "statics", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "statics", value: [] })
    }
  }

  const onCreate = (name: string) => {
    createStatic(name).then(() => {
      toast.success("Статичная страница успешно создана!")
      actions.setIsCreateModalOpen({ key: "statics", value: false })
    })
  }

  const onDelete = () => {
    deleteStatic(selectedStatics).then(() => {
      toast.success("Записи удалены")
      actions.clearSelection("statics")
    })
  }

  const onStatusChange = (statusId: number) => {
    changeStatuses(
      selectedStatics.map((id) => ({
        id_static_pages: id,
        id_static_page_statuses: statusId,
      })),
    ).then(() => {
      toast.success("Статус успешно изменен")
      actions.clearSelection("statics")
    })
  }

  const onSortChange = (property: string) => {
    actions.setStaticOrder(property as StaticSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setStaticPage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setStaticPageSize(amount),
    [actions],
  )

  const fetchSupportsFunc = useCallback(() => {
    fetchStatics({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        status: debouncedFilter.statuses.join(","),
        title: debouncedFilter.search,
        sort: debouncedSort,
      },
    })
  }, [fetchStatics, debouncedFilter, debouncedSort])

  useEffect(() => {
    actions.setStaticFilter(getObjectFromQuery(location.search, ["statuses"]))
  }, [actions, location.search])

  useEffect(() => {
    fetchSupportsFunc()
  }, [fetchSupportsFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    createdStatic &&
      navigate(STATICS_PAGE_ROUTE + `/${createdStatic.id_static_pages}`)
  }, [createdStatic, navigate])

  return (
    <Layout title="Статичные страницы - ЦТТ Админ-панель" tabKey="statics">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <StaticToolbar title="Статичные страницы" />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="statics"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedStatics.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                rows={rows}
                columns={COLUMNS}
                route={STATICS_PAGE_ROUTE}
                type="statics"
                order={filter.order}
                orderBy={filter.orderBy}
                onSortChange={onSortChange}
                onAllSelect={onAllRowsSelect}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <CreateModal
        title="Добавить статичную страницу"
        isOpen={createModalOpen}
        onClose={() =>
          actions.setIsCreateModalOpen({ key: "statics", value: false })
        }
        onSubmit={onCreate}
      />
      <ChangeStatusModal
        title="Изменить статус"
        property="id_static_page_statuses"
        name="status_name"
        isOpen={isChangeStatusModalOpen}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        statuses={fetchedStatuses?.data ?? []}
        onSubmit={onStatusChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранные страницы?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "statics", value: false })
        }
      />
    </Layout>
  )
}

export default StaticsPage
