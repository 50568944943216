import { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { toast } from "react-toastify"

import { authRoutes, publicRoutes } from "../routes/routes"
import { authApi } from "../services/authService"
import { LOGIN_PAGE_ROUTE } from "../consts/routes"

import Spinner from "./Spinner"

const AppRouter = () => {
  const { data: user, isLoading } = authApi.useGetIdQuery()

  useEffect(() => {
    if (user) {
      const { userid: id, adminlog } = user

      if (id === undefined || adminlog === 6) {
        toast.warning("Необходимо войти в систему")
      }
    }
  }, [user])

  return (
    <>
      {isLoading ? (
        <Spinner sx={{ position: "fixed", top: "50%", left: "50%" }} />
      ) : (
        <Routes>
          {authRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                user?.userid &&
                (user?.adminlog === 1 || user?.adminlog === 5) ? (
                  <Component />
                ) : (
                  <Navigate to={LOGIN_PAGE_ROUTE} />
                )
              }
            />
          ))}

          {publicRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      )}
    </>
  )
}

export default AppRouter
