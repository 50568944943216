import { FormEvent, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material"
import { toast } from "react-toastify"
import dayjs from "dayjs"

import { innovationApi } from "../services/innovationService"
import Layout from "../components/Layout"
import Spinner from "../components/Spinner"
import { FormStyled } from "../components/styled/StyledComponents"
import ShortInfo from "../components/innovationEdit/ShortInfo"
import DetailedInfo from "../components/innovationEdit/DetailedInfo"
import AdditionalInfo from "../components/innovationEdit/AdditionalInfo"
import Files from "../components/innovationEdit/Files"
import Team from "../components/innovationEdit/Team"
import Demand from "../components/innovationEdit/Demand"
import { INNOVATIONS_PAGE_ROUTE } from "../consts/routes"
import { getInnovationFromForm } from "../utils/getInnovationFromForm"
import InnovationSelect from "../components/innovationEdit/Inputs/InnovationSelect"
import InputGroup from "../components/groupedInput/InputGroup"

const InnovationPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: fetchedStatuses } = innovationApi.useGetStatusesQuery()
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: additionalFields } = innovationApi.useGetAdditionalFieldsQuery()
  const [fetchInnovation, { data: fetchedInnovation }] =
    innovationApi.useLazyGetInnovationQuery()
  const [saveInnovation, { data: savedInnovation, isLoading }] =
    innovationApi.useSaveInnovationMutation()

  const onSave = async (e: FormEvent) => {
    e.preventDefault()

    if (!id || !+id) return

    if (!defaultFields) return

    if (!additionalFields?.data) return

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formValues = Object.fromEntries(formData.entries())

    const innovation = await getInnovationFromForm(
      formValues,
      defaultFields,
      additionalFields.data,
    )

    innovation.id_innovations = +id

    saveInnovation(innovation)
  }

  const creationDate = useMemo(() => {
    const dateString = fetchedInnovation?.data?.date_creation

    if (!dateString) return ""

    const timezoneOffset = new Date().getTimezoneOffset()

    const isoDate = new Date(
      new Date(dateString).getTime() - timezoneOffset * 60000,
    ).toISOString()

    return dayjs(isoDate).format("YYYY-MM-DDTHH:mm")
  }, [fetchedInnovation?.data?.date_creation])

  useEffect(() => {
    if (!id || !+id) {
      navigate(INNOVATIONS_PAGE_ROUTE)

      return
    }

    fetchInnovation(+id)
  }, [fetchInnovation, id, navigate])

  useEffect(() => {
    if (!savedInnovation) return

    if (savedInnovation.status === "error") {
      toast.error("Произошла ошибка. Попробуйте позже")
    } else {
      toast.success("Изменения успешно сохранены")
    }
  }, [savedInnovation])

  if (!fetchedInnovation) {
    return (
      <Layout
        title="Редактирование инновационной разработки - ЦТТ Админ-панель"
        tabKey="innovations"
      >
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout
      title="Редактирование инновационной разработки - ЦТТ Админ-панель"
      tabKey="innovations"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h1">
            Редактирование инновационной разработки
          </Typography>

          <Paper sx={{ mt: 3 }}>
            <Box
              component={FormStyled}
              autoComplete="off"
              name="innovationForm"
              onSubmit={onSave}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {fetchedInnovation.data && fetchedStatuses && (
                    <InnovationSelect
                      name="id_innovation_statuses"
                      title="Статус инновации"
                      values={fetchedStatuses.data ?? []}
                      idProp="id_innovation_statuses"
                      valueProp="status_name"
                      defaultValue={
                        fetchedInnovation.data.id_innovation_statuses
                      }
                    />
                  )}

                  <InputGroup
                    title="Дата добавления"
                    textFieldProps={{
                      name: "date_creation",
                      defaultValue: creationDate,
                      type: "datetime-local",
                    }}
                    sx={{ width: "auto" }}
                  />

                  <ShortInfo innovation={fetchedInnovation.data} />

                  <Team innovation={fetchedInnovation.data} />

                  <DetailedInfo innovation={fetchedInnovation.data} />

                  <Demand innovation={fetchedInnovation.data} />

                  {additionalFields?.data && (
                    <AdditionalInfo
                      innovation={fetchedInnovation.data}
                      additionalFields={additionalFields.data}
                    />
                  )}

                  <Files innovation={fetchedInnovation.data} />

                  <Box sx={{ mt: 3, width: "25%" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Сохранение..." : "Сохранить"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Layout>
  )
}

export default InnovationPage
