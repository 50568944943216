import { Dispatch, SetStateAction, useState } from "react"

type FuncReturnType = [
  number[],
  Dispatch<SetStateAction<number[]>>,
  (id: number) => void,
]

export function useCheckboxList(initArray?: number[]): FuncReturnType {
  const [selected, setSelected] = useState<number[]>(() => initArray ?? [])

  const onSelect = (id: number) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((selectedId) => selectedId !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  return [selected, setSelected, onSelect]
}
