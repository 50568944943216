import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { InnovationSortsKeys } from "../../types/innovations"

interface InitialStateArrays {
  innovation_markets: number[]
  industries: number[]
  status: number[]
}

interface InitialState extends InitialStateArrays {
  search: string
  order: "asc" | "desc"
  orderBy: InnovationSortsKeys | null
  page: number
  page_size: number
  fundraising: boolean | null
  legal_entity: boolean | null
}

export type FilterInnovationSliceStateKeys = keyof InitialStateArrays

interface SetFilterArrayType {
  key: FilterInnovationSliceStateKeys
  value: number[]
}

type BooleanPayload = {
  key: "fundraising" | "legal_entity"
  value: boolean
}

const initialState: InitialState = {
  search: "",
  order: "asc",
  orderBy: null,
  page: 1,
  page_size: 20,
  innovation_markets: [],
  industries: [],
  status: [],
  fundraising: null,
  legal_entity: null,
}

export const filterInnovationSlice = createSlice({
  name: "filterInnovation",
  initialState,
  reducers: {
    setInnovationSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.page = 1
    },
    setInnovationOrder(
      state,
      action: PayloadAction<InnovationSortsKeys | null>,
    ) {
      const orderBy = action.payload

      if (orderBy === state.orderBy) {
        state.order = state.order === "asc" ? "desc" : "asc"

        return
      }

      state.orderBy = orderBy
      state.order = "asc"
    },
    setInnovationFilterArray(state, action: PayloadAction<SetFilterArrayType>) {
      state[action.payload.key] = action.payload.value
      state.page = 1
    },
    setInnovationPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setInnovationPageSize(state, action: PayloadAction<number>) {
      state.page_size = action.payload
    },
    setInnovationBoolean(state, action: PayloadAction<BooleanPayload>) {
      const { key, value } = action.payload

      state[key] = value
    },
    setInnovationFilter(state, action: PayloadAction<Partial<InitialState>>) {
      for (const key in action.payload) {
        if (initialState.hasOwnProperty(key)) {
          //@ts-ignore
          state[key] = action.payload[key]
        }
      }
    },
    resetInnovationFilters(state) {
      state.search = ""
      state.innovation_markets = []
      state.industries = []
      state.status = []
      state.fundraising = false
      state.legal_entity = false
    },
  },
})

export const filterInnovationReducer = filterInnovationSlice.reducer
