import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import dayjs, { Dayjs } from "dayjs"
import Box from "@mui/material/Box"
import {
  Checkbox,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material"
import Button from "@mui/material/Button"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import CheckboxList from "../components/groupedInput/CheckboxList"
import { FormStyled, LabelStyled } from "../components/styled/StyledComponents"
import { SUPPORTS_PAGE_ROUTE } from "../consts/routes"
import { supportApi } from "../services/supportService"
import Spinner from "../components/Spinner"
import SelectGroup from "../components/groupedInput/SelectGroup"
import { instituteApi } from "../services/instituteService"
import DateGroup from "../components/groupedInput/DateGroup"
import InputGroup from "../components/groupedInput/InputGroup"
import HTMLEditorGroup from "../components/groupedInput/HTMLEditorGroup"
import { useCheckboxList } from "../hooks/useCheckboxList"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import DeleteModal from "../components/modals/DeleteModal"

const SupportPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const actions = useActionCreators()

  const [fetchSupport, { data: fetchedSupport }] =
    supportApi.useLazyGetSupportQuery()
  const [saveSupport, { isLoading }] = supportApi.useSaveSupportMutation()
  const [deleteSupport] = supportApi.useDeleteSupportsMutation()
  const { data: statuses } = supportApi.useGetSupportStatusesQuery()
  const { data: forms } = supportApi.useGetSupportFormsQuery()
  const { data: members } = supportApi.useGetSupportMembersQuery()
  const { data: directions } = supportApi.useGetSupportDirectionsQuery()
  const { data: reasons } = supportApi.useGetSupportReasonsQuery()
  const { data: institutes } = instituteApi.useGetAllInstitutesQuery()

  const isOpen = useTypedSelector(
    (state) => state.modalReducer.supports.deleteModalOpen,
  )

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [amount, setAmount] = useState(0)
  const [instituteId, setInstituteId] = useState("")
  const [isNoFinance, setIsNoFinance] = useState(false)
  const [metaTitle, setMetaTitle] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [statusId, setStatusId] = useState(1)
  const [createDate, setCreateDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [isNoDeadline, setIsNoDeadline] = useState(true)
  const [formsId, setFormsId, onFormSelect] = useCheckboxList()
  const [directionsId, setDirectionsId, onDirectionSelect] = useCheckboxList()
  const [membersId, setMembersId, onMemberSelect] = useCheckboxList()
  const [reasonsId, setReasonsId, onReasonSelect] = useCheckboxList()

  const onSave = async () => {
    if (!fetchedSupport) return

    const { data: support } = fetchedSupport

    if (!support) return

    const response = await saveSupport({
      id_supports: support.id_supports,
      id_support_statuses: statusId,
      support_name: support.support_name,
      support_name_manual: name,
      site: url,
      description,
      date_creation: createDate?.format("YYYY-MM-DD HH:mm:ss"),
      date_end: isNoDeadline
        ? undefined
        : endDate?.format("YYYY-MM-DD HH:mm:ss"),
      no_deadline: isNoDeadline,
      amount: isNoFinance ? undefined : amount,
      no_finance: isNoFinance,
      support_forms_id: formsId.join(","),
      support_directions_id: directionsId.join(","),
      support_members_id: membersId.join(","),
      support_reasons_id: reasonsId.join(","),
      id_institutes: +instituteId,
      title_meta: metaTitle,
      keywords_meta: metaKeywords,
      description_meta: metaDescription,
    }).unwrap()

    if (response.status === "error") {
      toast.error("Не удалось сохранить")
    } else {
      toast.success("Успешно сохранено")
    }
  }

  const onDelete = () => {
    id &&
      deleteSupport([+id]).then(() => {
        toast.success("Успешно удалено")
        navigate(SUPPORTS_PAGE_ROUTE)
      })
  }

  useEffect(() => {
    id && fetchSupport(+id)
  }, [fetchSupport, id])

  useEffect(() => {
    if (!fetchedSupport?.data) return

    const { data } = fetchedSupport

    setName(data.support_name_manual)
    setStatusId(data.id_support_statuses)
    setCreateDate(dayjs(data.date_creation))
    setFormsId(data.support_forms.map((form) => form.id_support_forms))
    setDirectionsId(
      data.support_directions.map((dir) => dir.id_support_directions),
    )
    setMembersId(
      data.support_members.map((member) => member.id_support_members),
    )
    setReasonsId(
      data.support_reasons.map((reason) => reason.id_support_reasons),
    )

    data.description !== null && setDescription(data.description)
    data.site !== null && setUrl(data.site)
    data.amount !== null && setAmount(data.amount)
    data.id_institutes !== null && setInstituteId(`${data.id_institutes}`)
    data.no_finance !== null && setIsNoFinance(data.no_finance)
    data.title_meta !== null && setMetaTitle(data.title_meta)
    data.keywords_meta !== null && setMetaKeywords(data.keywords_meta)
    data.description_meta !== null && setMetaDescription(data.description_meta)
    data.date_end !== null && setEndDate(dayjs(data.date_end))
    data.no_deadline !== null && setIsNoDeadline(data.no_deadline)
  }, [fetchedSupport, setDirectionsId, setFormsId, setMembersId, setReasonsId])

  if (!fetchedSupport) {
    return (
      <Layout
        title="Редактирование поддержки - ЦТТ Админ-панель"
        tabKey="support_measures"
      >
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout
      title="Редактирование поддержки - ЦТТ Админ-панель"
      tabKey="support_measures"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4">
            Редактирование меры поддержки
          </Typography>
          <Paper sx={{ mt: 3 }}>
            <Box component={FormStyled} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputGroup
                    title="Название поддержки"
                    value={name}
                    Helper={
                      <span>
                        <b>Исходное название:</b>{" "}
                        {fetchedSupport?.data?.support_name}
                      </span>
                    }
                    onChange={(e) => setName(e.target.value)}
                  />

                  <HTMLEditorGroup
                    title="Описание поддержки"
                    value={description}
                    setValue={(e) => setDescription(e)}
                  />

                  <InputGroup
                    title="Сайт"
                    value={url}
                    Helper={
                      <span>
                        Сайт необходимо указывать в формате{" "}
                        <b>https://example.ru</b>
                      </span>
                    }
                    onChange={(e) => setUrl(e.target.value)}
                  />

                  <InputGroup
                    title="Размер поддержки"
                    sx={{ width: "50%" }}
                    value={amount}
                    disabled={isNoFinance}
                    endAdornment={{
                      endAdornment: (
                        <InputAdornment position="start">
                          Тысяч рублей
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setAmount(+e.target.value)}
                  />

                  <LabelStyled>
                    <Checkbox
                      checked={isNoFinance}
                      onChange={(e) => setIsNoFinance(e.target.checked)}
                    />
                    Нефинансовая мера поддержки
                  </LabelStyled>

                  <SelectGroup
                    title="Институт"
                    property="id_institutes"
                    name="institute_name"
                    selectedValue={instituteId}
                    values={institutes?.data ?? []}
                    onChange={(e) => {
                      const value = e.target.value
                      typeof value === "string" && setInstituteId(value)
                    }}
                  />

                  <InputGroup
                    title="Заголовок meta"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />

                  <InputGroup
                    title="Ключевые слова meta"
                    value={metaKeywords}
                    onChange={(e) => setMetaKeywords(e.target.value)}
                  />

                  <InputGroup
                    title="Описание meta"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectGroup
                    title="Статус"
                    property="id_support_statuses"
                    name="status_name"
                    sx={{ width: "50%" }}
                    selectedValue={`${statusId}`}
                    values={statuses?.data ?? []}
                    onChange={(e) => setStatusId(+e.target.value)}
                  />

                  <DateGroup
                    title="Дата публикации"
                    value={createDate}
                    onChange={(newValue: any) => setCreateDate(newValue)}
                  />

                  <CheckboxList
                    title="Формы поддержки"
                    property="id_support_forms"
                    name="support_form_name"
                    items={forms?.data ?? []}
                    selectedIds={formsId}
                    onSelect={onFormSelect}
                  />

                  <CheckboxList
                    title="Направления"
                    property="id_support_directions"
                    name="support_direction_name"
                    items={directions?.data ?? []}
                    selectedIds={directionsId}
                    onSelect={onDirectionSelect}
                  />

                  <CheckboxList
                    title="Участники"
                    property="id_support_members"
                    name="support_members_name"
                    items={members?.data ?? []}
                    selectedIds={membersId}
                    onSelect={onMemberSelect}
                  />

                  <CheckboxList
                    title="Потребность"
                    property="id_support_reasons"
                    name="support_reason_name"
                    items={reasons?.data ?? []}
                    selectedIds={reasonsId}
                    onSelect={onReasonSelect}
                  />

                  <DateGroup
                    title="Дата окончания подачи заявки"
                    value={isNoDeadline ? null : endDate}
                    onChange={(date: any) => setEndDate(date)}
                    disabled={isNoDeadline}
                  />

                  <LabelStyled>
                    <Checkbox
                      checked={isNoDeadline}
                      onChange={(e) => setIsNoDeadline(e.target.checked)}
                    />
                    Срок не ограничен
                  </LabelStyled>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Button
                  sx={{ mr: "10px" }}
                  color="error"
                  variant="contained"
                  onClick={() =>
                    actions.setIsDeleteModalOpen({
                      key: "supports",
                      value: true,
                    })
                  }
                >
                  Удалить
                </Button>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={onSave}
                >
                  Сохранить
                </Button>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
      <DeleteModal
        text="Вы точно хотите удалить эту меру поддержки?"
        onDelete={onDelete}
        isOpen={isOpen}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "supports", value: false })
        }
      />
    </Layout>
  )
}

export default SupportPage
