import { useState } from "react"
import { Button, Paper, Typography } from "@mui/material"

import AdditionalFieldModal from "../modals/AdditionalFieldModal"
import { innovationApi } from "../../services/innovationService"
import { InnovationAdditionalField } from "../../types/innovations"
import Spinner from "../Spinner"

import AdditionalFieldEditor from "./AdditionalFieldEditor"

const AdditionalFieldList = () => {
  const { data: additionalFields, isLoading } =
    innovationApi.useGetAdditionalFieldsQuery()

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [editingField, setEditingField] =
    useState<InnovationAdditionalField | null>(null)

  const onModalOpen = () => setIsAddModalOpen(true)

  return (
    <Paper sx={{ p: 2 }}>
      <Typography
        variant="h6"
        component="h6"
        sx={{ textAlign: "center", mb: 2, fontWeight: 700 }}
      >
        Настройка дополнительных полей формы
      </Typography>
      {isLoading ? (
        <Spinner
          sx={{ position: "relative", left: "50%", width: "fit-content" }}
        />
      ) : (
        <>
          <Button
            variant="contained"
            color="success"
            onClick={onModalOpen}
            sx={{ mb: 3 }}
          >
            Добавить
          </Button>
          {additionalFields?.data?.map((field) => (
            <AdditionalFieldEditor
              key={field.id_innovation_fields}
              field={field}
              onEditModalOpen={() => {
                setEditingField(field)
                onModalOpen()
              }}
            />
          ))}
        </>
      )}
      {isAddModalOpen && (
        <AdditionalFieldModal
          onClose={() => {
            setIsAddModalOpen(false)
            setEditingField(null)
          }}
          type={editingField ? "edit" : "add"}
          field={editingField ?? undefined}
        />
      )}
    </Paper>
  )
}

export default AdditionalFieldList
