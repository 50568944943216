import { IDefaultSearch, Region } from "./common"

export interface InstituteSearch extends IDefaultSearch {
  institution_forms: string
  support_forms: string
  support_members: string
  institute_name: string
}

export interface InstituteStatus {
  id_institution_statuses: number
  status_name: string
}

export interface InstituteForm {
  id_institution_forms: number
  institution_form_name: string
}

export interface InstituteShort {
  id_institutes: number
  institute_name: string
}

export interface Institute {
  id_institutes: number
  logo: string | null
  institute_name: string
  status_name: string
  logo_thumb: string
  id_institution_statuses: number
  description: string | null
  institution_statuses_id_institution_statuses: number
  date_creation: string
  date_creation_dd: string
  count_support: number
  institution_forms: InstituteForm[]
  regions: Region[]
  site: string | null
  logo_selected?: string

  title_meta: string | null
  keywords_meta: string | null
  description_meta: string | null

  id?: string
}

export interface Institutes {
  list: Institute[]
  count: number
}

export enum INSTITUTES_SORTS {
  id_institutes = "id_institutes",
  institute_name = "institute_name",
  status_name = "status_name",
  count_support = "count_support",
  date_creation = "date_creation",
}

export type InstituteSortsKeys = keyof typeof INSTITUTES_SORTS

export interface InstituteSave {
  id_institutes: number
  id_institution_statuses?: number
  institute_name?: string
  site?: string
  description?: string
  institution_regions_id?: string
  institution_forms_id?: string
  logo?: string

  title_meta?: string
  keywords_meta?: string
  description_meta?: string
}

export interface CreatedInstitute {
  id_institutes: string
  status: string
}

export interface ChangeInstituteStatus {
  id_institutes: number
  id_institution_statuses: number
}
