import { FC, memo } from "react"
import { Link } from "react-router-dom"
import { Box, Divider, Drawer, Theme, useMediaQuery } from "@mui/material"

import {
  FORM_EDIT_ROUTE,
  INNOVATIONS_PAGE_ROUTE,
  INSTITUTES_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  STATICS_PAGE_ROUTE,
  SUPPORTS_PAGE_ROUTE,
  USERS_PAGE_ROUTE,
} from "../../consts/routes"
import { ShoppingBag as ShoppingBagIcon } from "../../icons/ShoppingBag"
import { User as UserIcon } from "../../icons/User"
import Logo from "../Logo"
import NavItem from "../NavItem"
import { authApi } from "../../services/authService"

const itemsAuthenticated = [
  {
    href: SUPPORTS_PAGE_ROUTE,
    icon: <ShoppingBagIcon fontSize="small" />,
    title: "Меры поддержки",
    key: "support_measures",
  },
  {
    href: INSTITUTES_PAGE_ROUTE,
    icon: <ShoppingBagIcon fontSize="small" />,
    title: "Институты",
    key: "institutes",
  },
  {
    href: NEWS_PAGE_ROUTE,
    icon: <ShoppingBagIcon fontSize="small" />,
    title: "Новости",
    key: "news",
  },
  {
    href: STATICS_PAGE_ROUTE,
    icon: <ShoppingBagIcon fontSize="small" />,
    title: "Статичные страницы",
    key: "statics",
  },
  {
    href: USERS_PAGE_ROUTE,
    title: "Пользователи",
    key: "users",
  },
  {
    href: INNOVATIONS_PAGE_ROUTE,
    title: "Инновационные разработки",
    key: "innovations",
  },
  {
    href: FORM_EDIT_ROUTE,
    title: "Редактор формы",
    key: "forms",
  },
]

const itemsUnauthenticated = [
  {
    href: LOGIN_PAGE_ROUTE,
    icon: <UserIcon fontSize="small" />,
    title: "Войти",
    key: "login",
  },
]

interface DashboardSidebarProps {
  tabKey: string
  onClose: () => void
  isOpen: boolean
}

const DashboardSidebar: FC<DashboardSidebarProps> = ({
  tabKey,
  isOpen,
  onClose,
}) => {
  const { data: user } = authApi.useGetIdQuery()

  const items =
    user?.adminlog === 1 || user?.adminlog === 5
      ? itemsAuthenticated
      : itemsUnauthenticated

  const lgUp = useMediaQuery((theme) => (theme as Theme).breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  })

  const content = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ p: 3 }}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Divider sx={{ borderColor: "#2D3748", my: 3 }} />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.key}
              // icon={item.icon}
              href={item.href}
              title={item.title}
              active={tabKey === item.key}
            />
          ))}
        </Box>
      </Box>
    </>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: { backgroundColor: "neutral.900", color: "#FFFFFF", width: 280 },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: { backgroundColor: "neutral.900", color: "#FFFFFF", width: 280 },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

export default memo(DashboardSidebar)
