const ShowLessArrow = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.59 8L6 3.42L1.41 8L0 6.59L6 0.59L12 6.59L10.59 8Z"
        fill="#00000099"
      />
    </svg>
  )
}

export default ShowLessArrow
