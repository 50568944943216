import { FC, memo } from "react"
import { Checkbox, TableCell, TableRow as MUITableRow } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"

import { SelectSliceStateKeys } from "../store/reducers/selectSlice"
import { useActionCreators } from "../hooks/redux"

import { LinkStyled } from "./styled/StyledComponents"

interface ListRowProps {
  row: any
  columns: GridColDef[]
  route: string
  type: SelectSliceStateKeys
  isChecked: boolean
}

const TableRow: FC<ListRowProps> = ({
  row,
  columns,
  route,
  type,
  isChecked,
}) => {
  const actions = useActionCreators()

  const labelIfBoolean = (v: boolean) => (v ? "Да" : "Нет")

  return (
    <MUITableRow hover key={row.id}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isChecked}
          onChange={() => actions.setSelect({ key: type, value: row.id })}
          value="true"
        />
      </TableCell>
      {columns.map((column, index) => (
        <TableCell key={column.field}>
          {index === 1 ? (
            <LinkStyled to={`${route}/${row.id}`}>
              {row[column.field]}
            </LinkStyled>
          ) : typeof row[column.field] === "boolean" ||
            row[column.field] === null ? (
            labelIfBoolean(row[column.field])
          ) : (
            row[column.field]
          )}
        </TableCell>
      ))}
    </MUITableRow>
  )
}

export default memo(TableRow)
