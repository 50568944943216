import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Container, Box, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import UsersToolbar from "../components/toolbars/UsersToolbar"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import Spinner from "../components/Spinner"
import ItemsManage from "../components/ItemsManage"
import ItemsTable from "../components/ItemsTable"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { USERS_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import { userApi } from "../services/userService"
import { User, USER_SORTS, UserSortsKeys } from "../types/users"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"
import DeleteModal from "../components/modals/DeleteModal"

const UsersPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchUsers, { data: fetchedUsers, isFetching }] =
    userApi.useLazyGetUsersQuery()
  const [changeRole] = userApi.useChangeRolesMutation()
  const [deleteUsers] = userApi.useDeleteUsersMutation()
  const { data: fetchedGroups } = userApi.useGetGroupsQuery()

  const filter = useTypedSelector((state) => state.filterUserReducer)
  const selectedUsers = useTypedSelector((state) => state.selectReducer.users)
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )
  const { deleteModalOpen } = useTypedSelector(
    (state) => state.modalReducer.users,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: USER_SORTS.id, headerName: "ID" },
      { field: USER_SORTS.fio, headerName: "ФИО" },
      { field: USER_SORTS.email, headerName: "Email" },
      { field: USER_SORTS.activate_email, headerName: "Подтвержден" },
      { field: USER_SORTS.phone, headerName: "Номер телефона" },
      { field: USER_SORTS.date_reg, headerName: "Дата создания" },
    ],
    [],
  )

  const rowTransform = (users: User[]) => {
    return users.map((user) => ({
      ...user,
      date_creation: <SpanNoWrap>{user.date_reg}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedUsers) return []

    return rowTransform(fetchedUsers.list)
  }, [fetchedUsers])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedUsers?.count ?? 0) / filter.page_size),
    [fetchedUsers?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${USER_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(
    () => getQueryString({ ...filter, ...filter.filter, filter: undefined }),
    [filter],
  )

  const onAllRowsSelect = () => {
    if (selectedUsers.length !== rows.length) {
      actions.setSelectAll({ key: "users", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "users", value: [] })
    }
  }

  const onDelete = () => {
    deleteUsers(selectedUsers).then(() => {
      toast.success("Пользователи удалены")
      actions.clearSelection("users")
    })
  }

  const onRoleChange = (roleId: number) => {
    changeRole(
      selectedUsers.map((userId) => ({
        id: userId,
        id_groups: roleId,
      })),
    ).then(() => {
      toast.success("Роли были успешно изменены")
    })
  }

  const onSortChange = (property: string) => {
    actions.setUserFilterOrder(property as UserSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setUserFilterPage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setUserFilterPageSize(amount),
    [actions],
  )

  const fetchUsersFunc = useCallback(() => {
    if (debouncedFilter.filter.phone.includes("_")) return

    fetchUsers({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        ...debouncedFilter.filter,
        phone: debouncedFilter.filter.phone.replaceAll(/[ ()-]/g, ""),
        region: debouncedFilter.filter.region.join(","),
        sort: debouncedSort,
      },
    })
  }, [
    debouncedFilter.filter,
    debouncedFilter.page,
    debouncedFilter.page_size,
    debouncedSort,
    fetchUsers,
  ])

  useEffect(() => {
    actions.setUserFilter(
      getObjectFromQuery(
        location.search,
        ["region"],
        ["subscription", "activate_email"],
      ),
    )
  }, [actions, location.search])

  useEffect(() => {
    fetchUsersFunc()
  }, [fetchUsersFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    fetchUsers({ page: 1 })
  }, [fetchUsers])

  return (
    <Layout title="Пользователи - ЦТТ Админ-панель" tabKey="users">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <UsersToolbar />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="users"
                changeBtnName="Изменить роль"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedUsers.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                type="users"
                rows={rows}
                columns={COLUMNS}
                route={USERS_PAGE_ROUTE}
                order={filter.order}
                orderBy={filter.orderBy}
                onSortChange={onSortChange}
                onAllSelect={onAllRowsSelect}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <ChangeStatusModal
        title="Изменить роль"
        selectTitle="Новая роль"
        property="id_groups"
        name="group_name"
        isOpen={isChangeStatusModalOpen}
        statuses={fetchedGroups?.data ?? []}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        onSubmit={onRoleChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранных пользователей?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "users", value: false })
        }
      />
    </Layout>
  )
}

export default UsersPage
