import { InnovationMember } from "../types/innovations"

import { readFileAsBase64 } from "./readFileAsBase64"

export async function getMembers(): Promise<InnovationMember[]> {
  const memberCards = document.querySelectorAll(".member")
  const members: InnovationMember[] = []

  const keys: ["fio", "position", "degree", "logo"] = [
    "fio",
    "position",
    "degree",
    "logo",
  ]

  for (let i = 0; i < memberCards.length; i++) {
    const card = memberCards[i] as HTMLDivElement

    const datasetMemberId = card.dataset.member

    const inputs = card.querySelectorAll("input[name]")
    const inputValues: InnovationMember = {} as InnovationMember

    if (datasetMemberId) {
      const memberId = +datasetMemberId

      if (memberId === Math.round(memberId)) {
        inputValues.id_members = +memberId
      }
    }

    for (let j = 0; j < inputs.length; j++) {
      const element = inputs[j]

      const input = element as HTMLInputElement
      const value = keys[j]

      if (value === "logo") {
        if (!input.files?.length) continue

        const file = input.files[0]

        inputValues[value] = await readFileAsBase64(file)

        continue
      }

      inputValues[value] = input.value
    }

    inputValues.leader = i === 0

    members.push(inputValues)
  }

  return members
}
