import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  ChangeStaticStatus,
  CreatedStatic,
  Static,
  Statics,
  StaticSave,
  StaticSearch,
  StaticStatus,
} from "../types/statics"

import { baseQuery } from "./baseQuery"

export const staticApi = createApi({
  reducerPath: "static",
  baseQuery,
  tagTypes: ["STATICS"],
  endpoints: (build) => ({
    getStatics: build.query<Statics, IDefaultQuery<StaticSearch>>({
      query: ({ page, search }) => ({
        url: `/getstaticpages/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["STATICS"],
      transformResponse(response: IDefaultResponse<Static[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
        }
      },
    }),
    getStatic: build.query<IDefaultResponse<Static>, string>({
      query: (id) => ({
        url: `/getstaticpage/${id}`,
      }),
      providesTags: (result) => [
        { type: "STATICS", id: result?.data?.id_static_pages },
      ],
    }),
    getStatuses: build.query<IDefaultResponse<StaticStatus[]>, void>({
      query: () => ({
        url: "/getstaticpagestatuses",
      }),
    }),
    createStatic: build.mutation<CreatedStatic, string>({
      query: (title) => ({
        url: "/sendnewstaticpage",
        body: { title },
        method: "POST",
      }),
    }),
    deleteStatic: build.mutation<IDefaultResponse<void>, number[]>({
      query: (idArray) => ({
        url: "/deletestaticpages",
        body: {
          list: idArray.map((id) => ({ id_static_pages: id })),
        },
        method: "POST",
      }),
      invalidatesTags: ["STATICS"],
    }),
    changeStatuses: build.mutation<
      IDefaultResponse<void>,
      ChangeStaticStatus[]
    >({
      query: (list) => ({
        url: "/sendstaticstatuses",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: ["STATICS"],
    }),
    saveStatic: build.mutation<IDefaultResponse<void>, StaticSave>({
      query: (news) => ({
        url: "/sendstaticpage",
        body: news,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "STATICS", id: arg.id_static_pages },
      ],
    }),
  }),
})
