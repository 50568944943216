import { FC } from "react"
import { toast } from "react-toastify"
import { Box, Paper, Typography, Button } from "@mui/material"

import InputGroup from "../../groupedInput/InputGroup"
import { InnovationMember } from "../../../types/innovations"
import { useFileSelect } from "../../../hooks/useFileSelect"
import LogoInputGroup from "../../groupedInput/LogoInputGroup"
import { innovationApi } from "../../../services/innovationService"

interface MemberInputsProps {
  defaultMember: InnovationMember
  name: string
  label: string
  isLeader?: boolean
  onRemove?: (id: number) => void
}

const MemberInputs: FC<MemberInputsProps> = ({
  onRemove,
  name,
  label,
  isLeader,
  defaultMember,
}) => {
  const [deleteMemberLogo] = innovationApi.useDeleteMemberLogoMutation()

  const { logoFileName, onFileSelect, setLogoFileName, setLogoFile } =
    useFileSelect(defaultMember.logo?.split("/").at(-1))

  const onLogoDelete = (element: HTMLInputElement | null) => {
    if (element) element.value = ""

    if (defaultMember.logo) {
      deleteMemberLogo(defaultMember.id_members).then(() => {
        toast.warning("Успешно удалено")
        setLogoFileName("")
        setLogoFile(null)
      })

      return
    }

    if (logoFileName) {
      setLogoFileName("")
      setLogoFile(null)
    }
  }

  return (
    <Paper
      className="member"
      sx={{ p: 3, mt: 3, border: "1px solid #e5e8ef" }}
      data-member={defaultMember.id_members}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        {!isLeader && (
          <Button
            variant="contained"
            color="error"
            onClick={() => onRemove && onRemove(defaultMember.id_members)}
          >
            Удалить
          </Button>
        )}
      </Box>

      <InputGroup
        title="ФИО"
        textFieldProps={{
          name: `${name}_fio`,
          defaultValue: defaultMember.fio,
        }}
      />

      <InputGroup
        title="Должность"
        textFieldProps={{
          name: `${name}_position`,
          defaultValue: defaultMember.position,
        }}
      />

      <InputGroup
        title="Ученая степень"
        textFieldProps={{
          name: `${name}_degree`,
          defaultValue: defaultMember.degree,
        }}
      />

      <LogoInputGroup
        title="Фото"
        fetchedLogo={defaultMember.logo}
        logoFileName={logoFileName}
        handleFileSelect={onFileSelect}
        onLogoDelete={onLogoDelete}
        name={`${name}_logo`}
      />
    </Paper>
  )
}

export default MemberInputs
