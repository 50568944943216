import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import InputMask from "react-input-mask"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import Spinner from "../components/Spinner"
import { userApi } from "../services/userService"
import { FormStyled, LegendStyled } from "../components/styled/StyledComponents"
import InputGroup from "../components/groupedInput/InputGroup"
import SelectGroup from "../components/groupedInput/SelectGroup"
import { miscApi } from "../services/miscService"
import DateGroup from "../components/groupedInput/DateGroup"
import SubscriptionTable from "../components/SubscriptionTable"
import ChangePasswordModal from "../components/modals/ChangePasswordModal"
import AddSubscriptionModal from "../components/modals/AddSubscriptionModal"
import { isFullName } from "../utils/isFullName"
import { isEmail } from "../utils/isEmail"

interface User {
  fio: string
  email: string
  region: number
  phone: string | null
  locality: string | null
  birthDate: string | null
  work: string | null
  position: string | null
}

const UserPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: regions } = miscApi.useGetRegionsQuery()
  const [fetchUser, { data: fetchedUser }] = userApi.useLazyGetUserQuery()
  const [saveUser, { data: saveUserResponse }] = userApi.useSaveUserMutation()
  const [changeRole, { data: changeRoleResponse }] =
    userApi.useChangeRolesMutation()

  const [user, setUser] = useState<User | null>(null)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [isAddSubModalOpen, setIsAddSubModalOpen] = useState(false)

  const formWasChanged = useMemo(() => {
    if (!user || !fetchedUser?.data) return false

    const fetchedUserData = fetchedUser.data

    return !(
      fetchedUserData.fio === user.fio &&
      fetchedUserData.phone === "+" + user.phone?.replace(/\D/g, "") &&
      fetchedUserData.email === user.email &&
      fetchedUserData.id_regions === user.region &&
      fetchedUserData.position === user.position &&
      fetchedUserData.locality === user.locality &&
      fetchedUserData.date_of_birth === user.birthDate &&
      fetchedUserData.place_of_work_study === user.work
    )
  }, [fetchedUser?.data, user])

  const handleFormChange = ({
    key,
    value,
  }: {
    key: keyof User
    value: unknown
  }) => {
    if (key === "region" && typeof value === "number") {
      return user && setUser({ ...user, region: value })
    }

    if (typeof value === "string") {
      user && setUser({ ...user, [key]: value })
    }
  }

  const onUserSave = () => {
    if (!user || !id || !+id) return

    if (!isFullName(user.fio)) {
      return toast.error(
        "ФИО должно состоять минимум из двух слов на русском языке",
      )
    }

    if (
      user.phone &&
      !/^\+7\d{10}$/.test("+" + user.phone.replace(/\D/g, ""))
    ) {
      return toast.error("Проверьте корректность введенного номера телефона")
    }

    if (!isEmail(user.email)) {
      return toast.error("Проверьте корректность введенного e-mail")
    }

    saveUser({
      id: +id,
      fio: user.fio,
      id_regions: user.region,
      locality: user.locality ?? "",
      date_of_birth: user.birthDate,
      place_of_work_study: user.work ?? "",
      position: user.position ?? "",
      phone: user.phone ? "+" + user.phone.replace(/\D/g, "") : undefined,
      email: user.email,
    })
  }

  const archiveUser = () => {
    if (!id || !+id) return

    const currentRole = fetchedUser?.data?.groups_id_groups

    changeRole([{ id: +id, id_groups: currentRole === 7 ? 6 : 7 }]).then(() => {
      toast.warning(
        currentRole === 7
          ? "Пользователь был убран из архива"
          : "Пользователь был добавлен в архив",
      )
    })
  }

  useEffect(() => {
    if (!saveUserResponse) return

    if (saveUserResponse.status === "error") {
      toast.error("Произошла ошибка, попробуйте еще раз")

      return
    }

    if (saveUserResponse.status === "successful") {
      toast.success("Изменения успешно сохранены")
    }
  }, [saveUserResponse])

  useEffect(() => {
    if (!id || !+id) {
      navigate("/404")
      return
    }

    fetchUser(+id)
  }, [fetchUser, id, navigate])

  useEffect(() => {
    if (!fetchedUser?.data) return

    const user = fetchedUser.data

    setUser({
      fio: user.fio,
      region: user.id_regions,
      locality: user.locality,
      birthDate: user.date_of_birth,
      work: user.place_of_work_study,
      position: user.position,
      phone: user.phone,
      email: user.email,
    })
  }, [fetchedUser?.data])

  useEffect(() => {
    if (!changeRoleResponse) return

    if (changeRoleResponse.status === "error") {
      toast.error("Неизвестная ошибка")

      return
    }

    if (changeRoleResponse.status === "successful") {
      toast.success("Статус успешно изменен")

      return
    }
  }, [changeRoleResponse])

  if (!user) {
    return (
      <Layout
        title="Редактирование пользователя - ЦТТ Админ-панель"
        tabKey="users"
      >
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout
      title="Редактирование пользователя - ЦТТ Админ-панель"
      tabKey="users"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4">
            Редактирование пользователя
          </Typography>
          <Paper sx={{ mt: 3 }}>
            <Box component={FormStyled} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    color="info"
                    variant="outlined"
                    onClick={() => setIsPasswordModalOpen(true)}
                  >
                    Изменить пароль
                  </Button>
                  <Box sx={{ mt: 1 }}>
                    <Button
                      color="warning"
                      variant="outlined"
                      onClick={archiveUser}
                    >
                      {fetchedUser?.data?.groups_id_groups === 7
                        ? "Разархивировать"
                        : "Архивировать"}{" "}
                      пользователя
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <InputGroup
                    title="ФИО"
                    value={user.fio}
                    onChange={(e) =>
                      handleFormChange({ key: "fio", value: e.target.value })
                    }
                  />

                  <SelectGroup
                    title="Регион"
                    property="id_regions"
                    name="region_name"
                    selectedValue={user.region?.toString() ?? "0"}
                    values={regions?.data ?? []}
                    onChange={(e) => {
                      const { value } = e.target

                      typeof value === "number" &&
                        handleFormChange({ key: "region", value: value })
                    }}
                  />

                  <InputGroup
                    title="Город"
                    value={user.locality ?? ""}
                    onChange={(e) =>
                      handleFormChange({
                        key: "locality",
                        value: e.target.value,
                      })
                    }
                  />

                  <DateGroup
                    title="Дата рождения"
                    value={dayjs(user.birthDate)}
                    maxDate={dayjs()}
                    onChange={(date) =>
                      handleFormChange({
                        key: "birthDate",
                        value: date.format("YYYY-MM-DD"),
                      })
                    }
                  />

                  <InputGroup
                    title="Место работы / учебы"
                    value={user.work ?? ""}
                    onChange={(e) =>
                      handleFormChange({ key: "work", value: e.target.value })
                    }
                  />

                  <InputGroup
                    title="Должность"
                    value={user.position ?? ""}
                    onChange={(e) =>
                      handleFormChange({
                        key: "position",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ position: "relative" }}>
                  <FormLabel component={LegendStyled}>Номер телефона</FormLabel>
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    value={user.phone ?? ""}
                    onChange={(e) =>
                      handleFormChange({ key: "phone", value: e.target.value })
                    }
                  >
                    <TextField
                      type="tel"
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </InputMask>
                  <InputGroup
                    title="E-mail"
                    value={user.email}
                    onChange={(e) =>
                      handleFormChange({ key: "email", value: e.target.value })
                    }
                  />
                  <Button
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                    color="info"
                    variant="contained"
                    onClick={onUserSave}
                    disabled={!formWasChanged}
                  >
                    Сохранить изменения
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ position: "relative" }}>
                <Button
                  color="success"
                  variant="outlined"
                  sx={{ position: "absolute", right: 0 }}
                  onClick={() => setIsAddSubModalOpen(true)}
                >
                  Добавить подписку
                </Button>
                <Typography sx={{ mt: 3 }} variant="h4">
                  Подписки
                </Typography>
                <SubscriptionTable />
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        setModalOpen={setIsPasswordModalOpen}
      />
      <AddSubscriptionModal
        isOpen={isAddSubModalOpen}
        setModalOpen={setIsAddSubModalOpen}
      />
    </Layout>
  )
}

export default UserPage
