import { Box, Container, Grid, Typography } from "@mui/material"

import Layout from "../components/Layout"
import DefaultFieldList from "../components/editors/DefaultFieldList"
import AdditionalFieldList from "../components/editors/AdditionalFieldList"

const FormEditorPage = () => {
  return (
    <Layout title="Редактор формы - ЦТТ Админ-панель" tabKey="forms">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4" sx={{ pb: "24px" }}>
            Редактирование формы подачи разработки
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DefaultFieldList />
            </Grid>
            <Grid item xs={6}>
              <AdditionalFieldList />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default FormEditorPage
