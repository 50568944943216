import { FormEvent, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import InputMask from "react-input-mask"
import { Container, Typography, Box, TextField, Button } from "@mui/material"

import Layout from "../components/Layout"
import { authApi } from "../services/authService"

const LoginPage = () => {
  const navigate = useNavigate()

  const { data: user } = authApi.useGetIdQuery()
  const [sendAuth, { data: authResponse }] = authApi.useSendAuthMutation()

  const phoneRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    const phone = phoneRef.current?.value
    const password = passwordRef.current?.value

    if (!phone || !password) {
      return toast.warning("Проверьте корректность введенных данных")
    }

    sendAuth({ login: phone, password })
  }

  useEffect(() => {
    if (user?.userid && [1, 5].includes(user?.adminlog)) {
      navigate("/")
    }
  }, [navigate, user?.adminlog, user?.userid])

  useEffect(() => {
    if (!authResponse) return

    if (authResponse.status === "error") {
      toast.error("Ошибка авторизации")

      return
    }

    if (authResponse.status === "successful") {
      const groupId = authResponse.data?.id_group

      if (!(groupId === 1 || groupId === 5)) {
        toast.error("Ошибка авторизации")

        return
      }

      toast.success("Вход выполнен успешно")
    }
  }, [authResponse])

  return (
    <Layout title="Вход - ЦТТ Админ-панель" tabKey="login">
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={onSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4" component="h4">
                Войти
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                По номеру телефона и паролю
              </Typography>
            </Box>
            <InputMask mask="+7 (999) 999-99-99" inputRef={phoneRef}>
              <TextField
                type="tel"
                fullWidth
                label="Номер телефона"
                margin="normal"
                variant="outlined"
              />
            </InputMask>

            <TextField
              error={false}
              fullWidth
              label="Пароль"
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              inputRef={passwordRef}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                Войти
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Layout>
  )
}

export default LoginPage
