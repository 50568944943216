import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import { Container, Grid, Input, Paper, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import dayjs, { Dayjs } from "dayjs"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import { FormStyled } from "../components/styled/StyledComponents"
import InputGroup from "../components/groupedInput/InputGroup"
import Spinner from "../components/Spinner"
import HTMLEditorGroup from "../components/groupedInput/HTMLEditorGroup"
import LogoInputGroup from "../components/groupedInput/LogoInputGroup"
import SelectGroup from "../components/groupedInput/SelectGroup"
import DateGroup from "../components/groupedInput/DateGroup"
import CheckboxList from "../components/groupedInput/CheckboxList"
import FileBrowser from "../components/FileBrowser"
import { newsApi } from "../services/newsService"
import { NEWS_PAGE_ROUTE } from "../consts/routes"
import { useFileSelect } from "../hooks/useFileSelect"
import { useCheckboxList } from "../hooks/useCheckboxList"
import DeleteModal from "../components/modals/DeleteModal"
import { useActionCreators, useTypedSelector } from "../hooks/redux"

const NewsDetailedPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const actions = useActionCreators()

  const [fetchDetailedNews, { data: fetchedNews }] =
    newsApi.useLazyGetNewsDetailedQuery()
  const [saveNews, { isLoading }] = newsApi.useSaveNewsMutation()
  const [deleteNews] = newsApi.useDeleteNewsMutation()
  const [deleteLogo] = newsApi.useDeleteLogoMutation()
  const [deleteCategory] = newsApi.useDeleteCategoryMutation()
  const [createCategory] = newsApi.useCreateCategoryMutation()
  const { data: statuses } = newsApi.useGetNewsStatusesQuery()
  const { data: categories } = newsApi.useGetNewsCategoriesQuery()

  const isOpen = useTypedSelector(
    (state) => state.modalReducer.news.deleteModalOpen,
  )

  const [name, setName] = useState("")
  const [fullStory, setFullStory] = useState("")
  const [shortStory, setShortStory] = useState("")
  const [urlPath, setUrlPath] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [statusId, setStatusId] = useState(1)
  const [createDate, setCreateDate] = useState<Dayjs | null>(null)
  const [categoriesId, setCategoriesId, onCategorySelect] = useCheckboxList()
  const [categoryName, setCategoryName] = useState("")

  const { logoFile, logoFileName, setLogoFile, setLogoFileName, onFileSelect } =
    useFileSelect()

  const filteredCategories = useMemo(() => {
    if (!categories?.data) return []

    return categories.data.filter((category) =>
      category.title.toLowerCase().includes(categoryName.toLowerCase()),
    )
  }, [categories?.data, categoryName])

  const isCategoryAddDisabled = useMemo(() => {
    return (
      !categoryName ||
      !!filteredCategories.find(
        (category) =>
          category.title.toLowerCase() === categoryName.toLowerCase(),
      )
    )
  }, [categoryName, filteredCategories])

  const onSave = async () => {
    if (!fetchedNews) return

    const { data: news } = fetchedNews

    if (!news) return

    const response = await saveNews({
      id_news: news.id_news,
      title: name,
      short_story: shortStory,
      full_story: fullStory,
      alt_name: urlPath,
      logo: logoFile ? logoFile : undefined,
      date_creation: createDate?.format("YYYY-MM-DD HH:mm:ss"),
      id_news_statuses: statusId,
      news_categories_id: categoriesId.join(","),
      title_meta: metaTitle,
      keywords_meta: metaKeywords,
      description_meta: metaDescription,
    }).unwrap()

    if (response.status === "error") {
      toast.error("Не удалось сохранить")
    } else {
      toast.success("Успешно сохранено")
    }
  }

  const onDelete = () => {
    id &&
      deleteNews([+id]).then(() => {
        toast.success("Успешно удалено")
        navigate(NEWS_PAGE_ROUTE)
      })
  }

  const onLogoDelete = () => {
    if (!id) return

    if (fetchedNews?.data?.logo) {
      deleteLogo(+id).then(() => {
        toast.warning("Успешно удалено")
        setLogoFile(null)
      })

      return
    }

    if (logoFileName) {
      setLogoFileName("")
      setLogoFile(null)
    }
  }

  const onCategoryCreate = () => {
    createCategory(categoryName).then(() => {
      setCategoryName("")
      toast.success("Категория добавлена")
    })
  }

  const onCategoryDelete = (id: number) => {
    deleteCategory(id).then(() => {
      setCategoryName("")
      toast.success("Категория удалена")
    })
  }

  useEffect(() => {
    if (!fetchedNews?.data) return

    const { data } = fetchedNews

    setName(data.title)
    setStatusId(data.news_statuses_id_news_statuses)
    setCreateDate(dayjs(data.date_creation))
    setUrlPath(data.alt_name)
    setCategoriesId(data.news_categories.map((c) => c.id_news_categories))

    data.full_story !== null && setFullStory(data.full_story)
    data.short_story !== null && setShortStory(data.short_story)
    data.title_meta !== null && setMetaTitle(data.title_meta)
    data.keywords_meta !== null && setMetaKeywords(data.keywords_meta)
    data.description_meta !== null && setMetaDescription(data.description_meta)
    data.logo !== null && setLogoFileName(data.logo.split("/").at(-1) as string)
  }, [fetchedNews, setCategoriesId, setLogoFileName])

  useEffect(() => {
    id && fetchDetailedNews(+id)
  }, [fetchDetailedNews, id])

  if (!fetchedNews) {
    return (
      <Layout title="Редактирование новости - ЦТТ Админ-панель" tabKey="news">
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout title="Редактирование новости - ЦТТ Админ-панель" tabKey="news">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4">
            Редактирование новости
          </Typography>
          <Paper sx={{ mt: 3 }}>
            <Box component={FormStyled} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputGroup
                    title="Заголовок"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <HTMLEditorGroup
                    title="Описание"
                    value={fullStory}
                    setValue={(value) => setFullStory(value)}
                  />

                  <HTMLEditorGroup
                    title="Аннотация"
                    value={shortStory}
                    setValue={(value) => setShortStory(value)}
                  />

                  <LogoInputGroup
                    title="Логотип"
                    fetchedLogo={fetchedNews?.data?.logo}
                    logoFile={logoFile}
                    logoFileName={logoFileName}
                    handleFileSelect={onFileSelect}
                    onLogoDelete={onLogoDelete}
                  />

                  <InputGroup
                    title="Заголовок meta"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />

                  <InputGroup
                    title="Ключевые слова meta"
                    value={metaKeywords}
                    onChange={(e) => setMetaKeywords(e.target.value)}
                  />

                  <InputGroup
                    title="Описание meta"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />

                  <InputGroup
                    title="URL"
                    value={urlPath}
                    onChange={(e) => setUrlPath(e.target.value)}
                  />

                  <FileBrowser />
                </Grid>

                <Grid item xs={4}>
                  <SelectGroup
                    title="Статус"
                    property="id_news_statuses"
                    name="status_name"
                    sx={{ width: "50%" }}
                    selectedValue={`${statusId}`}
                    values={statuses?.data ?? []}
                    onChange={(e) => setStatusId(+e.target.value)}
                  />

                  <DateGroup
                    title="Дата публикации"
                    value={createDate}
                    onChange={(newValue: any) => setCreateDate(newValue)}
                  />

                  <CheckboxList
                    title="Теги"
                    property="id_news_categories"
                    name="title"
                    collapsed={false}
                    items={filteredCategories}
                    selectedIds={categoriesId}
                    isControlled
                    onDelete={onCategoryDelete}
                    onSelect={onCategorySelect}
                  >
                    <Box sx={{ mb: "15px" }}>
                      <Input
                        type="text"
                        placeholder="Поиск / Новый тег"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      ></Input>
                      <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        disabled={isCategoryAddDisabled}
                        onClick={onCategoryCreate}
                      >
                        Добавить
                      </Button>
                    </Box>
                  </CheckboxList>
                </Grid>

                <Grid item xs={12} sx={{ mt: 5 }}>
                  <Button
                    sx={{ mr: "10px" }}
                    color="error"
                    variant="contained"
                    onClick={() =>
                      actions.setIsDeleteModalOpen({ key: "news", value: true })
                    }
                  >
                    Удалить
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={onSave}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
      <DeleteModal
        text="Вы точно хотите удалить эту новость?"
        onDelete={onDelete}
        isOpen={isOpen}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "news", value: false })
        }
      />
    </Layout>
  )
}

export default NewsDetailedPage
