import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface InitialState {
  supports: number[]
  institutes: number[]
  news: number[]
  statics: number[]
  users: number[]
  innovations: number[]
}

export type SelectSliceStateKeys = keyof InitialState

interface SetSelectPayload {
  key: SelectSliceStateKeys
  value: number
}

interface SetSelectAllPayload {
  key: SelectSliceStateKeys
  value: number[]
}

const initialState: InitialState = {
  supports: [],
  institutes: [],
  news: [],
  statics: [],
  users: [],
  innovations: [],
}

export const selectSlice = createSlice({
  name: "select",
  initialState,
  reducers: {
    setSelect(state, action: PayloadAction<SetSelectPayload>) {
      const { key, value } = action.payload

      if (state[key].includes(value)) {
        state[key] = state[key].filter((n) => n !== value)
      } else {
        state[key].push(value)
      }
    },
    setSelectAll(state, action: PayloadAction<SetSelectAllPayload>) {
      const { key, value } = action.payload

      state[key] = value
    },
    clearSelection(state, action: PayloadAction<SelectSliceStateKeys>) {
      state[action.payload] = []
    },
  },
})

export const selectReducer = selectSlice.reducer
