import { ChangeEvent, FC, useEffect, useState } from "react"
import { Paper, Typography } from "@mui/material"
import { toast } from "react-toastify"

import { Innovation, InnovationSliderImage } from "../../types/innovations"
import { innovationApi } from "../../services/innovationService"
import CheckboxList from "../groupedInput/CheckboxList"
import InputGroup from "../groupedInput/InputGroup"
import { useCheckboxList } from "../../hooks/useCheckboxList"

import InnovationSelect from "./Inputs/InnovationSelect"
import MultipleFileInputGroup from "./Inputs/MultipleFileInputGroup"

type ExtendedInnovationImage = InnovationSliderImage & { fromEdit?: boolean }

interface DetailedInfoProps {
  innovation?: Innovation
}

const DetailedInfo: FC<DetailedInfoProps> = ({ innovation }) => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedIndustries } = innovationApi.useGetIndustriesQuery()
  const { data: fetchedMarkets } = innovationApi.useGetMarketsQuery()
  const { data: fetchedTrl } = innovationApi.useGetTRLQuery()
  const { data: fetchedDirections } = innovationApi.useGetDirectionsQuery()
  const { data: fetchedTechnologies } = innovationApi.useGetTechnologiesQuery()
  const { data: fetchedFinanceSources } =
    innovationApi.useGetFinanceSourcesQuery()
  const [deleteSliderImage] = innovationApi.useDeleteProductPhotoMutation()

  const [sliderImages, setSliderImages] = useState<ExtendedInnovationImage[]>(
    [],
  )
  const [wasFinanced, setWasFinanced] = useState(() => innovation?.financing)

  const [selectedIndustries, , handleSelectedIndustriesChange] =
    useCheckboxList(
      innovation?.industries.map((ind) => ind.id_industries) ?? [],
    )
  const [selectedMarkets, , handleSelectedMarketsChange] = useCheckboxList(
    innovation?.innovation_markets.map(
      (market) => market.id_innovation_markets,
    ) ?? [],
  )
  const [selectedTechnologies, , handleSelectedTechnologiesChange] =
    useCheckboxList(
      innovation?.technologies.map((tech) => tech.id_technologies) ?? [],
    )
  const [selectedFinanceSources, , handleSelectedFinanceSourcesChange] =
    useCheckboxList(
      innovation?.finance_sources.map((source) => source.id_finance_sources) ??
        [],
    )

  const handleSliderFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target

    if (!input.files) return

    const fileNames = Array.from(input.files).map((file) => file.name)

    const filesFromServer = sliderImages.filter((image) => !image.fromEdit)

    if (fileNames.length + filesFromServer.length > 3) {
      toast.error("Количество файлов не может превышать 3")
      input.value = ""

      return
    }

    setSliderImages([
      ...filesFromServer,
      ...fileNames.map((name) => ({
        id_innovation_images: Math.random(),
        url_file: name,
        fromEdit: true,
      })),
    ])
  }

  const onSlideImageDelete = (deleteImage: ExtendedInnovationImage) => {
    if (!deleteImage.fromEdit) {
      deleteSliderImage(deleteImage.id_innovation_images).then(() => {
        toast.warning("Успешно удалено")
      })
    }

    setSliderImages(
      sliderImages.filter(
        (image) =>
          image.id_innovation_images !== deleteImage.id_innovation_images,
      ),
    )
  }

  useEffect(() => {
    if (!innovation?.slider_images) return

    setSliderImages(innovation.slider_images)
  }, [innovation?.slider_images])

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Подробная информация о проекте
      </Typography>

      {fetchedIndustries?.data && (
        <CheckboxList
          title="Отрасль"
          items={fetchedIndustries.data}
          property="id_industries"
          name="industry_name"
          selectedIds={selectedIndustries}
          onSelect={handleSelectedIndustriesChange}
          collapsed={false}
          width="100%"
          nameFormProp="industries"
          unsorted
        />
      )}

      {fetchedMarkets?.data && (
        <CheckboxList
          title="Рынок НТИ"
          items={fetchedMarkets.data}
          property="id_innovation_markets"
          name="market_name"
          selectedIds={selectedMarkets}
          onSelect={handleSelectedMarketsChange}
          collapsed={false}
          width="100%"
          nameFormProp="innovation_markets"
          unsorted
        />
      )}

      {fetchedTrl?.data && (
        <InnovationSelect
          name="id_trls"
          title="Уровень готовности технологии TRL"
          values={fetchedTrl.data}
          idProp="id_trls"
          valueProp="trl_name"
          defaultValue={innovation?.id_trls}
        />
      )}

      {fetchedDirections?.data && (
        <InnovationSelect
          name="id_innovation_directions"
          title="Направление"
          values={fetchedDirections.data}
          idProp="id_innovation_directions"
          valueProp="direction_name"
          defaultValue={innovation?.id_innovation_directions}
        />
      )}

      {fetchedTechnologies?.data && (
        <CheckboxList
          title="Технология"
          items={fetchedTechnologies.data}
          property="id_technologies"
          name="technology_name"
          selectedIds={selectedTechnologies}
          onSelect={handleSelectedTechnologiesChange}
          collapsed={false}
          width="100%"
          nameFormProp="technologies"
          unsorted
        />
      )}

      {defaultFields?.target_product?.active && (
        <InputGroup
          title={defaultFields?.target_product.label}
          textFieldProps={{
            name: "target_product",
            defaultValue: innovation?.target_product,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      {innovation?.slider_images && (
        <MultipleFileInputGroup
          title="Фото конечного продукта"
          fetchedFiles={sliderImages}
          onDelete={onSlideImageDelete}
          onChange={handleSliderFileSelect}
          name="slider_images"
          max={3}
        />
      )}

      {defaultFields?.start_date?.active && (
        <InputGroup
          title={defaultFields.start_date.label}
          textFieldProps={{
            name: "start_date",
            defaultValue: innovation?.start_date,
            type: "date",
          }}
          sx={{ width: "auto" }}
        />
      )}

      {defaultFields?.first_sale_date?.active && (
        <InputGroup
          title={defaultFields.first_sale_date.label}
          textFieldProps={{
            name: "first_sale_date",
            defaultValue: innovation?.first_sale_date,
            type: "date",
          }}
          sx={{ width: "auto" }}
        />
      )}

      {defaultFields?.budget?.active && (
        <InputGroup
          title={defaultFields.budget.label}
          textFieldProps={{
            name: "budget",
            defaultValue: innovation?.budget,
            type: "number",
          }}
          sx={{ width: "auto" }}
        />
      )}

      {defaultFields?.financing?.active && (
        <InnovationSelect
          name="financing"
          title={defaultFields.financing.label}
          values={[
            { id: 0, value: "Нет" },
            { id: 1, value: "Да" },
          ]}
          idProp="id"
          valueProp="value"
          defaultValue={innovation?.financing ? 1 : 0}
          onChange={(e) => setWasFinanced(e.target.value === "1")}
        />
      )}

      {defaultFields?.fundraising?.active && wasFinanced && (
        <InputGroup
          title={defaultFields?.fundraising.label}
          textFieldProps={{
            name: "fundraising",
            defaultValue: innovation?.fundraising,
            type: "number",
          }}
          sx={{ width: "auto" }}
        />
      )}

      {fetchedFinanceSources?.data &&
        defaultFields?.finance_sources?.active && (
          <CheckboxList
            title={defaultFields.finance_sources.label}
            items={fetchedFinanceSources?.data}
            property="id_finance_sources"
            name="finance_sources_name"
            selectedIds={selectedFinanceSources}
            onSelect={handleSelectedFinanceSourcesChange}
            collapsed={false}
            width="100%"
            nameFormProp="finance_sources"
            unsorted
          />
        )}

      {defaultFields?.expected_income?.active && (
        <InputGroup
          title={defaultFields.expected_income.label}
          textFieldProps={{
            name: "expected_income",
            defaultValue: innovation?.expected_income,
            type: "number",
          }}
          sx={{ width: "auto" }}
        />
      )}

      {defaultFields?.intellectual_property?.active && (
        <InnovationSelect
          name="intellectual_property"
          title={defaultFields.intellectual_property.label}
          values={[
            { id: 0, value: "Нет" },
            { id: 1, value: "Да" },
          ]}
          idProp="id"
          valueProp="value"
          defaultValue={innovation?.intellectual_property ? 1 : 0}
        />
      )}

      {defaultFields?.detailed_info?.active && (
        <InputGroup
          title={defaultFields?.detailed_info.label}
          textFieldProps={{
            name: "detailed_info",
            defaultValue: innovation?.detailed_info,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      <Paper sx={{ p: 3, mt: 3, border: "1px solid #e5e8ef" }}>
        <Typography variant="h6">Цели проекта на год</Typography>

        <InputGroup
          title="Цель 1"
          textFieldProps={{
            name: "objectives_1",
            defaultValue: innovation?.objectives[0],
            multiline: true,
            rows: 6,
          }}
        />

        <InputGroup
          title="Цель 2"
          textFieldProps={{
            name: "objectives_2",
            defaultValue: innovation?.objectives[1],
            multiline: true,
            rows: 6,
          }}
        />

        <InputGroup
          title="Цель 3"
          textFieldProps={{
            name: "objectives_3",
            defaultValue: innovation?.objectives[2],
            multiline: true,
            rows: 6,
          }}
        />
      </Paper>

      {defaultFields?.expected_consumer?.active && (
        <InputGroup
          title={defaultFields.expected_consumer.label}
          textFieldProps={{
            name: "expected_consumer",
            defaultValue: innovation?.expected_consumer,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      {defaultFields?.expected_effect?.active && (
        <InputGroup
          title={defaultFields.expected_effect.label}
          textFieldProps={{
            name: "expected_effect",
            defaultValue: innovation?.expected_effect,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      {defaultFields?.legal_entity?.active && (
        <InnovationSelect
          name="legal_entity"
          title={defaultFields.legal_entity.label}
          values={[
            { id: 0, value: "Нет" },
            { id: 1, value: "Да" },
          ]}
          idProp="id"
          valueProp="value"
          defaultValue={innovation?.legal_entity ? 1 : 0}
        />
      )}
    </>
  )
}

export default DetailedInfo
