import { createApi } from "@reduxjs/toolkit/query/react"

import { File } from "../types/files"
import { IDefaultResponse } from "../types/api"

import { baseQuery } from "./baseQuery"

export const fileApi = createApi({
  reducerPath: "file",
  baseQuery,
  tagTypes: ["FILES"],
  endpoints: (build) => ({
    getFiles: build.query<IDefaultResponse<File[]>, void>({
      query: () => "/getfiles",
      providesTags: ["FILES"],
    }),
    uploadFiles: build.mutation<void, FormData>({
      query: (formData) => ({
        url: "/sendfile",
        body: formData,
        method: "POST",
      }),
      invalidatesTags: ["FILES"],
    }),
    deleteFile: build.mutation<void, string[]>({
      query: (idArray) => ({
        url: "/deletefiles",
        body: {
          list: idArray.map((id) => ({
            id_files: id,
          })),
        },
        method: "POST",
      }),
      invalidatesTags: ["FILES"],
    }),
  }),
})
