import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  ChangeInstituteStatus,
  CreatedInstitute,
  Institute,
  InstituteForm,
  Institutes,
  InstituteSave,
  InstituteSearch,
  InstituteShort,
  InstituteStatus,
} from "../types/institutes"

import { baseQuery } from "./baseQuery"

export const instituteApi = createApi({
  reducerPath: "institute",
  baseQuery,
  tagTypes: ["INSTITUTES", "INSTITUTE"],
  endpoints: (build) => ({
    getInstitutes: build.query<Institutes, IDefaultQuery<InstituteSearch>>({
      query: ({ page, search }) => ({
        url: `/getinstitutes/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["INSTITUTES"],
      transformResponse(response: IDefaultResponse<Institute[]>) {
        return {
          list: response.data ?? [],
          count: response.count ?? 0,
        }
      },
    }),
    getInstitute: build.query<IDefaultResponse<Institute>, number>({
      query: (id) => ({
        url: `/getinstitute/${id}`,
      }),
      providesTags: (result) => [
        { type: "INSTITUTE", id: result?.data?.id_institutes },
      ],
    }),
    getInstituteStatuses: build.query<
      IDefaultResponse<InstituteStatus[]>,
      void
    >({
      query: () => ({
        url: "/getinstitutionstatuses",
      }),
    }),
    getInstituteForms: build.query<IDefaultResponse<InstituteForm[]>, void>({
      query: () => ({
        url: "/getinstitutionforms",
      }),
    }),
    getAllInstitutes: build.query<IDefaultResponse<InstituteShort[]>, void>({
      query: () => ({
        url: "/getallinstitutes",
      }),
    }),
    saveInstitute: build.mutation<IDefaultResponse<void>, InstituteSave>({
      query: (institute) => ({
        url: "/sendinstitute",
        body: institute,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "INSTITUTE", id: arg.id_institutes },
      ],
    }),
    createInstitute: build.mutation<CreatedInstitute, string>({
      query: (institute_name) => ({
        url: "/sendnewinstitute",
        body: { institute_name },
        method: "POST",
      }),
    }),
    deleteInstitutes: build.mutation<{ status: string }, number[]>({
      query: (idArray) => ({
        url: "/deleteinstitutes",
        body: {
          list: idArray.map((id) => ({ id_institutes: id })),
        },
        method: "POST",
      }),
      invalidatesTags: ["INSTITUTES"],
    }),
    changeStatuses: build.mutation<
      IDefaultResponse<void>,
      ChangeInstituteStatus[]
    >({
      query: (list) => ({
        url: "/sendinstitutestatuses",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: ["INSTITUTES"],
    }),
    deleteLogo: build.mutation<IDefaultResponse<void>, number>({
      query: (id) => ({
        url: "/deleteinstitutelogo",
        body: { id_institutes: id },
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "INSTITUTE", id }],
    }),
  }),
})
