import { IDefaultSearch } from "./common"

export interface InnovationSearch extends IDefaultSearch {
  innovation_name: string
  industries: string
  innovation_markets: string
  fundraising?: boolean
  legal_entity?: boolean
}

export interface Innovation {
  id_innovations: number
  id_innovation_statuses: number
  date_creation: string
  date_creation_dd: string

  innovation_name: string
  wallpaper: string | null
  logo: string | null
  description: string | null
  keywords: string
  id_stages: number | null
  stage_name: string | null
  site: string

  members: InnovationMember[]
  count_members: number | null

  industries: InnovationIndustry[]
  innovation_markets: InnovationMarket[]
  id_trls: number | null
  trl: number | null
  id_innovation_directions: number | null
  direction_name: string | null
  technologies: InnovationTechnology[]
  target_product: string | null
  slider_images: InnovationSliderImage[]
  start_date: string | null
  start_date_dd?: string
  first_sale_date: string | null
  first_sale_date_dd?: string
  budget: number | null
  financing: boolean | null
  fundraising: number | null
  finance_sources: InnovationFinanceSource[]
  expected_income: number | null
  intellectual_property: boolean
  detailed_info: string | null
  objectives: string[]
  expected_consumer: string | null
  expected_effect: string | null
  legal_entity: boolean

  demands: InnovationDemand[]
  required_resources: string | null
  vacancies: InnovationVacancy[]

  innovation_data: InnovationData[]

  presentation: string | null
  video_presentation_link: string
}

export interface InnovationSliderImage {
  id_innovation_images: number
  url_file: string
}

export interface InnovationData {
  id_innovation_data: number
  id_innovation_fields: number
  id_field_types: 1 | 2 | 3
  data: string | InnovationAdditionalFieldSet[]
}

export interface InnovationDemand {
  id_demands: number
  demand_name: string
}

export interface InnovationVacancy {
  id_vacancies: number
  vacancy_name: string
  description: string
  amount: number
}

export interface InnovationMember {
  id_members: number
  fio: string
  logo: string | null
  position: string
  degree: string
  leader: boolean
}

export interface InnovationStatus {
  id_innovation_statuses: number
  status_name: string
}

interface InnovationField {
  public: boolean
  label: string
  visible: boolean
  active: boolean
  required: boolean
  field_name: string
  tooltip: string
}

export interface InnovationDefaultField extends InnovationField {
  id_default_fields: number
}

export interface InnovationAdditionalField extends InnovationField {
  id_innovation_fields: number
  id_field_types: number
  sets?: InnovationAdditionalFieldSet[]
}

export interface InnovationAdditionalFieldSet {
  id_field_sets: number
  set_value: string
}

export type SaveDefaultField = Omit<
  InnovationDefaultField,
  "label" | "field_name"
>

export type SaveAdditionalField = {
  sets?: Partial<InnovationAdditionalFieldSet>[]
} & Omit<InnovationAdditionalField, "active" | "field_name" | "sets">

export type CreateAdditionalField = {
  sets?: string[]
} & Omit<
  InnovationAdditionalField,
  "active" | "id_innovation_fields" | "field_name" | "sets"
>

export interface InnovationShort {
  id_innovations: number
  innovation_name: string
  logo: string | null
  id_innovation_statuses: number
  industries: number[]
  date_creation_dd: string
  date_creation: string
  trl: number
}

export interface InnovationIndustry {
  id_industries: number
  industry_name: string
}

export interface InnovationMarket {
  id_innovation_markets: number
  market_name: string
}

export interface InnovationStage {
  id_stages: number
  stage_name: string
}

export interface InnovationDirection {
  id_innovation_directions: number
  direction_name: string
}

export interface InnovationFinanceSource {
  id_finance_sources: number
  finance_sources_name: string
}

export interface InnovationTechnology {
  id_technologies: number
  technology_name: string
}

export interface InnovationTRL {
  id_trls: number
  trl_name: string
  description: string
}

export interface ChangeInnovationStatus {
  id_innovations: number
  id_innovation_statuses: number
}
export enum INNOVATION_SORTS {
  id_innovations = "id_innovations",
  innovation_name = "innovation_name",
  status_name = "status_name",
  budget = "budget",
  id_trls = "id_trls",
  date_creation = "date_creation",
}

export type InnovationSortsKeys = keyof typeof INNOVATION_SORTS
