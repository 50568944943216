import { IDefaultSearch } from "./common"

export interface SupportSearch extends IDefaultSearch {
  support_forms: string
  support_directions: string
  support_members: string
  support_reasons: string
  support_name: string
}

export interface SupportForm {
  id_support_forms: number
  support_form_name: string
}

export interface SupportDirection {
  id_support_directions: number
  support_direction_name: string
}

export interface SupportMember {
  id_support_members: number
  support_members_name: string
}

export interface SupportReason {
  id_support_reasons: number
  support_reason_name: string
}

export interface SupportStatus {
  id_support_statuses: number
  status_name: string
}

export interface Support {
  amount: number | null
  date_creation: string
  date_creation_dd: string
  date_end: string | null
  date_end_dd?: string
  description: string | null
  site: string | null
  support_forms: SupportForm[]
  support_forms_id: string[]
  support_directions: SupportDirection[]
  support_directions_id: string[]
  support_members: SupportMember[]
  support_members_id: string[]
  support_reasons: SupportReason[]
  support_reasons_id: string[]
  id_support_statuses: number
  id_supports: number
  logo: string | null
  logo_thumb: string | null
  logo_selected?: string
  no_deadline: boolean | null
  no_finance: boolean | null
  regions: string
  remained_count: number
  remained_days: string
  status_name: string
  support_name: string
  support_name_manual: string
  support_statuses_id_support_statuses: string
  institute_name: string | null
  id_institutes: number | null

  title_meta: string | null
  keywords_meta: string | null
  description_meta: string | null

  id?: string
}

export interface SupportSave {
  id_supports: number
  id_support_statuses?: number
  support_name: string
  support_name_manual?: string
  site?: string
  description?: string
  date_end?: string
  date_creation?: string
  no_deadline?: boolean
  amount?: number
  no_finance?: boolean
  logo?: string
  support_forms_id: string
  support_directions_id: string
  support_members_id: string
  support_reasons_id: string
  id_institutes?: number

  title_meta?: string
  keywords_meta?: string
  description_meta?: string
}

export interface Supports {
  list: Support[]
  count: number
  min_amount: number
  max_amount: number
}

export enum SUPPORT_SORTS {
  id_supports = "id_supports",
  support_name = "support_name",
  status_name = "status_name",
  amount = "amount",
  date_creation = "date_creation",
  date_end = "date_end",
}

export type SupportSortsKeys = keyof typeof SUPPORT_SORTS

export interface CreatedSupport {
  id_supports: string
  status: string
}

export interface ChangeSupportStatus {
  id_supports: number
  id_support_statuses: number
}
