import { createApi } from "@reduxjs/toolkit/query/react"

import {
  ChangeNewsStatus,
  CreatedNews,
  News,
  NewsCategory,
  NewsList,
  NewsSave,
  NewsSearch,
  NewsStatus,
} from "../types/news"
import { IDefaultQuery, IDefaultResponse } from "../types/api"

import { baseQuery } from "./baseQuery"

export const newsApi = createApi({
  reducerPath: "news",
  baseQuery,
  tagTypes: ["NEWS", "CATEGORIES"],
  endpoints: (build) => ({
    getNews: build.query<NewsList, IDefaultQuery<NewsSearch>>({
      query: ({ page, search }) => ({
        url: `/getnews/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["NEWS"],
      transformResponse(response: IDefaultResponse<News[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
        }
      },
    }),
    getNewsDetailed: build.query<IDefaultResponse<News>, number>({
      query: (id) => ({
        url: `/getonenews/${id}`,
      }),
      providesTags: (result) => [{ type: "NEWS", id: result?.data?.id_news }],
    }),
    getNewsCategories: build.query<IDefaultResponse<NewsCategory[]>, void>({
      query: () => ({
        url: "/getallnewscategories",
      }),
      providesTags: ["CATEGORIES"],
    }),
    getNewsStatuses: build.query<IDefaultResponse<NewsStatus[]>, void>({
      query: () => ({
        url: "/getnewsstatuses",
      }),
    }),
    createNews: build.mutation<CreatedNews, string>({
      query: (title) => ({
        url: "/sendnewnews",
        body: { title },
        method: "POST",
      }),
    }),
    deleteNews: build.mutation<IDefaultResponse<void>, number[]>({
      query: (idArray) => ({
        url: "/deletenews",
        body: {
          list: idArray.map((id) => ({ id_news: id })),
        },
        method: "POST",
      }),
      invalidatesTags: ["NEWS"],
    }),
    changeNews: build.mutation<IDefaultResponse<void>, ChangeNewsStatus[]>({
      query: (list) => ({
        url: "/sendnewsstatuses",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: ["NEWS"],
    }),
    saveNews: build.mutation<IDefaultResponse<void>, NewsSave>({
      query: (news) => ({
        url: "/sendnews",
        body: news,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "NEWS", id: arg.id_news },
      ],
    }),
    deleteLogo: build.mutation<IDefaultResponse<void>, number>({
      query: (id) => ({
        url: "/deletenewslogo",
        body: { id_news: id },
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "NEWS", id }],
    }),
    createCategory: build.mutation<IDefaultResponse<void>, string>({
      query: (title) => ({
        url: "/sendnewscategory",
        body: { title },
        method: "POST",
      }),
      invalidatesTags: ["CATEGORIES"],
    }),
    deleteCategory: build.mutation<IDefaultResponse<void>, number>({
      query: (id) => ({
        url: "/deletenewscategories",
        body: { list: [{ id_news_categories: id }] },
        method: "POST",
      }),
      invalidatesTags: ["CATEGORIES"],
    }),
  }),
})
