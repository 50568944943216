import { FC } from "react"
import { GridColDef } from "@mui/x-data-grid"
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MUITableRow,
  TableSortLabel,
} from "@mui/material"
import { visuallyHidden } from "@mui/utils"

import { useTypedSelector } from "../hooks/redux"
import { SelectSliceStateKeys } from "../store/reducers/selectSlice"

import TableRow from "./TableRow"

interface AdminListProps {
  rows: any[]
  columns: GridColDef[]
  order: "asc" | "desc"
  orderBy: string | null
  route: string
  type: SelectSliceStateKeys
  onSortChange: (columnId: string) => void
  onAllSelect: () => void
}

const ItemsTable: FC<AdminListProps> = ({
  rows,
  columns,
  order,
  orderBy,
  route,
  type,
  onSortChange,
  onAllSelect,
}) => {
  const selected = useTypedSelector((state) => state.selectReducer[type])

  return (
    <Box sx={{ minWidth: 1050, mt: 3 }}>
      <Table>
        <TableHead>
          <MUITableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selected.length === rows.length}
                color="primary"
                indeterminate={
                  selected.length > 0 && selected.length < rows.length
                }
                onChange={onAllSelect}
              />
            </TableCell>
            {columns.map((column) => (
              <TableCell sortDirection={"desc"} key={column.field}>
                <TableSortLabel
                  active={orderBy === column.field}
                  direction={orderBy === column.field ? order : "asc"}
                  onClick={() => onSortChange(column.field)}
                >
                  {column.headerName}
                  {orderBy === column.field ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </MUITableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              row={row}
              columns={columns}
              route={route}
              type={type}
              isChecked={selected.includes(row.id)}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default ItemsTable
