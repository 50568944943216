import { ChangeEvent, useEffect, useState } from "react"
import {
  FileBrowser as ChonkyFileBrowser,
  FileContextMenu,
  FileNavbar,
  FileToolbar,
  FileList,
  ChonkyActions,
  defineFileAction,
  I18nConfig,
  FileHelper,
} from "chonky"
import { FileData } from "chonky/src/types/file.types"
import Button from "@mui/material/Button"
import { toast } from "react-toastify"

import { File } from "../types/files"
import { HOST } from "../consts/hosts"
import { fileApi } from "../services/fileService"

const russianI18n: I18nConfig = {
  locale: "ru",
  formatters: {
    formatFileModDate: (intl, file) => {
      const safeModDate = FileHelper.getModDate(file)
      if (safeModDate) {
        return `${intl.formatDate(safeModDate)}, ${intl.formatTime(
          safeModDate,
        )}`
      } else {
        return null
      }
    },
    formatFileSize: (intl, file) => {
      if (!file || typeof file.size !== "number") return null
      return `Размер: ${file.size}`
    },
  },
  messages: {
    // Chonky UI translation strings. String IDs hardcoded into Chonky's source code.
    "chonky.toolbar.searchPlaceholder": "Поиск",
    "chonky.toolbar.visibleFileCount": `{fileCount, plural,
            =0 {}
            one {# файл}
            other {# файлов}
        }`,
    "chonky.toolbar.selectedFileCount": `{fileCount, plural,
            =0 {}
            one {# выделен}
            other {# выделено}
        }`,
    "chonky.toolbar.hiddenFileCount": `{fileCount, plural,
            =0 {}
            one {# скрыт}
            other {# скрыто}
        }`,
    "chonky.fileList.nothingToShow": "Здесь пусто!",
    "chonky.contextMenu.browserMenuShortcut": "Меню браузера: {shortcut}",

    // File action translation strings. These depend on which actions you have
    // enabled in Chonky.
    "chonky.actionGroups.Actions": "Действия",
    "chonky.actionGroups.Options": "Опции",
    "chonky.actions.copy_url.button.name": "Скопировать URL",
    "chonky.actions.delete_file.button.name": "Удалить",
    [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]:
      "Открыть родительскую папку",
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]:
      "Новая папка",
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]:
      "Создать новую папку",
    [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]:
      "Удалить файлы",
    [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]:
      "Открыть выделение",
    [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]:
      "Выделить все",
    [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]:
      "Сбросить выделение",
    [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]:
      "Показать список",
    [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]:
      "Показать иконки",
    [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]:
      "Сорт. по имени",
    [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]:
      "Сорт. по размеру",
    [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]:
      "Сорт. по дате",
    [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]:
      "Скрытые файлы",
    [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]:
      "Папки в начале",
    [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: "Загрузить",
    [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]: "Скачать",
  },
}

const fileToChonkyFormat = (file: File): FileData => {
  return {
    id: file.id_files,
    name: file.file_name,
    thumbnailUrl: `${HOST}${file.thumbnail_url}`,
    url: `${HOST}${file.url_file}`,
    modDate: file.date_creation,
  }
}

const FileBrowser = () => {
  const { data: fetchedFiles } = fileApi.useGetFilesQuery()
  const [deleteFiles] = fileApi.useDeleteFileMutation()
  const [uploadFiles] = fileApi.useUploadFilesMutation()

  const [files, setFiles] = useState<FileData[]>([])

  useEffect(() => {
    if (!fetchedFiles?.data) return

    setFiles(fetchedFiles.data.map((file) => fileToChonkyFormat(file)))
  }, [fetchedFiles])

  const copyUrl = defineFileAction(
    {
      id: "copy_url",
      button: {
        name: "Скопировать URL",
        toolbar: true,
        contextMenu: true,
        // group: 'Options',
      },
      requiresSelection: true,
    } as const,
    ({ state }) => {
      navigator.clipboard
        .writeText(state.selectedFiles[0].url)
        .then(() => toast.success("Скопировано!"))
    },
  )

  const deleteFile = defineFileAction(
    {
      id: "delete_file",
      button: {
        name: "Удалить",
        toolbar: true,
        contextMenu: true,
        // group: 'Options',
      },
      requiresSelection: true,
    } as const,
    ({ state }) => {
      deleteFiles(state.selectedFiles.map((file) => file.id))
    },
  )

  const myFileActions = [copyUrl, deleteFile]

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()

    if (event.target.files === null) return

    Array.prototype.forEach.call(event.target.files, (file) => {
      formData.append("files[]", file)
    })

    uploadFiles(formData).then(() => {
      toast.success("Файлы загружены")
    })
  }

  return (
    <div style={{ height: 300, marginTop: 20, marginBottom: 20 }}>
      <ChonkyFileBrowser
        files={files}
        fileActions={myFileActions}
        i18n={russianI18n}
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </ChonkyFileBrowser>
      <Button variant="outlined" component="label" style={{ marginTop: 10 }}>
        Загрузить файл
        <input type="file" multiple hidden onChange={handleFileSelect} />
      </Button>
    </div>
  )
}

export default FileBrowser
