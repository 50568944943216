import { FC, memo, useCallback, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"

import { Search } from "../../icons/Search"
import { staticApi } from "../../services/staticService"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import SelectGroup from "../groupedInput/SelectGroup"
import { isNumberArray } from "../../utils/typeguard"
import { SelectChange } from "../../types/SelectGroupChangeEventType"

interface StaticToolbarProps {
  title: string
}

const StaticsToolbar: FC<StaticToolbarProps> = ({ title }) => {
  const { data: fetchedStatuses } = staticApi.useGetStatusesQuery()

  const search = useTypedSelector((state) => state.filterStaticReducer.search)
  const filterStatuses = useTypedSelector(
    (state) => state.filterStaticReducer.statuses,
  )

  const actions = useActionCreators()

  const onModalOpen = () => {
    actions.setIsCreateModalOpen({ key: "statics", value: true })
  }

  const onChange = useCallback(
    (e: SelectChange) => {
      const value = e.target.value

      if (isNumberArray(value)) {
        actions.setStaticStatuses(value)
      }
    },
    [actions],
  )

  const statusesMemo = useMemo(() => {
    return {
      selectedArray: filterStatuses,
      values: fetchedStatuses?.data ?? [],
      onChange,
    }
  }, [fetchedStatuses?.data, filterStatuses, onChange])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: "10px" }}
            color="info"
            variant="outlined"
            onClick={() => actions.resetStaticFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button color="primary" variant="contained" onClick={onModalOpen}>
            Создать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Найти"
                  variant="outlined"
                  value={search}
                  onChange={(e) => actions.setStaticSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Статус"
                  property="id_static_page_statuses"
                  name="status_name"
                  multiple
                  {...statusesMemo}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default memo(StaticsToolbar)
