import { Dispatch, FC, FormEvent, SetStateAction, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Modal, Typography } from "@mui/material"
import { toast } from "react-toastify"

import { userApi } from "../../services/userService"

interface DeleteSubscriptionModalProps {
  isOpen: boolean
  subId: number
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const DeleteSubscriptionModal: FC<DeleteSubscriptionModalProps> = ({
  subId,
  setModalOpen,
  isOpen,
}) => {
  const { id } = useParams()

  const [deleteSubscription, { data: deleteSubscriptionResponse }] =
    userApi.useDeleteSubscriptionMutation()

  const onSubscriptionDelete = (e: FormEvent) => {
    e.preventDefault()

    if (!id || !+id) return

    deleteSubscription(subId)
  }

  useEffect(() => {
    if (!deleteSubscriptionResponse) return

    if (deleteSubscriptionResponse.status === "error") {
      toast.error("Произошла ошибка, попробуйте еще раз")

      return
    }

    if (deleteSubscriptionResponse.status === "successful") {
      toast.success("Подписка успешно удалена")
      setModalOpen(false)
    }
  }, [deleteSubscriptionResponse, setModalOpen])

  return (
    <Modal
      open={isOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" sx={{ mb: 2 }}>
          Вы действительно хотите удалить подписку?
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={onSubscriptionDelete}
        >
          Удалить
        </Button>
      </Box>
    </Modal>
  )
}

export default DeleteSubscriptionModal
