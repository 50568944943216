import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { setChonkyDefaults } from "chonky"
import { ChonkyIconFA } from "chonky-icon-fontawesome"
import { ThemeProvider } from "@mui/material"
import { unstable_ClassNameGenerator as ClassNameGenerator } from "@mui/material/className"

import App from "./App"
import { initStore } from "./store/store"
import { theme } from "./theme"

setChonkyDefaults({ iconComponent: ChonkyIconFA })

ClassNameGenerator.configure((componentName) => `my-${componentName}`)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={initStore()}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)
