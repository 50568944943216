export function getObjectFromQuery(
  query: string,
  arrayKeys?: string[],
  booleanKeys?: string[],
) {
  if (!query) return { page: 1, page_size: 20 }

  const queryMap = new Map()

  query
    .slice(1)
    .split("&")
    .forEach((q) => {
      const [key, value] = q.split("=")

      if (arrayKeys?.includes(key)) {
        if (key === "tags") {
          queryMap.set(key, value.split(","))

          return
        }

        const arr = value.split(",").map((v) => +v)

        if (arr.filter((v) => isNaN(v)).length) {
          return
        }

        queryMap.set(key, arr)

        return
      }

      if (booleanKeys?.includes(key)) {
        queryMap.set(key, value === "true")

        return
      }

      queryMap.set(key, +value ? +value : decodeURIComponent(value))
    })

  if (queryMap.has("sort")) {
    const [orderBy, order] = queryMap.get("sort").split("-")

    queryMap.delete("sort")
    queryMap.set("orderBy", orderBy)
    queryMap.set("order", order ? "desc" : "asc")
  }

  return Object.fromEntries(queryMap)
}
