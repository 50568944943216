import { ChangeEvent, FC, useRef } from "react"
import { FormLabel, IconButton, Typography, Button, Box } from "@mui/material"

import { LegendStyled } from "../styled/StyledComponents"
import TrashBin from "../../icons/TrashBin"
import { HOST } from "../../consts/hosts"

interface LogoInputGroupProps {
  name?: string
  fetchedLogo?: string | null
  title: string
  logoFileName: string
  accept?: string
  logoFile?: string | null
  handleFileSelect: (e: ChangeEvent<HTMLInputElement>) => void
  onLogoDelete?: (element: HTMLInputElement | null) => void
}
const LogoInputGroup: FC<LogoInputGroupProps> = ({
  name,
  fetchedLogo,
  title,
  logoFileName,
  onLogoDelete,
  handleFileSelect,
  accept,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const idRef = useRef(Math.random())

  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "15px",
          pl: "3px",
        }}
      >
        {logoFileName && (
          <>
            {fetchedLogo ? (
              <Typography
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`${HOST}${fetchedLogo}`}
              >
                {logoFileName}
              </Typography>
            ) : (
              <Typography>{logoFileName}</Typography>
            )}
            <IconButton
              color="error"
              onClick={() => onLogoDelete && onLogoDelete(inputRef.current)}
            >
              <TrashBin />
            </IconButton>
          </>
        )}

        {!logoFileName && (
          <Button
            variant="outlined"
            component="label"
            htmlFor={idRef.current.toString()}
          >
            Загрузить файл
          </Button>
        )}

        <input
          id={idRef.current.toString()}
          type="file"
          accept={accept ?? "image/png, image/jpeg"}
          hidden
          name={name}
          onChange={handleFileSelect}
          ref={inputRef}
        />
      </Box>
    </>
  )
}

export default LogoInputGroup
