import { FC, memo, useCallback, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"

import { instituteApi } from "../../services/instituteService"
import { supportApi } from "../../services/supportService"
import { Search } from "../../icons/Search"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { FilterInstituteSliceStateKeys } from "../../store/reducers/filterInstituteSlice"
import SelectGroup from "../groupedInput/SelectGroup"
import { isNumberArray } from "../../utils/typeguard"
import { SelectChange } from "../../types/SelectGroupChangeEventType"

interface InstitutesToolbarProps {
  title: string
}

const InstitutesToolbar: FC<InstitutesToolbarProps> = ({ title }) => {
  const { data: fetchedInstituteForms } =
    instituteApi.useGetInstituteFormsQuery()
  const { data: fetchedSupportForms } = supportApi.useGetSupportFormsQuery()
  const { data: fetchedMembers } = supportApi.useGetSupportMembersQuery()
  const { data: fetchedStatuses } = instituteApi.useGetInstituteStatusesQuery()

  const {
    search,
    forms: filterInstituteForms,
    support_forms: filterSupportForms,
    members: filterMembers,
    statuses: filterStatuses,
  } = useTypedSelector((state) => state.filterInstituteReducer)

  const actions = useActionCreators()

  const onModalOpen = () => {
    actions.setIsCreateModalOpen({ key: "institutes", value: true })
  }

  const handleFilterChange = useCallback(
    (e: SelectChange) => (key: FilterInstituteSliceStateKeys) => {
      const value = e.target.value

      if (isNumberArray(value)) {
        actions.setInstituteFilterArray({
          key,
          value,
        })
      }
    },
    [actions],
  )

  const instituteFormsMemo = useMemo(() => {
    return {
      selectedArray: filterInstituteForms,
      values: fetchedInstituteForms?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("forms"),
    }
  }, [fetchedInstituteForms?.data, filterInstituteForms, handleFilterChange])

  const statusesMemo = useMemo(() => {
    return {
      selectedArray: filterStatuses,
      values: fetchedStatuses?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("statuses"),
    }
  }, [fetchedStatuses?.data, filterStatuses, handleFilterChange])

  const supportFormsMemo = useMemo(() => {
    return {
      selectedArray: filterSupportForms,
      values: fetchedSupportForms?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("support_forms"),
    }
  }, [fetchedSupportForms?.data, filterSupportForms, handleFilterChange])

  const membersMemo = useMemo(() => {
    return {
      selectedArray: filterMembers,
      values: fetchedMembers?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("members"),
    }
  }, [fetchedMembers?.data, filterMembers, handleFilterChange])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: "10px" }}
            color="info"
            variant="outlined"
            onClick={() => actions.resetInstituteFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button color="primary" variant="contained" onClick={onModalOpen}>
            Создать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Найти"
                  variant="outlined"
                  value={search}
                  onChange={(e) => actions.setInstituteSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Форма института поддержки"
                  property="id_institution_forms"
                  name="institution_form_name"
                  multiple
                  {...instituteFormsMemo}
                />
                <SelectGroup
                  title="Статус"
                  property="id_institution_statuses"
                  name="status_name"
                  multiple
                  {...statusesMemo}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectGroup
                  title="Форма поддержки"
                  property="id_support_forms"
                  name="support_form_name"
                  multiple
                  {...supportFormsMemo}
                />
                <SelectGroup
                  title="Участники"
                  property="id_support_members"
                  name="support_members_name"
                  multiple
                  {...membersMemo}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default memo(InstitutesToolbar)
