import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { Box, Container, Typography } from "@mui/material"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import ItemsTable from "../components/ItemsTable"
import InstitutesToolbar from "../components/toolbars/InstitutesToolbar"
import CreateModal from "../components/modals/CreateModal"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { instituteApi } from "../services/instituteService"
import { INSTITUTES_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import {
  Institute,
  INSTITUTES_SORTS,
  InstituteSortsKeys,
} from "../types/institutes"
import ItemsManage from "../components/ItemsManage"
import Spinner from "../components/Spinner"
import DeleteModal from "../components/modals/DeleteModal"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"

const InstitutesPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchInstitutes, { data: fetchedInstitutes, isFetching }] =
    instituteApi.useLazyGetInstitutesQuery()
  const { data: fetchedStatuses } = instituteApi.useGetInstituteStatusesQuery()
  const [createInstitute, { data: createdInstitute }] =
    instituteApi.useCreateInstituteMutation()
  const [deleteInstitute] = instituteApi.useDeleteInstitutesMutation()
  const [changeStatuses] = instituteApi.useChangeStatusesMutation()

  const filter = useTypedSelector((state) => state.filterInstituteReducer)
  const selectedInstitutes = useTypedSelector(
    (state) => state.selectReducer.institutes,
  )
  const { deleteModalOpen, createModalOpen } = useTypedSelector(
    (state) => state.modalReducer.institutes,
  )
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: INSTITUTES_SORTS.id_institutes, headerName: "ID" },
      { field: INSTITUTES_SORTS.institute_name, headerName: "Название" },
      { field: INSTITUTES_SORTS.status_name, headerName: "Статус" },
      { field: INSTITUTES_SORTS.count_support, headerName: "Количество мер" },
      { field: INSTITUTES_SORTS.date_creation, headerName: "Добавлено" },
    ],
    [],
  )

  const rowTransform = (institutes: Institute[]) => {
    return institutes.map((institute) => ({
      ...institute,
      id: institute.id_institutes,
      name: institute.institute_name,
      date_creation: <SpanNoWrap>{institute.date_creation_dd}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedInstitutes) return []

    return rowTransform(fetchedInstitutes.list)
  }, [fetchedInstitutes])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id_institutes)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedInstitutes?.count ?? 0) / filter.page_size),
    [fetchedInstitutes?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${INSTITUTES_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(() => getQueryString(filter), [filter])

  const onAllRowsSelect = () => {
    if (selectedInstitutes.length !== rows.length) {
      actions.setSelectAll({ key: "institutes", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "institutes", value: [] })
    }
  }

  const onCreate = (name: string) => {
    createInstitute(name).then(() => {
      toast.success("Институт успешно создан!")
      actions.setIsCreateModalOpen({ key: "institutes", value: false })
    })
  }

  const onDelete = () => {
    deleteInstitute(selectedInstitutes).then(() => {
      toast.success("Записи удалены")
      actions.clearSelection("institutes")
    })
  }

  const onStatusChange = (statusId: number) => {
    changeStatuses(
      selectedInstitutes.map((id) => ({
        id_institutes: id,
        id_institution_statuses: statusId,
      })),
    ).then(() => {
      toast.success("Статус успешно изменен")
      actions.clearSelection("institutes")
    })
  }

  const onSortChange = (property: string) => {
    actions.setInstituteOrder(property as InstituteSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setInstitutePage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setInstitutePageSize(amount),
    [actions],
  )

  const fetchInstitutesFunc = useCallback(() => {
    fetchInstitutes({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        institution_forms: debouncedFilter.forms.join(","),
        support_forms: debouncedFilter.support_forms.join(","),
        support_members: debouncedFilter.members.join(","),
        status: debouncedFilter.statuses.join(","),
        institute_name: debouncedFilter.search,
        sort: debouncedSort,
      },
    })
  }, [fetchInstitutes, debouncedFilter, debouncedSort])

  useEffect(() => {
    actions.setInstituteFilter(
      getObjectFromQuery(location.search, [
        "forms",
        "support_forms",
        "members",
        "statuses",
      ]),
    )
  }, [actions, location.search])

  useEffect(() => {
    fetchInstitutesFunc()
  }, [fetchInstitutesFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    createdInstitute &&
      navigate(INSTITUTES_PAGE_ROUTE + `/${createdInstitute.id_institutes}`)
  }, [createdInstitute, navigate])

  return (
    <Layout title="Институты поддержки - ЦТТ Админ-панель" tabKey="institutes">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <InstitutesToolbar title="Институты" />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="institutes"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedInstitutes.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                rows={rows}
                columns={COLUMNS}
                type="institutes"
                order={filter.order}
                orderBy={filter.orderBy}
                route={INSTITUTES_PAGE_ROUTE}
                onAllSelect={onAllRowsSelect}
                onSortChange={onSortChange}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <CreateModal
        title="Добавить институт поддержки"
        isOpen={createModalOpen}
        onClose={() =>
          actions.setIsCreateModalOpen({ key: "institutes", value: false })
        }
        onSubmit={onCreate}
      />
      <ChangeStatusModal
        title="Изменить статус"
        property="id_institution_statuses"
        name="status_name"
        isOpen={isChangeStatusModalOpen}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        statuses={fetchedStatuses?.data ?? []}
        onSubmit={onStatusChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранные институты?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "institutes", value: false })
        }
      />
    </Layout>
  )
}

export default InstitutesPage
