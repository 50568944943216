import { FC, useState } from "react"
import {
  Box,
  Button,
  Modal,
  SelectChangeEvent,
  Typography,
} from "@mui/material"

import SelectGroup from "../groupedInput/SelectGroup"
import { useActionCreators } from "../../hooks/redux"

interface StatusModalProps {
  title: string
  selectTitle?: string
  isOpen: boolean
  property: string
  name: string
  statuses: any[]
  onClose: () => void
  onSubmit: (id: number) => void
}

const ChangeStatusModal: FC<StatusModalProps> = ({
  title,
  selectTitle,
  property,
  name,
  isOpen,
  statuses,
  onSubmit,
  onClose,
}) => {
  const [status, setStatus] = useState<number | undefined>(undefined)

  const actions = useActionCreators()

  const handleSelect = (
    e: SelectChangeEvent<number[] | string[] | string | number>,
  ) => {
    const value = e.target.value

    if (typeof value === "number") {
      setStatus(value)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <br />
        <SelectGroup
          title={selectTitle ?? "Новый статус"}
          values={statuses}
          property={property}
          name={name}
          selectedValue={status === undefined ? "" : `${status}`}
          onChange={handleSelect}
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="success"
          style={{ marginRight: 10 }}
          onClick={() => {
            status !== undefined && onSubmit(status)
            actions.setIsChangeStatusModalOpen(false)
          }}
          disabled={!status}
        >
          Подтвердить
        </Button>
        <Button variant="outlined" color="info" onClick={onClose}>
          Отменить
        </Button>
      </Box>
    </Modal>
  )
}

export default ChangeStatusModal
