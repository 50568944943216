import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import { Container, Paper, Grid, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import { instituteApi } from "../services/instituteService"
import { FormStyled } from "../components/styled/StyledComponents"
import InputGroup from "../components/groupedInput/InputGroup"
import HTMLEditorGroup from "../components/groupedInput/HTMLEditorGroup"
import SelectGroup from "../components/groupedInput/SelectGroup"
import { isStringArray } from "../utils/typeguard"
import CheckboxList from "../components/groupedInput/CheckboxList"
import { INSTITUTES_PAGE_ROUTE } from "../consts/routes"
import Spinner from "../components/Spinner"
import LogoInputGroup from "../components/groupedInput/LogoInputGroup"
import { useFileSelect } from "../hooks/useFileSelect"
import { useCheckboxList } from "../hooks/useCheckboxList"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import DeleteModal from "../components/modals/DeleteModal"
import { miscApi } from "../services/miscService"

const InstitutePage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const actions = useActionCreators()

  const [fetchInstitute, { data: fetchedInstitute }] =
    instituteApi.useLazyGetInstituteQuery()
  const [saveInstitute, { isLoading }] = instituteApi.useSaveInstituteMutation()
  const [deleteInstitute] = instituteApi.useDeleteInstitutesMutation()
  const [deleteLogo] = instituteApi.useDeleteLogoMutation()
  const { data: statuses } = instituteApi.useGetInstituteStatusesQuery()
  const { data: forms } = instituteApi.useGetInstituteFormsQuery()
  const { data: regions } = miscApi.useGetRegionsQuery()

  const isOpen = useTypedSelector(
    (state) => state.modalReducer.institutes.deleteModalOpen,
  )

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [statusId, setStatusId] = useState(1)
  const [regionsId, setRegionsId] = useState<string[]>([])
  const [formsId, setFormsId, onFormSelect] = useCheckboxList()

  const { logoFile, logoFileName, setLogoFileName, setLogoFile, onFileSelect } =
    useFileSelect()

  const onSave = async () => {
    if (!fetchedInstitute) return

    const { data: institute } = fetchedInstitute

    if (!institute) return

    const response = await saveInstitute({
      id_institutes: institute.id_institutes,
      id_institution_statuses: statusId,
      institute_name: name,
      site: url,
      description,
      logo: logoFile ? logoFile : undefined,
      institution_forms_id: formsId.join(","),
      institution_regions_id: regionsId.join(","),
      title_meta: metaTitle,
      keywords_meta: metaKeywords,
      description_meta: metaDescription,
    }).unwrap()

    if (response.status === "error") {
      toast.error("Не удалось сохранить")
    } else {
      toast.success("Успешно сохранено")
    }
  }

  const onDelete = () => {
    id &&
      deleteInstitute([+id]).then(() => {
        toast.success("Успешно удалено")
        navigate(INSTITUTES_PAGE_ROUTE)
      })
  }

  const onLogoDelete = () => {
    if (!id) return

    if (fetchedInstitute?.data?.logo) {
      deleteLogo(+id).then(() => {
        toast.warning("Успешно удалено")
        setLogoFile(null)
      })

      return
    }

    if (logoFileName) {
      setLogoFileName("")
      setLogoFile(null)
    }
  }

  useEffect(() => {
    if (!fetchedInstitute?.data) return

    const { data } = fetchedInstitute

    setName(data.institute_name)
    setStatusId(data.id_institution_statuses)
    setFormsId(data.institution_forms.map((form) => form.id_institution_forms))
    setRegionsId(data.regions.map((region) => region.id_regions.toString()))

    data.description !== null && setDescription(data.description)
    data.site !== null && setUrl(data.site)
    data.title_meta !== null && setMetaTitle(data.title_meta)
    data.keywords_meta !== null && setMetaKeywords(data.keywords_meta)
    data.description_meta !== null && setMetaDescription(data.description_meta)
    data.logo !== null && setLogoFileName(data.logo.split("/").at(-1) as string)
  }, [fetchedInstitute, setFormsId, setLogoFileName])

  useEffect(() => {
    id && fetchInstitute(+id)
  }, [fetchInstitute, id])

  if (!fetchedInstitute) {
    return (
      <Layout
        title="Редактирование института - ЦТТ Админ-панель"
        tabKey="institutes"
      >
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout
      title="Редактирование института - ЦТТ Админ-панель"
      tabKey="institutes"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4">
            Редактирование института
          </Typography>
          <Paper sx={{ mt: 3 }}>
            <Box component={FormStyled} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputGroup
                    title="Название"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <HTMLEditorGroup
                    title="Описание"
                    value={description}
                    setValue={(value) => setDescription(value)}
                  />

                  <SelectGroup
                    title="Регионы"
                    property="id_regions"
                    name="region_name"
                    multiple
                    selectedArray={regionsId}
                    values={regions?.data ?? []}
                    onChange={(e) => {
                      const idArray = e.target.value
                      typeof idArray !== "string" &&
                        isStringArray(idArray) &&
                        setRegionsId(idArray)
                    }}
                  />

                  <InputGroup
                    title="Сайт"
                    value={url}
                    Helper={
                      <span>
                        Сайт необходимо указывать в формате{" "}
                        <b>https://example.ru</b>
                      </span>
                    }
                    onChange={(e) => setUrl(e.target.value)}
                  />

                  <LogoInputGroup
                    title="Логотип"
                    fetchedLogo={fetchedInstitute?.data?.logo}
                    logoFile={logoFile}
                    logoFileName={logoFileName}
                    handleFileSelect={onFileSelect}
                    onLogoDelete={onLogoDelete}
                  />

                  <InputGroup
                    title="Заголовок meta"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />

                  <InputGroup
                    title="Ключевые слова meta"
                    value={metaKeywords}
                    onChange={(e) => setMetaKeywords(e.target.value)}
                  />

                  <InputGroup
                    title="Описание meta"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectGroup
                    title="Статус"
                    property="id_institution_statuses"
                    name="status_name"
                    sx={{ width: "50%" }}
                    selectedValue={`${statusId}`}
                    values={statuses?.data ?? []}
                    onChange={(e) => setStatusId(+e.target.value)}
                  />

                  <CheckboxList
                    title="Формы"
                    property="id_institution_forms"
                    name="institution_form_name"
                    items={forms?.data ?? []}
                    selectedIds={formsId}
                    onSelect={onFormSelect}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button
                    sx={{ mr: "10px" }}
                    color="error"
                    variant="contained"
                    onClick={() =>
                      actions.setIsDeleteModalOpen({
                        key: "institutes",
                        value: true,
                      })
                    }
                  >
                    Удалить
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={onSave}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
      <DeleteModal
        text="Вы точно хотите удалить этот институт?"
        onDelete={onDelete}
        isOpen={isOpen}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "institutes", value: false })
        }
      />
    </Layout>
  )
}

export default InstitutePage
