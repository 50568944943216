import { FC } from "react"
import { Editor } from "@tinymce/tinymce-react"

interface HTMLEditorProps {
  value: string
  setValue: (value: string) => void
}

const HTMLEditor: FC<HTMLEditorProps> = ({ value, setValue }) => {
  return (
    <Editor
      value={value}
      onEditorChange={setValue}
      // initialValue={value}
      init={{
        height: 300,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | link image | help",
        extended_valid_elements:
          "img[*],style[*],i[*],a[*],h1[*],h2[*],h3[*],h4[*],section[*],button[*],p[*],span[*]",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        language: "ru",
      }}
    />
  )
}

export default HTMLEditor
