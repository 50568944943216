import { FC } from "react"
import { Box, Button, Modal, Typography } from "@mui/material"

interface DeleteModalProps {
  text: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

const DeleteModal: FC<DeleteModalProps> = ({
  text,
  isOpen,
  onDelete,
  onClose,
}) => {
  const handleDelete = () => {
    onDelete()
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h6"
          color="red"
        >
          ВНИМАНИЕ!
        </Typography>
        <br />
        <Typography color="red">{text}</Typography>
        <br />
        <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
          <Button variant="outlined" color="info" onClick={onClose}>
            Отменить
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Удалить
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteModal
