import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Layout from "../components/Layout"
import { authApi } from "../services/authService"
import { LOGIN_PAGE_ROUTE, SUPPORTS_PAGE_ROUTE } from "../consts/routes"

const MainPage = () => {
  const navigate = useNavigate()

  const { data: user } = authApi.useGetIdQuery()

  useEffect(() => {
    if (user) {
      navigate(SUPPORTS_PAGE_ROUTE)
    } else {
      navigate(LOGIN_PAGE_ROUTE)
    }
  }, [navigate, user])

  return <Layout title="ЦТТ Админ-панель" tabKey="main" />
}

export default MainPage
