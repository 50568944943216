import { useState, FC, useRef, memo } from "react"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

import { UserCircle as UserCircleIcon } from "../../icons/UserCircle"
import { authApi } from "../../services/authService"
import { LOGIN_PAGE_ROUTE } from "../../consts/routes"
import { DashboardNavbarRoot } from "../styled/StyledComponents"

interface DashboardNavbarProps {
  onPopupOpen: () => void
}

const DashboardNavbar: FC<DashboardNavbarProps> = ({ onPopupOpen }) => {
  const navigate = useNavigate()

  const { data: user } = authApi.useGetIdQuery()
  const [logout] = authApi.useLogoutMutation()

  const [isOpen, setIsOpen] = useState(false)
  const anchorEl = useRef(null)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handlePopoverClick = () => {
    if (user?.userid === undefined) {
      navigate(LOGIN_PAGE_ROUTE)

      return
    }

    handleLogout()
  }

  const handleLogout = () => {
    logout().then(() => {
      navigate(LOGIN_PAGE_ROUTE)
    })
  }

  return (
    <>
      <DashboardNavbarRoot
        sx={{ left: { lg: 280 }, width: { lg: "calc(100% - 280px)" } }}
      >
        <Toolbar disableGutters sx={{ minHeight: 64, left: 0, px: 2 }}>
          <IconButton
            onClick={onPopupOpen}
            sx={{
              display: { xs: "inline-flex", lg: "none" },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          {/*<Tooltip title="Contacts">*/}
          {/*    <IconButton sx={{ml: 1}}>*/}
          {/*        <UsersIcon fontSize="small"/>*/}
          {/*    </IconButton>*/}
          {/*</Tooltip>*/}
          {/*<Tooltip title="Notifications">*/}
          {/*    <IconButton sx={{ml: 1}}>*/}
          {/*        <Badge*/}
          {/*          badgeContent={4}*/}
          {/*          color="primary"*/}
          {/*          variant="dot"*/}
          {/*        >*/}
          {/*            <BellIcon fontSize="small"/>*/}
          {/*        </Badge>*/}
          {/*    </IconButton>*/}
          {/*</Tooltip>*/}
          <Avatar
            ref={anchorEl}
            sx={{ height: 40, width: 40, ml: 1, cursor: "pointer" }}
            src="/static/images/avatars/avatar_1.png"
            aria-describedby={isOpen ? "avatar-popover" : undefined}
            onClick={handleOpen}
          >
            <UserCircleIcon fontSize="small" />
          </Avatar>
          <Popover
            id="avatar-popover"
            open={isOpen}
            onClose={handleClose}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 1, backgroundColor: "#FAFAFA" }}>
              <Button variant="text" onClick={handlePopoverClick}>
                {user?.userid === undefined ? "Войти" : "Выйти"}
              </Button>
            </Typography>
          </Popover>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  )
}

export default memo(DashboardNavbar)
