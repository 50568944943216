import { FC } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Typography } from "@mui/material"

import { Innovation } from "../../types/innovations"
import InputGroup from "../groupedInput/InputGroup"
import { innovationApi } from "../../services/innovationService"
import { useFileSelect } from "../../hooks/useFileSelect"
import LogoInputGroup from "../groupedInput/LogoInputGroup"

interface FilesProps {
  innovation?: Innovation
}

const Files: FC<FilesProps> = ({ innovation }) => {
  const { id } = useParams()

  const [deletePresentation] = innovationApi.useDeletePresentationMutation()
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()

  const {
    logoFileName: presentationFileName,
    onFileSelect: onPresentationSelect,
    setLogoFileName: setPresentationFileName,
    setLogoFile: setPresentationFile,
  } = useFileSelect(innovation?.presentation?.split("/").at(-1))

  const onPresentationDelete = (element: HTMLInputElement | null) => {
    if (element) element.value = ""

    if (!id) return

    if (innovation?.presentation) {
      deletePresentation(+id).then(() => {
        toast.warning("Успешно удалено")
        setPresentationFileName("")
        setPresentationFile(null)
      })

      return
    }

    if (presentationFileName) {
      setPresentationFileName("")
      setPresentationFile(null)
    }
  }

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Файлы
      </Typography>

      {defaultFields?.presentation?.active && (
        <LogoInputGroup
          title={defaultFields.presentation.label}
          fetchedLogo={innovation?.presentation}
          logoFileName={presentationFileName}
          handleFileSelect={onPresentationSelect}
          onLogoDelete={onPresentationDelete}
          name="presentation"
          accept=".ppt,.pptx,.pdf"
        />
      )}

      {defaultFields?.video_presentation_link?.active && (
        <InputGroup
          title={defaultFields.video_presentation_link.label}
          textFieldProps={{
            name: "video_presentation_link",
            defaultValue: innovation?.video_presentation_link,
            type: "url",
          }}
        />
      )}
    </>
  )
}

export default Files
