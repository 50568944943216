import { FC } from "react"
import { HelmetProvider, Helmet } from "react-helmet-async"

interface HeadMetaComponentProps {
  title: string
}

const HeadMetaComponent: FC<HeadMetaComponentProps> = ({ title }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default HeadMetaComponent
