import { ChangeEvent, useEffect, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import {
  Phone,
  Email,
  Person,
  LocationCity,
  Work,
  Search,
} from "@mui/icons-material"
import { toast } from "react-toastify"
import InputMask from "react-input-mask"
import dayjs from "dayjs"

import SelectGroup from "../groupedInput/SelectGroup"
import DateGroup from "../groupedInput/DateGroup"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { UserFilterStateStrings } from "../../types/users"
import { SelectChange } from "../../types/SelectGroupChangeEventType"
import { isNumberArray } from "../../utils/typeguard"
import { miscApi } from "../../services/miscService"
import { HOST } from "../../consts/hosts"
import { userApi } from "../../services/userService"

const UsersToolbar = () => {
  const actions = useActionCreators()

  const { data: fetchedRegions } = miscApi.useGetRegionsQuery()
  const [exportUsers, { data: exportUsersResponse }] =
    userApi.useExportUsersMutation()

  const filter = useTypedSelector((state) => state.filterUserReducer.filter)
  const selectedUsers = useTypedSelector((state) => state.selectReducer.users)

  const onDataExport = () => {
    toast.info("Загрузка скоро начнется...")
    exportUsers(selectedUsers.map((id) => ({ id })))
  }

  const setStateString = (
    key: keyof UserFilterStateStrings,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    actions.setUserFilterString({ key, value: event.target.value })
  }

  const regionsMemo = useMemo(() => {
    return {
      values: fetchedRegions?.data ?? [],
      selectedArray: filter.region,
      onChange: (e: SelectChange) => {
        const idArray = e.target.value

        isNumberArray(idArray) && actions.setUserFilterRegions(idArray)
      },
    }
  }, [actions, fetchedRegions?.data, filter.region])

  useEffect(() => {
    if (!exportUsersResponse) return

    if (exportUsersResponse.status === "error") {
      toast.error("Неизвестная ошибка")

      return
    }

    if (exportUsersResponse.status === "successful") {
      window.open(HOST + exportUsersResponse.file, "_blank")
    }
  }, [exportUsersResponse])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          Пользователи
        </Typography>
        <Box>
          <Button
            color="info"
            variant="outlined"
            onClick={() => actions.resetUserFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button
            color="primary"
            variant="outlined"
            sx={{ mx: "10px" }}
            href={`${HOST}/logs/archive_logs.csv`}
            target="_blank"
            rel="noreferrer"
            download
          >
            Скачать логи
          </Button>
          <Button color="primary" variant="contained" onClick={onDataExport}>
            Экспортировать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Person />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="ФИО"
                  variant="outlined"
                  value={filter.fio}
                  onChange={(e) => setStateString("fio", e)}
                />
              </Grid>
              <Grid item xs={2}>
                <InputMask
                  mask="+7 (999) 999-99-99"
                  value={filter.phone}
                  onChange={(e) => setStateString("phone", e)}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon color="action" fontSize="small">
                            <Phone />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Телефон"
                    variant="outlined"
                    type="tel"
                  />
                </InputMask>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Email />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Email"
                  variant="outlined"
                  type="email"
                  value={filter.email}
                  onChange={(e) => setStateString("email", e)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={filter.activate_email}
                      onChange={(e) =>
                        actions.setUserFilterBoolean({
                          key: "activate_email",
                          value: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Email подтвержден"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Регион"
                  multiple
                  property="id_regions"
                  name="region_name"
                  {...regionsMemo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <LocationCity />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Город"
                  variant="outlined"
                  value={filter.locality}
                  onChange={(e) => setStateString("locality", e)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Work />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Место работы / учебы"
                  variant="outlined"
                  value={filter.place_of_work_study}
                  onChange={(e) => setStateString("place_of_work_study", e)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Должность"
                  variant="outlined"
                  value={filter.position}
                  onChange={(e) => setStateString("position", e)}
                />
              </Grid>
              <Grid item xs={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filter.subscription}
                        onChange={(e) =>
                          actions.setUserFilterBoolean({
                            key: "subscription",
                            value: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Есть подписки"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
                <DateGroup
                  title="Дата создания (с / по)"
                  value={
                    filter.start_date_reg ? dayjs(filter.start_date_reg) : null
                  }
                  maxDate={
                    filter.end_date_reg ? dayjs(filter.end_date_reg) : dayjs()
                  }
                  onChange={(date) =>
                    actions.setUserFilterDate({
                      key: "start_date_reg",
                      value: date === null ? null : date.format("YYYY-MM-DD"),
                    })
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <DateGroup
                  title=""
                  value={
                    filter.end_date_reg ? dayjs(filter.end_date_reg) : null
                  }
                  maxDate={dayjs()}
                  minDate={dayjs(filter.start_date_reg)}
                  onChange={(date) =>
                    actions.setUserFilterDate({
                      key: "end_date_reg",
                      value: date === null ? null : date.format("YYYY-MM-DD"),
                    })
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default UsersToolbar
