import { readFileAsBase64 } from "./readFileAsBase64"

export async function getFilesArrayFromInputByName(
  name: string,
  maxFiles?: number,
): Promise<{ file_name: string; file: string }[]> {
  const element = document.querySelector(`[name=${name}]`)

  if (element === null) return []

  const input = element as HTMLInputElement

  let files: { file_name: string; file: string }[] = []

  const inputFiles = input.files

  if (!inputFiles || inputFiles.length === 0) return []

  for (let i = 0; i < inputFiles.length; i++) {
    const file = inputFiles[i]

    if (file.size === 0) continue

    files.push({
      file_name: file.name,
      file: await readFileAsBase64(file),
    })
  }

  if (maxFiles) {
    files = files.slice(0, maxFiles)
  }

  return files
}
