import { Link } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout title="404" tabKey="404">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          top: "300px",
        }}
      >
        <Typography variant="h4" component="h4">
          Страница не найдена!
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ width: "150px", mt: 2 }}
        >
          На главную
        </Button>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
