import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  ChangeSubscriptionTitleRequest,
  ChangeUserRole,
  DetailedUser,
  ExportUsersResponse,
  SaveUser,
  SearchUser,
  User,
  UserGroup,
  Users,
  UserSubscription,
} from "../types/users"

import { baseQuery } from "./baseQuery"

export const userApi = createApi({
  reducerPath: "user",
  baseQuery,
  tagTypes: ["USERS", "SUBSCRIPTIONS"],
  endpoints: (build) => ({
    getUsers: build.query<Users, IDefaultQuery<SearchUser>>({
      query: ({ page, search }) => ({
        url: `/getusers/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["USERS"],
      transformResponse(response: IDefaultResponse<User[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
        }
      },
    }),
    getUser: build.query<IDefaultResponse<DetailedUser>, number>({
      query: (id) => `/getuser/${id}`,
      providesTags: (result) => [{ type: "USERS", id: result?.data?.id }],
    }),
    getGroups: build.query<IDefaultResponse<UserGroup[]>, void>({
      query: () => "/getgroups",
    }),
    getRole: build.query<IDefaultResponse<number>, void>({
      query: () => "/getadminlog",
    }),
    getSubscriptions: build.query<IDefaultResponse<UserSubscription[]>, number>(
      {
        query: (id) => `/getsubscriptions/${id}`,
        providesTags: ["SUBSCRIPTIONS"],
      },
    ),
    deleteUsers: build.mutation<IDefaultResponse<void>, number[]>({
      query: (idArray) => ({
        url: "/deleteusers",
        body: {
          list: idArray.map((id) => ({ id })),
        },
        method: "POST",
      }),
      invalidatesTags: ["USERS"],
    }),
    changeRoles: build.mutation<IDefaultResponse<void>, ChangeUserRole[]>({
      query: (list) => ({
        url: "/sendgroup",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) =>
        arg.map(({ id }) => ({ type: "USERS", id })),
    }),
    changePassword: build.mutation<
      IDefaultResponse<void>,
      { id: number; new_password: string }
    >({
      query: (body) => ({
        url: "/sendnewpassword",
        body,
        method: "POST",
      }),
    }),
    saveUser: build.mutation<IDefaultResponse<void>, SaveUser>({
      query: (body) => ({
        url: "/senduser",
        body,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "USERS", id: arg.id }],
    }),
    subscribeToFilter: build.mutation<
      IDefaultResponse<void>,
      { id: number; url: string }
    >({
      query: (body) => ({
        url: "/sendsubscribe",
        body,
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
    changeSubscriptionTitle: build.mutation<
      IDefaultResponse<void>,
      ChangeSubscriptionTitleRequest
    >({
      query: (body) => ({
        url: "/sendsubscribename",
        body,
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
    deleteSubscription: build.mutation<IDefaultResponse<void>, number>({
      query: (id_subscriptions) => ({
        url: "/deletesubscribe",
        body: { id_subscriptions },
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
    exportUsers: build.mutation<ExportUsersResponse, { id: number }[]>({
      query: (list) => ({
        url: "/exportcheckedusers",
        body: { list },
        method: "POST",
      }),
    }),
  }),
})
