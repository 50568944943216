import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { SupportSortsKeys } from "../../types/supports"

interface InitialStateArrays {
  forms: number[]
  members: number[]
  directions: number[]
  reasons: number[]
  statuses: number[]
}

interface InitialState extends InitialStateArrays {
  search: string
  order: "asc" | "desc"
  orderBy: SupportSortsKeys | null
  page: number
  page_size: number
}

export type FilterSupportSliceStateKeys = keyof InitialStateArrays

interface SetFilterArrayType {
  key: FilterSupportSliceStateKeys
  value: number[]
}

const initialState: InitialState = {
  search: "",
  order: "asc",
  orderBy: null,
  page: 1,
  page_size: 20,
  forms: [],
  members: [],
  directions: [],
  reasons: [],
  statuses: [],
}

export const filterSupportSlice = createSlice({
  name: "filterSupport",
  initialState,
  reducers: {
    setSupportSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.page = 1
    },
    setSupportOrder(state, action: PayloadAction<SupportSortsKeys | null>) {
      const orderBy = action.payload

      if (orderBy === state.orderBy) {
        state.order = state.order === "asc" ? "desc" : "asc"

        return
      }

      state.orderBy = orderBy
      state.order = "asc"
    },
    setSupportFilterArray(state, action: PayloadAction<SetFilterArrayType>) {
      const { key, value } = action.payload

      state[key] = value
      state.page = 1
    },
    setSupportPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setSupportPageSize(state, action: PayloadAction<number>) {
      state.page_size = action.payload
    },
    setSupportFilter(state, action: PayloadAction<Partial<InitialState>>) {
      for (const key in action.payload) {
        if (initialState.hasOwnProperty(key)) {
          //@ts-ignore
          state[key] = action.payload[key]
        }
      }
    },
    resetSupportFilters(state) {
      state.search = ""
      state.forms = []
      state.members = []
      state.directions = []
      state.reasons = []
      state.statuses = []
    },
  },
})

export const filterSupportReducer = filterSupportSlice.reducer
