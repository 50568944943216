export const isStringArray = (value: any): value is string[] => {
  if (!Array.isArray(value)) return false

  if (value.length === 0) return true

  return typeof value[0] === "string"
}

export const isNumberArray = (value: any): value is number[] => {
  if (!Array.isArray(value)) return false

  if (value.length === 0) return true

  return typeof value[0] === "number"
}
