import { FC, memo, useCallback, useState, PropsWithChildren } from "react"
import { Box } from "@mui/material"

import { DashboardLayoutRoot } from "../styled/StyledComponents"

import DashboardNavbar from "./DashboardNavbar"
import DashboardSidebar from "./DashboardSidebar"

interface DashboardLayoutProps {
  tabKey?: string
}

const DashboardLayout: FC<PropsWithChildren<DashboardLayoutProps>> = ({
  children,
  tabKey,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  const onPopupOpen = useCallback(() => setSidebarOpen(true), [])
  const onSidebarClose = useCallback(() => setSidebarOpen(false), [])

  return (
    <>
      <DashboardNavbar onPopupOpen={onPopupOpen} />
      <DashboardSidebar
        tabKey={tabKey ?? ""}
        onClose={onSidebarClose}
        isOpen={isSidebarOpen}
      />
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
    </>
  )
}

export default memo(DashboardLayout)
