import { FC, memo, useCallback, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"

import { supportApi } from "../../services/supportService"
import { Search } from "../../icons/Search"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { FilterSupportSliceStateKeys } from "../../store/reducers/filterSupportSlice"
import SelectGroup from "../groupedInput/SelectGroup"
import { isNumberArray } from "../../utils/typeguard"
import { SelectChange } from "../../types/SelectGroupChangeEventType"

interface SupportsToolbarProps {
  title: string
}

const SupportsToolbar: FC<SupportsToolbarProps> = ({ title }) => {
  const { data: fetchedForms } = supportApi.useGetSupportFormsQuery()
  const { data: fetchedMembers } = supportApi.useGetSupportMembersQuery()
  const { data: fetchedDirections } = supportApi.useGetSupportDirectionsQuery()
  const { data: fetchedReasons } = supportApi.useGetSupportReasonsQuery()
  const { data: fetchedStatuses } = supportApi.useGetSupportStatusesQuery()

  const { search, forms, directions, statuses, members, reasons } =
    useTypedSelector((state) => state.filterSupportReducer)

  const actions = useActionCreators()

  const onModalOpen = () => {
    actions.setIsCreateModalOpen({ key: "supports", value: true })
  }

  const handleFilterChange = useCallback(
    (e: SelectChange) => (key: FilterSupportSliceStateKeys) => {
      const value = e.target.value

      if (isNumberArray(value)) {
        actions.setSupportFilterArray({
          key,
          value,
        })
      }
    },
    [actions],
  )

  const formsMemo = useMemo(() => {
    return {
      values: fetchedForms?.data ?? [],
      selectedArray: forms,
      onChange: (e: SelectChange) => handleFilterChange(e)("forms"),
    }
  }, [fetchedForms?.data, forms, handleFilterChange])

  const directionsMemo = useMemo(() => {
    return {
      selectedArray: directions,
      values: fetchedDirections?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("directions"),
    }
  }, [fetchedDirections?.data, directions, handleFilterChange])

  const statusesMemo = useMemo(() => {
    return {
      selectedArray: statuses,
      values: fetchedStatuses?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("statuses"),
    }
  }, [fetchedStatuses?.data, statuses, handleFilterChange])

  const membersMemo = useMemo(() => {
    return {
      selectedArray: members,
      values: fetchedMembers?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("members"),
    }
  }, [fetchedMembers?.data, members, handleFilterChange])

  const reasonsMemo = useMemo(() => {
    return {
      selectedArray: reasons,
      values: fetchedReasons?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("reasons"),
    }
  }, [fetchedReasons?.data, reasons, handleFilterChange])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: "10px" }}
            color="info"
            variant="outlined"
            onClick={() => actions.resetSupportFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button color="primary" variant="contained" onClick={onModalOpen}>
            Создать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Найти"
                  variant="outlined"
                  value={search}
                  onChange={(e) => actions.setSupportSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Форма поддержки"
                  property="id_support_forms"
                  name="support_form_name"
                  multiple
                  {...formsMemo}
                />
                <SelectGroup
                  title="Направления поддержки"
                  property="id_support_directions"
                  name="support_direction_name"
                  multiple
                  {...directionsMemo}
                />
                <SelectGroup
                  title="Статус"
                  property="id_support_statuses"
                  name="status_name"
                  multiple
                  {...statusesMemo}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Участники"
                  property="id_support_members"
                  name="support_members_name"
                  multiple
                  {...membersMemo}
                />
                <SelectGroup
                  title="Потребность"
                  property="id_support_reasons"
                  name="support_reason_name"
                  multiple
                  {...reasonsMemo}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default memo(SupportsToolbar)
