import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
} from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { toast } from "react-toastify"

import { userApi } from "../../services/userService"

interface ChangePasswordModalProps {
  isOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  setModalOpen,
  isOpen,
}) => {
  const { id } = useParams()

  const [changePassword, { data: changePasswordResponse, isLoading }] =
    userApi.useChangePasswordMutation()

  const newPasswordRef = useRef<HTMLInputElement>(null)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!newPasswordRef.current || !id || !+id) return

    const newPassword = newPasswordRef.current.value

    if (newPassword === "") {
      return toast.error("Введите новый пароль")
    }

    changePassword({ id: +id, new_password: newPassword })
  }

  useEffect(() => {
    if (!changePasswordResponse) return

    if (changePasswordResponse.status === "error") {
      toast.error("Произошла ошибка, попробуйте еще раз")

      return
    }

    if (changePasswordResponse.status === "successful") {
      toast.success("Пароль успешно изменен")
      setModalOpen(false)
    }
  }, [changePasswordResponse, setModalOpen])

  return (
    <Modal
      open={isOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6">
          Изменение пароля
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            fullWidth
            type="password"
            placeholder="Новый пароль"
            size="small"
            sx={{ my: 3 }}
            inputRef={newPasswordRef}
          />
          {!isLoading && (
            <Button variant="contained" color="success" type="submit">
              Подтвердить
            </Button>
          )}
        </form>
      </Box>
    </Modal>
  )
}

export default ChangePasswordModal
