import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultResponse } from "../types/api"
import { IAuthRequest, UserIdResponse } from "../types/auth"
import { CurrentUser } from "../types/users"

import { baseQuery } from "./baseQuery"

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery,
  tagTypes: ["USER_ID"],
  endpoints: (build) => ({
    sendAuth: build.mutation<IDefaultResponse<CurrentUser>, IAuthRequest>({
      query: (body) => ({
        url: "/sendauth",
        body,
        method: "POST",
      }),
      invalidatesTags: ["USER_ID"],
    }),
    getId: build.query<UserIdResponse, void>({
      query: () => "/getuserid",
      providesTags: ["USER_ID"],
    }),
    logout: build.mutation<IDefaultResponse<void>, void>({
      query: () => "/logout",
      invalidatesTags: ["USER_ID"],
    }),
  }),
})
