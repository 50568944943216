import { ChangeEvent, FC } from "react"
import { FormLabel, NativeSelect, OutlinedInput } from "@mui/material"

import { LegendStyled } from "../../styled/StyledComponents"

interface InnovationSelectProps {
  title: string
  values: any[]
  name: string
  defaultValue?: number | null
  idProp: string
  valueProp: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const InnovationSelect: FC<InnovationSelectProps> = ({
  values,
  title,
  defaultValue,
  name,
  idProp,
  valueProp,
  onChange,
}) => {
  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <NativeSelect
        fullWidth
        size="small"
        input={<OutlinedInput />}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {values.map((value) => (
          <option key={value[idProp]} value={value[idProp]}>
            {value[valueProp]}
          </option>
        ))}
      </NativeSelect>
    </>
  )
}

export default InnovationSelect
