export function getQueryString<
  T extends { order: "asc" | "desc"; orderBy: string | null },
>(filter: T) {
  const queryArr: string[] = []

  const order = filter.order
  const orderBy = filter.orderBy

  const sort =
    orderBy === null ? "" : orderBy + (order === "asc" ? "" : "-inverse")

  if (sort !== "") {
    queryArr.push(`sort=${sort}`)
  }

  for (const [key, value] of Object.entries(filter)) {
    if (!value || (Array.isArray(value) && value.length === 0)) continue

    if (key === "order" || key === "orderBy") continue

    if (Array.isArray(value)) {
      queryArr.push(`${key}=${value.join(",")}`)
    } else {
      queryArr.push(`${key}=${value}`)
    }
  }

  return "?" + queryArr.join("&")
}
