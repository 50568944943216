import { memo, useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"

import { userApi } from "../services/userService"
import { PUBLIC_HOST } from "../consts/hosts"

import { LinkStyled } from "./styled/StyledComponents"
import Spinner from "./Spinner"
import DeleteSubscriptionModal from "./modals/DeleteSubscriptionModal"

const SubscriptionTable = () => {
  const { id } = useParams()

  const [changeSubTitle, { isLoading: isTitleLoading }] =
    userApi.useChangeSubscriptionTitleMutation()

  const [isDeleteSubModalOpen, setIsDeleteSubModalOpen] = useState(false)
  const [subIdToDelete, setSubIdToDelete] = useState(0)
  const [editingRowId, setEditingRowId] = useState<number | null>(null)

  const editingInputRef = useRef<HTMLInputElement>(null)

  const onRowTitleClick = (id: number) => {
    setEditingRowId(id)
  }

  const handleTitleChange = () => {
    setEditingRowId(null)

    const title = editingInputRef.current?.value

    if (!title || !editingRowId) return

    changeSubTitle({ id_subscriptions: editingRowId, subscription_name: title })
  }

  useEffect(() => {
    editingInputRef.current?.focus()
  }, [editingRowId])

  if (!id || !+id) {
    return <></>
  }

  const { data: subscriptions, isLoading } = userApi.useGetSubscriptionsQuery(
    +id,
  )

  if (isLoading) {
    return <Spinner />
  }

  if (!subscriptions?.data?.length) {
    return <Typography sx={{ mt: 2 }}>Нет подписок</Typography>
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell>URL</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.data.map((row) => (
            <TableRow
              key={row.id_subscriptions}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                {editingRowId === row.id_subscriptions ? (
                  <TextField
                    size="small"
                    defaultValue={row.subscription_name}
                    onKeyDown={(e) => e.key === "Enter" && handleTitleChange()}
                    onBlur={handleTitleChange}
                    inputRef={editingInputRef}
                  />
                ) : (
                  <Button onClick={() => onRowTitleClick(row.id_subscriptions)}>
                    {isTitleLoading ? "..." : row.subscription_name}
                  </Button>
                )}
              </TableCell>
              <TableCell
                sx={{
                  maxWidth: "600px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <LinkStyled
                  target="_blank"
                  to={PUBLIC_HOST + row.url}
                  title={PUBLIC_HOST + row.url}
                >
                  {PUBLIC_HOST + row.url}
                </LinkStyled>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => {
                    setSubIdToDelete(row.id_subscriptions)
                    setIsDeleteSubModalOpen(true)
                  }}
                >
                  Удалить
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteSubscriptionModal
        isOpen={isDeleteSubModalOpen}
        setModalOpen={setIsDeleteSubModalOpen}
        subId={subIdToDelete}
      />
    </TableContainer>
  )
}

export default memo(SubscriptionTable)
