import { useMemo } from "react"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "@reduxjs/toolkit"

import { AppDispatch, RootState } from "../store/store"
import { modalSlice } from "../store/reducers/modalSlice"
import { filterSupportSlice } from "../store/reducers/filterSupportSlice"
import { filterInstituteSlice } from "../store/reducers/filterInstituteSlice"
import { filterNewsSlice } from "../store/reducers/filterNewsSlice"
import { filterStaticSlice } from "../store/reducers/filterStaticSlice"
import { selectSlice } from "../store/reducers/selectSlice"
import { filterUserSlice } from "../store/reducers/filterUserSlice"
import { filterInnovationSlice } from "../store/reducers/filterInnovationSlice"

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export const useActionCreators = () => {
  const dispatch = useAppDispatch()

  const actions = useMemo(
    () => ({
      ...modalSlice.actions,
      ...filterSupportSlice.actions,
      ...filterInstituteSlice.actions,
      ...filterNewsSlice.actions,
      ...filterStaticSlice.actions,
      ...filterUserSlice.actions,
      ...filterInnovationSlice.actions,
      ...selectSlice.actions,
    }),
    [],
  )

  return useMemo(
    () => bindActionCreators(actions, dispatch),
    [actions, dispatch],
  )
}
