import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  ChangeSupportStatus,
  CreatedSupport,
  Support,
  SupportDirection,
  SupportForm,
  SupportMember,
  SupportReason,
  Supports,
  SupportSave,
  SupportSearch,
  SupportStatus,
} from "../types/supports"

import { baseQuery } from "./baseQuery"

export const supportApi = createApi({
  reducerPath: "support",
  baseQuery,
  tagTypes: ["SUPPORTS"],
  endpoints: (build) => ({
    getSupports: build.query<Supports, IDefaultQuery<SupportSearch>>({
      query: ({ page, search }) => ({
        url: `/getsupports/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["SUPPORTS"],
      transformResponse(response: IDefaultResponse<Support[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
          min_amount: response.min_amount || 0,
          max_amount: response.max_amount || 100000,
        }
      },
    }),
    getSupport: build.query<IDefaultResponse<Support>, number>({
      query: (id) => ({
        url: `/getsupport/${id}`,
      }),
    }),
    getSupportStatuses: build.query<IDefaultResponse<SupportStatus[]>, void>({
      query: () => ({
        url: "/getsupportstatuses",
      }),
    }),
    getSupportForms: build.query<IDefaultResponse<SupportForm[]>, void>({
      query: () => ({
        url: "/getsupportforms",
      }),
    }),
    getSupportDirections: build.query<
      IDefaultResponse<SupportDirection[]>,
      void
    >({
      query: () => ({
        url: "/getsupportdirections",
      }),
    }),
    getSupportMembers: build.query<IDefaultResponse<SupportMember[]>, void>({
      query: () => ({
        url: "/getsupportmembers",
      }),
    }),
    getSupportReasons: build.query<IDefaultResponse<SupportReason[]>, void>({
      query: () => ({
        url: "/getsupportreasons",
      }),
    }),
    createSupport: build.mutation<CreatedSupport, string>({
      query: (support_name) => ({
        url: "/sendnewsupport",
        body: { support_name },
        method: "POST",
      }),
      invalidatesTags: ["SUPPORTS"],
    }),
    deleteSupports: build.mutation<IDefaultResponse<void>, number[]>({
      query: (idArray) => ({
        url: "/deletesupports",
        body: {
          list: idArray.map((id) => ({ id_supports: id })),
        },
        method: "POST",
      }),
      invalidatesTags: ["SUPPORTS"],
    }),
    changeStatuses: build.mutation<
      IDefaultResponse<void>,
      ChangeSupportStatus[]
    >({
      query: (list) => ({
        url: "/sendsupportstatuses",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: ["SUPPORTS"],
    }),
    saveSupport: build.mutation<{ status: string }, SupportSave>({
      query: (support) => ({
        url: "/sendsupport",
        body: support,
        method: "POST",
      }),
    }),
    deleteLogo: build.mutation<IDefaultResponse<void>, number>({
      query: (id) => ({
        url: "/deletesupportlogo",
        body: { id_supports: id },
        method: "POST",
      }),
    }),
  }),
})
