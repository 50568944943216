import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import AppRouter from "./components/AppRouter"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppRouter />
        <ToastContainer />
      </BrowserRouter>
    </div>
  )
}

export default App
