import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  ChangeInnovationStatus,
  CreateAdditionalField,
  Innovation,
  InnovationAdditionalField,
  InnovationDefaultField,
  InnovationDemand,
  InnovationDirection,
  InnovationFinanceSource,
  InnovationIndustry,
  InnovationMarket,
  InnovationSearch,
  InnovationShort,
  InnovationStage,
  InnovationStatus,
  InnovationTechnology,
  InnovationTRL,
  SaveAdditionalField,
  SaveDefaultField,
} from "../types/innovations"

import { baseQuery } from "./baseQuery"

export const innovationApi = createApi({
  reducerPath: "innovation",
  baseQuery,
  tagTypes: ["INNOVATIONS", "DEFAULT_FIELDS", "ADDITIONAL_FIELDS"],
  endpoints: (build) => ({
    getInnovations: build.query<
      IDefaultResponse<InnovationShort[]>,
      IDefaultQuery<InnovationSearch>
    >({
      query: ({ page, search }) => ({
        url: `/getinnovations/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      providesTags: ["INNOVATIONS"],
    }),
    getInnovation: build.query<IDefaultResponse<Innovation>, number>({
      query: (id) => `/getinnovation/${id}`,
      providesTags: (result) => [
        { type: "INNOVATIONS", id: result?.data?.id_innovations },
      ],
    }),
    createInnovation: build.mutation<
      IDefaultResponse<void> & { id_innovations: number },
      string
    >({
      query: (name) => ({
        url: "/sendinnovation",
        body: { innovation_name: name },
        method: "POST",
      }),
      invalidatesTags: ["INNOVATIONS"],
    }),
    saveInnovation: build.mutation<
      IDefaultResponse<void> & { id_innovations: number },
      Record<string, any>
    >({
      query: (body) => ({
        url: "/sendinnovation",
        body,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "INNOVATIONS", id: arg.id_innovations },
      ],
    }),
    deleteInnovation: build.mutation<IDefaultResponse<void>, number[]>({
      query: (idArray) => ({
        url: "/deleteinnovations",
        body: { list: idArray.map((id) => ({ id_innovations: id })) },
        method: "POST",
      }),
      invalidatesTags: ["INNOVATIONS"],
    }),
    changeStatuses: build.mutation<
      IDefaultResponse<void>,
      ChangeInnovationStatus[]
    >({
      query: (list) => ({
        url: "/sendinnovationstatuses",
        body: { list },
        method: "POST",
      }),
      invalidatesTags: ["INNOVATIONS"],
    }),
    getStatuses: build.query<IDefaultResponse<InnovationStatus[]>, void>({
      query: () => "/getinnovationstatuses",
    }),
    getIndustries: build.query<IDefaultResponse<InnovationIndustry[]>, void>({
      query: () => "/getinnovationindustries",
    }),
    getFilterIndustries: build.query<
      IDefaultResponse<InnovationIndustry[]>,
      void
    >({
      query: () => "/getsearchinnovationindustries",
    }),
    getMarkets: build.query<IDefaultResponse<InnovationMarket[]>, void>({
      query: () => "/getinnovationmarkets",
    }),
    getFilterMarkets: build.query<IDefaultResponse<InnovationMarket[]>, void>({
      query: () => "/getsearchinnovationmarkets",
    }),
    getStages: build.query<IDefaultResponse<InnovationStage[]>, void>({
      query: () => "/getinnovationstages",
    }),
    getDirections: build.query<IDefaultResponse<InnovationDirection[]>, void>({
      query: () => "/getinnovationdirections",
    }),
    getFinanceSources: build.query<
      IDefaultResponse<InnovationFinanceSource[]>,
      void
    >({
      query: () => "/getinnovationfinancesources",
    }),
    getTechnologies: build.query<
      IDefaultResponse<InnovationTechnology[]>,
      void
    >({
      query: () => "/getinnovationtechnologies",
    }),
    getTRL: build.query<IDefaultResponse<InnovationTRL[]>, void>({
      query: () => "/getinnovationtrls",
    }),
    getDemands: build.query<IDefaultResponse<InnovationDemand[]>, void>({
      query: () => "/getinnovationdemands",
    }),
    getDefaultFields: build.query<Record<string, InnovationDefaultField>, void>(
      {
        query: () => "/getdefaultfields",
        providesTags: ["DEFAULT_FIELDS"],
        transformResponse: (
          response: IDefaultResponse<InnovationDefaultField[]>,
        ) => {
          const returnObj: Record<string, InnovationDefaultField> = {}
          const data = response.data

          data?.forEach((field) => (returnObj[field.field_name] = field))

          return returnObj
        },
      },
    ),
    saveDefaultFields: build.mutation<
      IDefaultResponse<void>,
      SaveDefaultField[]
    >({
      query: (body) => ({
        url: "/senddefaultfield",
        body,
        method: "POST",
      }),
      invalidatesTags: ["DEFAULT_FIELDS"],
    }),
    getAdditionalFields: build.query<
      IDefaultResponse<InnovationAdditionalField[]>,
      void
    >({
      query: () => "/getadditionalfields",
      providesTags: ["ADDITIONAL_FIELDS"],
    }),
    createAdditionalField: build.mutation<
      IDefaultResponse<void>,
      CreateAdditionalField
    >({
      query: (body) => ({
        url: "/addadditionalfield",
        body,
        method: "POST",
      }),
      invalidatesTags: ["ADDITIONAL_FIELDS"],
    }),
    saveAdditionalField: build.mutation<
      IDefaultResponse<void>,
      SaveAdditionalField
    >({
      query: (body) => ({
        url: "/sendadditionalfield",
        body,
        method: "POST",
      }),
      invalidatesTags: ["ADDITIONAL_FIELDS"],
    }),
    deleteAdditionalField: build.mutation<IDefaultResponse<void>, number>({
      query: (id) => ({
        url: "/deleteadditionalfield",
        body: { id_innovation_fields: id },
        method: "POST",
      }),
      invalidatesTags: ["ADDITIONAL_FIELDS"],
    }),
    deleteLogo: build.mutation<IDefaultResponse<void>, number>({
      query: (id_innovations) => ({
        url: "/deleteinnovationlogo",
        body: { id_innovations },
        method: "POST",
      }),
    }),
    deleteWallpaper: build.mutation<IDefaultResponse<void>, number>({
      query: (id_innovations) => ({
        url: "/deleteinnovationwallpaper",
        body: { id_innovations },
        method: "POST",
      }),
    }),
    deletePresentation: build.mutation<IDefaultResponse<void>, number>({
      query: (id_innovations) => ({
        url: "/deleteinnovationpresentation",
        body: { id_innovations },
        method: "POST",
      }),
    }),
    deleteProductPhoto: build.mutation<IDefaultResponse<void>, number>({
      query: (id_innovation_images) => ({
        url: "/deleteinnovationimages",
        body: { id_innovation_images },
        method: "POST",
      }),
    }),
    deleteMemberLogo: build.mutation<IDefaultResponse<void>, number>({
      query: (id_members) => ({
        url: "/deletememberlogo",
        body: { id_members },
        method: "POST",
      }),
    }),
  }),
})
