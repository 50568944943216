import { FC } from "react"
import { Box, Typography, Button } from "@mui/material"

import { useActionCreators } from "../hooks/redux"
import { SelectSliceStateKeys } from "../store/reducers/selectSlice"
import { userApi } from "../services/userService"

import ItemsManageControls from "./ItemsManageControls"

interface ListManageProps {
  page: number
  pageCount: number
  pageSize: number
  selectedAmount: number
  type: SelectSliceStateKeys
  changeBtnName?: string
  onPageChange: (page: number) => void
  onPageSizeChange: (e: number) => void
}

const ItemsManage: FC<ListManageProps> = ({
  selectedAmount,
  type,
  changeBtnName,
  ...props
}) => {
  const actions = useActionCreators()

  const { data: userRole } = userApi.useGetRoleQuery()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "30px",
        position: "sticky",
        top: 0,
        padding: "20px 0",
        backgroundColor: "background.default",
        zIndex: 5,
        borderBottom: "1px solid #5048E5",
      }}
    >
      <ItemsManageControls {...props} />
      <Typography sx={{ width: "130px" }}>
        Выделено: {selectedAmount}
      </Typography>
      <Button
        color="warning"
        variant="outlined"
        disabled={selectedAmount === 0}
        onClick={() => actions.clearSelection(type)}
      >
        Снять выделение
      </Button>
      {(type !== "users" || (type === "users" && userRole?.data === 1)) && (
        <Button
          color="info"
          variant="outlined"
          disabled={selectedAmount === 0}
          onClick={() => actions.setIsChangeStatusModalOpen(true)}
        >
          {changeBtnName ?? "Изменить статус"}
        </Button>
      )}
      <Button
        color="error"
        variant="outlined"
        disabled={selectedAmount === 0}
        onClick={() => actions.setIsDeleteModalOpen({ key: type, value: true })}
      >
        Удалить выбранное
      </Button>
    </Box>
  )
}

export default ItemsManage
