import { FC } from "react"
import { Box, Button, Paper, Typography } from "@mui/material"

import { InnovationVacancy } from "../../../types/innovations"
import InputGroup from "../../groupedInput/InputGroup"

interface VacancyInputsProps {
  defaultVacancy: InnovationVacancy
  label: string
  name: string
  onRemove: (id: number) => void
}

const VacancyInputs: FC<VacancyInputsProps> = ({
  defaultVacancy,
  name,
  label,
  onRemove,
}) => {
  return (
    <Paper
      className="vacancy"
      sx={{ p: 3, mt: 3, border: "1px solid #e5e8ef" }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{label}</Typography>
        <Button
          variant="contained"
          color="error"
          onClick={() => onRemove && onRemove(defaultVacancy.id_vacancies)}
        >
          Удалить
        </Button>
      </Box>

      <InputGroup
        title="Название вакансии"
        textFieldProps={{
          name: `${name}_name`,
          defaultValue: defaultVacancy.vacancy_name,
        }}
      />

      <InputGroup
        title="Описание вакансии"
        textFieldProps={{
          name: `${name}_description`,
          defaultValue: defaultVacancy.description,
        }}
      />

      <InputGroup
        title="Количество мест"
        textFieldProps={{
          name: `${name}_amount`,
          defaultValue: defaultVacancy.amount,
          type: "number",
        }}
        sx={{ width: "auto" }}
      />
    </Paper>
  )
}

export default VacancyInputs
