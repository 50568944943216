import { FC, memo } from "react"
import { MenuItem, Pagination, Select } from "@mui/material"

interface ItemsManageControlsProps {
  page: number
  pageCount: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (size: number) => void
}

const ItemsManageControls: FC<ItemsManageControlsProps> = ({
  page,
  pageCount,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  return (
    <>
      <Pagination
        count={pageCount < 0 ? 1 : pageCount}
        color="primary"
        page={page}
        onChange={(e, page) => onPageChange(page)}
      />
      <div>
        <span>
          Выводить по:{" "}
          <Select
            size="small"
            value={pageSize}
            onChange={(event) => onPageSizeChange(+event.target.value)}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={-1}>все</MenuItem>
          </Select>
        </span>
      </div>
    </>
  )
}

export default memo(ItemsManageControls)
