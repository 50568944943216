import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type PageKeys =
  | "supports"
  | "institutes"
  | "news"
  | "statics"
  | "users"
  | "innovations"

interface ModalPayload {
  key: PageKeys
  value: boolean
}

const initialState = {
  supports: {
    createModalOpen: false,
    deleteModalOpen: false,
  },
  news: {
    createModalOpen: false,
    deleteModalOpen: false,
  },
  institutes: {
    createModalOpen: false,
    deleteModalOpen: false,
  },
  statics: {
    createModalOpen: false,
    deleteModalOpen: false,
  },
  users: {
    createModalOpen: false,
    deleteModalOpen: false,
  },
  innovations: {
    createModalOpen: false,
    deleteModalOpen: false,
  },

  isChangeStatusModalOpen: false,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setIsCreateModalOpen(state, action: PayloadAction<ModalPayload>) {
      const { key, value } = action.payload

      state[key].createModalOpen = value
    },
    setIsDeleteModalOpen(state, action: PayloadAction<ModalPayload>) {
      const { key, value } = action.payload

      state[key].deleteModalOpen = value
    },
    // setIsSupportModalOpen(state, action: ModalPayload) {
    //   state.isSupportModalOpen = action.payload
    // },
    // setIsInstituteModalOpen(state, action: ModalPayload) {
    //   state.isInstituteModalOpen = action.payload
    // },
    // setIsNewsModalOpen(state, action: ModalPayload) {
    //   state.isNewsModalOpen = action.payload
    // },
    // setIsStaticModalOpen(state, action: ModalPayload) {
    //   state.isStaticModalOpen = action.payload
    // },
    setIsChangeStatusModalOpen(state, action: PayloadAction<boolean>) {
      state.isChangeStatusModalOpen = action.payload
    },
  },
})

export const modalReducer = modalSlice.reducer
