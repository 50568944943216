import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { NewsSortsKeys } from "../../types/news"

interface InitialState {
  tags: string[]
  statuses: number[]
  search: string
  order: "asc" | "desc"
  orderBy: NewsSortsKeys | null
  page: number
  page_size: number
}

const initialState: InitialState = {
  search: "",
  order: "asc",
  orderBy: null,
  page: 1,
  page_size: 20,
  tags: [],
  statuses: [],
}

export const filterNewsSlice = createSlice({
  name: "filterNews",
  initialState,
  reducers: {
    setNewsSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.page = 1
    },
    setNewsOrder(state, action: PayloadAction<NewsSortsKeys | null>) {
      const orderBy = action.payload

      if (orderBy === state.orderBy) {
        state.order = state.order === "asc" ? "desc" : "asc"

        return
      }

      state.orderBy = orderBy
      state.order = "asc"
    },
    setNewsCategories(state, action: PayloadAction<string[]>) {
      state.tags = action.payload
      state.page = 1
    },
    setNewsStatuses(state, action: PayloadAction<number[]>) {
      state.statuses = action.payload
      state.page = 1
    },
    setNewsPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setNewsPageSize(state, action: PayloadAction<number>) {
      state.page_size = action.payload
    },
    setNewsFilter(state, action: PayloadAction<Partial<InitialState>>) {
      for (const key in action.payload) {
        if (initialState.hasOwnProperty(key)) {
          //@ts-ignore
          state[key] = action.payload[key]
        }
      }
    },
    resetNewsFilters(state) {
      state.search = ""
      state.tags = []
      state.statuses = []
    },
  },
})

export const filterNewsReducer = filterNewsSlice.reducer
