export function readFileAsBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64 = reader.result?.toString() ?? ""
      resolve(base64)
    }

    reader.onerror = reject
  })
}
