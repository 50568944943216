export const MAIN_PAGE_ROUTE = "/"
export const LOGIN_PAGE_ROUTE = "/login"
export const SUPPORTS_PAGE_ROUTE = "/supports"
export const SUPPORT_PAGE_ROUTE = "/supports/:id"
export const INSTITUTES_PAGE_ROUTE = "/institutes"
export const INSTITUTE_PAGE_ROUTE = "/institutes/:id"
export const NEWS_PAGE_ROUTE = "/news"
export const NEWS_DETAILED_PAGE_ROUTE = "/news/:id"
export const STATICS_PAGE_ROUTE = "/statics"
export const STATIC_PAGE_ROUTE = "/statics/:id"
export const USERS_PAGE_ROUTE = "/users"
export const USER_PAGE_ROUTE = "/users/:id"
export const INNOVATIONS_PAGE_ROUTE = "/innovations"
export const INNOVATION_PAGE_ROUTE = "/innovations/:id"
export const FORM_EDIT_ROUTE = "/forms"
