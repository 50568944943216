import { FC, useState } from "react"
import { Typography, Button, Box } from "@mui/material"

import { Innovation, InnovationMember } from "../../types/innovations"
import { innovationApi } from "../../services/innovationService"
import InputGroup from "../groupedInput/InputGroup"

import MemberInputs from "./Inputs/MemberInputs"

interface TeamProps {
  innovation?: Innovation
}

const Team: FC<TeamProps> = ({ innovation }) => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()

  const [members, setMembers] = useState<InnovationMember[]>(
    () => innovation?.members.slice(1) ?? [],
  )

  const generateMember = (): InnovationMember => ({
    id_members: Math.random(),
    leader: false,
    fio: "",
    position: "",
    degree: "",
    logo: "",
  })

  const addMember = () => {
    setMembers([...members, generateMember()])
  }

  const removeMember = (deleteId: number) => {
    setMembers(members.filter((member) => member.id_members !== deleteId))
  }

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Команда
      </Typography>

      <MemberInputs
        name="member_0"
        label="Руководитель проекта"
        isLeader
        defaultMember={innovation?.members[0] ?? generateMember()}
      />

      {defaultFields?.count_members?.active && (
        <InputGroup
          title={defaultFields.count_members.label}
          textFieldProps={{
            name: "count_members",
            defaultValue: innovation?.count_members,
            type: "number",
          }}
          sx={{ width: "auto" }}
        />
      )}

      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="info" fullWidth onClick={addMember}>
          Добавить участника
        </Button>
      </Box>

      {members.map((member, index) => (
        <MemberInputs
          key={member.id_members}
          name={`member_${index + 1}`}
          label={"Участник " + (index + 1)}
          onRemove={removeMember}
          defaultMember={member}
        />
      ))}
    </>
  )
}

export default Team
