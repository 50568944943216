import { FC, useCallback, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"

import { Search } from "../../icons/Search"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import SelectGroup from "../groupedInput/SelectGroup"
import { innovationApi } from "../../services/innovationService"
import { SelectChange } from "../../types/SelectGroupChangeEventType"
import { isNumberArray } from "../../utils/typeguard"
import { FilterInnovationSliceStateKeys } from "../../store/reducers/filterInnovationSlice"

interface InnovationsToolbarProps {
  title: string
}

const InnovationsToolbar: FC<InnovationsToolbarProps> = ({ title }) => {
  const actions = useActionCreators()

  const { data: fetchedIndustries } = innovationApi.useGetIndustriesQuery()
  const { data: fetchedMarkets } = innovationApi.useGetMarketsQuery()
  const { data: fetchedStatuses } = innovationApi.useGetStatusesQuery()

  const {
    search,
    innovation_markets: filterMarkets,
    status: filterStatuses,
    industries: filterIndustries,
    fundraising,
    legal_entity,
  } = useTypedSelector((state) => state.filterInnovationReducer)

  const onModalOpen = () => {
    actions.setIsCreateModalOpen({ key: "innovations", value: true })
  }

  const handleFilterChange = useCallback(
    (e: SelectChange) => (key: FilterInnovationSliceStateKeys) => {
      const value = e.target.value

      if (isNumberArray(value)) {
        actions.setInnovationFilterArray({
          key,
          value,
        })
      }
    },
    [actions],
  )

  const innovationMarketsMemo = useMemo(() => {
    return {
      selectedArray: filterMarkets,
      values: fetchedMarkets?.data ?? [],
      onChange: (e: SelectChange) =>
        handleFilterChange(e)("innovation_markets"),
    }
  }, [fetchedMarkets?.data, filterMarkets, handleFilterChange])

  const innovationStatusesMemo = useMemo(() => {
    return {
      selectedArray: filterStatuses,
      values: fetchedStatuses?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("status"),
    }
  }, [fetchedStatuses?.data, filterStatuses, handleFilterChange])

  const innovationIndustriesMemo = useMemo(() => {
    return {
      selectedArray: filterIndustries,
      values: fetchedIndustries?.data ?? [],
      onChange: (e: SelectChange) => handleFilterChange(e)("industries"),
    }
  }, [fetchedIndustries?.data, filterIndustries, handleFilterChange])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: "10px" }}
            color="info"
            variant="outlined"
            onClick={() => actions.resetInnovationFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button color="primary" variant="contained" onClick={onModalOpen}>
            Создать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Найти"
                  variant="outlined"
                  value={search}
                  onChange={(e) => actions.setInnovationSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Отрасль"
                  property="id_industries"
                  name="industry_name"
                  multiple
                  {...innovationIndustriesMemo}
                />
                <SelectGroup
                  title="Рынок NTI"
                  property="id_innovation_markets"
                  name="market_name"
                  multiple
                  {...innovationMarketsMemo}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Статус"
                  property="id_innovation_statuses"
                  name="status_name"
                  multiple
                  {...innovationStatusesMemo}
                />

                <Box sx={{ mt: 3 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!!fundraising}
                          onChange={(e) =>
                            actions.setInnovationBoolean({
                              key: "fundraising",
                              value: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Привлекал финансирование"
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={!!legal_entity}
                          onChange={(e) =>
                            actions.setInnovationBoolean({
                              key: "legal_entity",
                              value: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Есть юр. лицо"
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default InnovationsToolbar
