import { FC } from "react"
import { FormLabel } from "@mui/material"

import { LegendStyled } from "../styled/StyledComponents"
import HTMLEditor from "../HTMLEditor"

interface HTMLEditorGroupProps {
  title: string
  value: string
  setValue: (newValue: string) => void
}

const HTMLEditorGroup: FC<HTMLEditorGroupProps> = ({
  title,
  value,
  setValue,
}) => {
  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <HTMLEditor value={value} setValue={setValue} />
    </>
  )
}

export default HTMLEditorGroup
