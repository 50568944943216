import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import dayjs, { Dayjs } from "dayjs"
import Box from "@mui/material/Box"
import { Container, Grid, Paper, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import { FormStyled } from "../components/styled/StyledComponents"
import InputGroup from "../components/groupedInput/InputGroup"
import HTMLEditorGroup from "../components/groupedInput/HTMLEditorGroup"
import SelectGroup from "../components/groupedInput/SelectGroup"
import DateGroup from "../components/groupedInput/DateGroup"
import Spinner from "../components/Spinner"
import FileBrowser from "../components/FileBrowser"
import { staticApi } from "../services/staticService"
import { STATICS_PAGE_ROUTE } from "../consts/routes"
import DeleteModal from "../components/modals/DeleteModal"
import { useActionCreators, useTypedSelector } from "../hooks/redux"

const StaticPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const actions = useActionCreators()

  const [fetchStatic, { data: fetchedStatic }] =
    staticApi.useLazyGetStaticQuery()
  const [saveStatic, { isLoading }] = staticApi.useSaveStaticMutation()
  const [deleteStatic] = staticApi.useDeleteStaticMutation()
  const { data: statuses } = staticApi.useGetStatusesQuery()

  const isOpen = useTypedSelector(
    (state) => state.modalReducer.statics.deleteModalOpen,
  )

  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [urlPath, setUrlPath] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [statusId, setStatusId] = useState(1)
  const [createDate, setCreateDate] = useState<Dayjs | null>(null)

  const onSave = async () => {
    if (!fetchedStatic) return

    const { data: staticData } = fetchedStatic

    if (!staticData) return

    const response = await saveStatic({
      id_static_pages: staticData.id_static_pages,
      title,
      content,
      alt_name: urlPath,
      date_creation: createDate?.format("YYYY-MM-DD HH:mm:ss"),
      title_meta: metaTitle,
      keywords_meta: metaKeywords,
      description_meta: metaDescription,
      id_static_page_statuses: statusId,
    }).unwrap()

    if (response.status === "error") {
      toast.error("Не удалось сохранить")
    } else {
      toast.success("Успешно сохранено")
    }
  }

  const onDelete = () => {
    id &&
      deleteStatic([+id]).then(() => {
        toast.success("Успешно удалено")
        navigate(STATICS_PAGE_ROUTE)
      })
  }

  useEffect(() => {
    if (!fetchedStatic?.data) return

    const { data } = fetchedStatic

    setTitle(data.title)
    setStatusId(data.id_static_page_statuses)
    setCreateDate(dayjs(data.date_creation))
    setUrlPath(data.alt_name)

    data.content !== null && setContent(data.content)
    data.title_meta !== null && setMetaTitle(data.title_meta)
    data.keywords_meta !== null && setMetaKeywords(data.keywords_meta)
    data.description_meta !== null && setMetaDescription(data.description_meta)
  }, [fetchedStatic])

  useEffect(() => {
    id && fetchStatic(id)
  }, [fetchStatic, id])

  if (!fetchedStatic) {
    return (
      <Layout
        title="Редактирование статичной страницы - ЦТТ Админ-панель"
        tabKey="statics"
      >
        <Spinner sx={{ position: "absolute", top: "50%", left: "58%" }} />
      </Layout>
    )
  }

  return (
    <Layout
      title="Редактирование статичной страницы - ЦТТ Админ-панель"
      tabKey="statics"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h4" component="h4">
            Редактирование статичной страницы
          </Typography>
          <Paper sx={{ mt: 3 }}>
            <Box component={FormStyled} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <InputGroup
                    title="Заголовок"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <HTMLEditorGroup
                    title="Содержимое"
                    value={content}
                    setValue={(value) => setContent(value)}
                  />

                  <InputGroup
                    title="Заголовок meta"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                  />

                  <InputGroup
                    title="Ключевые слова meta"
                    value={metaKeywords}
                    onChange={(e) => setMetaKeywords(e.target.value)}
                  />

                  <InputGroup
                    title="Описание meta"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />

                  <InputGroup
                    title="URL"
                    value={urlPath}
                    onChange={(e) => setUrlPath(e.target.value)}
                  />

                  <FileBrowser />
                </Grid>

                <Grid item xs={4}>
                  <SelectGroup
                    title="Статус"
                    property="id_static_page_statuses"
                    name="status_name"
                    sx={{ width: "50%" }}
                    selectedValue={`${statusId}`}
                    values={statuses?.data ?? []}
                    onChange={(e) => setStatusId(+e.target.value)}
                  />

                  <DateGroup
                    title="Дата публикации"
                    value={createDate}
                    onChange={(newValue: any) => setCreateDate(newValue)}
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button
                    sx={{ mr: "10px" }}
                    color="error"
                    variant="contained"
                    onClick={() =>
                      actions.setIsDeleteModalOpen({
                        key: "statics",
                        value: true,
                      })
                    }
                  >
                    Удалить
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={onSave}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
      <DeleteModal
        text="Вы точно хотите удалить эту страницу?"
        onDelete={onDelete}
        isOpen={isOpen}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "statics", value: false })
        }
      />
    </Layout>
  )
}

export default StaticPage
