import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { InstituteSortsKeys } from "../../types/institutes"

interface InitialStateArrays {
  forms: number[]
  support_forms: number[]
  members: number[]
  statuses: number[]
}

interface InitialState extends InitialStateArrays {
  search: string
  order: "asc" | "desc"
  orderBy: InstituteSortsKeys | null
  page: number
  page_size: number
}

export type FilterInstituteSliceStateKeys = keyof InitialStateArrays

interface SetFilterArrayType {
  key: FilterInstituteSliceStateKeys
  value: number[]
}

const initialState: InitialState = {
  search: "",
  order: "asc",
  orderBy: null,
  page: 1,
  page_size: 20,
  forms: [],
  support_forms: [],
  members: [],
  statuses: [],
}

export const filterInstituteSlice = createSlice({
  name: "filterInstitute",
  initialState,
  reducers: {
    setInstituteSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.page = 1
    },
    setInstituteOrder(state, action: PayloadAction<InstituteSortsKeys | null>) {
      const orderBy = action.payload

      if (orderBy === state.orderBy) {
        state.order = state.order === "asc" ? "desc" : "asc"

        return
      }

      state.orderBy = orderBy
      state.order = "asc"
    },
    setInstituteFilterArray(state, action: PayloadAction<SetFilterArrayType>) {
      state[action.payload.key] = action.payload.value
      state.page = 1
    },
    setInstitutePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setInstitutePageSize(state, action: PayloadAction<number>) {
      state.page_size = action.payload
    },
    setInstituteFilter(state, action: PayloadAction<Partial<InitialState>>) {
      for (const key in action.payload) {
        if (initialState.hasOwnProperty(key)) {
          //@ts-ignore
          state[key] = action.payload[key]
        }
      }
    },
    resetInstituteFilters(state) {
      state.search = ""
      state.forms = []
      state.support_forms = []
      state.members = []
      state.statuses = []
    },
  },
})

export const filterInstituteReducer = filterInstituteSlice.reducer
