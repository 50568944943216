import {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
} from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { toast } from "react-toastify"

import { userApi } from "../../services/userService"

interface AddSubscriptionModalProps {
  isOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const AddSubscriptionModal: FC<AddSubscriptionModalProps> = ({
  setModalOpen,
  isOpen,
}) => {
  const { id } = useParams()

  const [addSubscription, { data: addSubscriptionResponse }] =
    userApi.useSubscribeToFilterMutation()

  const subscriptionRef = useRef<HTMLInputElement>(null)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!subscriptionRef.current || !id || !+id) return

    addSubscription({
      id: +id,
      url: subscriptionRef.current.value,
    })
  }

  useEffect(() => {
    if (!addSubscriptionResponse) return

    if (addSubscriptionResponse.status === "error") {
      toast.error("Произошла ошибка, попробуйте еще раз")

      return
    }

    if (addSubscriptionResponse.status === "successful") {
      toast.success("Подписка успешно добавлена")
      setModalOpen(false)
    }
  }, [addSubscriptionResponse, setModalOpen])

  return (
    <Modal
      open={isOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6">
          Добавление подписки
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            fullWidth
            type="text"
            placeholder="URL"
            size="small"
            sx={{ my: 3 }}
            inputRef={subscriptionRef}
          />
          <Button variant="contained" color="success" type="submit">
            Добавить
          </Button>
        </form>
      </Box>
    </Modal>
  )
}

export default AddSubscriptionModal
