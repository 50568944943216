import { FC, useState } from "react"
import { Box, Button, Typography } from "@mui/material"

import { Innovation, InnovationVacancy } from "../../types/innovations"
import { innovationApi } from "../../services/innovationService"
import CheckboxList from "../groupedInput/CheckboxList"
import InputGroup from "../groupedInput/InputGroup"
import { useCheckboxList } from "../../hooks/useCheckboxList"

import VacancyInputs from "./Inputs/VacancyInputs"

interface DemandProps {
  innovation?: Innovation
}

const Demand: FC<DemandProps> = ({ innovation }) => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedDemands } = innovationApi.useGetDemandsQuery()

  const [vacancies, setVacancies] = useState<InnovationVacancy[]>(
    () => innovation?.vacancies ?? [],
  )

  const [selectedDemands, , handleSelectedDemandsChange] = useCheckboxList(
    innovation?.demands.map((demand) => demand.id_demands) ?? [],
  )

  const generateVacancy = (): InnovationVacancy => ({
    id_vacancies: Math.random(),
    vacancy_name: "",
    description: "",
    amount: 1,
  })

  const addVacancy = () => {
    setVacancies([...vacancies, generateVacancy()])
  }

  const removeVacancy = (deleteId: number) => {
    setVacancies(
      vacancies.filter((vacancy) => vacancy.id_vacancies !== deleteId),
    )
  }

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Запрос
      </Typography>

      {fetchedDemands?.data && (
        <CheckboxList
          title="Потребность"
          items={fetchedDemands.data}
          property="id_demands"
          name="demand_name"
          selectedIds={selectedDemands}
          onSelect={handleSelectedDemandsChange}
          collapsed={false}
          width="100%"
          nameFormProp="demands"
          unsorted
        />
      )}

      {defaultFields?.required_resources?.active && (
        <InputGroup
          title={defaultFields.required_resources.label}
          textFieldProps={{
            name: "required_resources",
            defaultValue: innovation?.required_resources,
            multiline: true,
            rows: 6,
          }}
        />
      )}

      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="info" fullWidth onClick={addVacancy}>
          Добавить вакансию
        </Button>
      </Box>

      {vacancies.map((vacancy, index) => (
        <VacancyInputs
          key={vacancy.id_vacancies}
          defaultVacancy={vacancy}
          label={`Вакансия ${index + 1}`}
          name={`vacancy_${index}`}
          onRemove={removeVacancy}
        />
      ))}
    </>
  )
}

export default Demand
