import { IDefaultSearch } from "./common"

export interface NewsSearch extends IDefaultSearch {
  news_categories: string
  title: string
}

export interface NewsCategory {
  id_news_categories: number
  title: string
  description?: string
  keywords?: string
  alt_name: string
}

export interface NewsStatus {
  id_news_statuses: number
  status_name: string
}

export interface News {
  id_news: number
  logo: string | null
  title: string
  status_name: string
  alt_name: string
  keywords?: string
  description: string | null
  short_story: string | null
  full_story: string | null
  logo_thumb: string | null
  logo_selected?: string
  id_news_statuses: number
  news_statuses_id_news_statuses: number
  date_creation: string
  date_creation_dd: string
  news_categories: NewsCategory[]
  related: News[]

  title_meta: string | null
  keywords_meta: string | null
  description_meta: string | null
}

export interface NewsList {
  list: News[]
  count: number
}

export interface NewsSave {
  title: string
  id_news: number
  short_story?: string
  full_story?: string
  description?: string
  keywords?: string
  alt_name?: string
  logo?: string
  title_meta?: string
  keywords_meta?: string
  description_meta?: string
  date_creation?: string
  news_categories_id?: string
  id_news_statuses?: number
}

export enum NEWS_SORTS {
  id_news = "id_news",
  title = "title",
  status_name = "status_name",
  date_creation = "date_creation",
}

export type NewsSortsKeys = keyof typeof NEWS_SORTS

export interface CreatedNews {
  id_news: string
  status: string
}

export interface ChangeNewsStatus {
  id_news: number
  id_news_statuses: number
}
