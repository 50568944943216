import { InnovationVacancy } from "../types/innovations"

type VacancyWithoutId = Omit<InnovationVacancy, "id_vacancies">

export function getVacancies(): VacancyWithoutId[] {
  const vacancyCards = document.querySelectorAll(".vacancy")
  const vacancies: VacancyWithoutId[] = []

  const keys: ["vacancy_name", "description", "amount"] = [
    "vacancy_name",
    "description",
    "amount",
  ]

  vacancyCards.forEach((vacancy, index) => {
    const inputs = vacancy.querySelectorAll("input[name]")
    const inputValues: VacancyWithoutId = {} as VacancyWithoutId

    inputs.forEach((element, index) => {
      const value = keys[index]

      const input = element as HTMLInputElement

      if (!input) return

      if (value === "amount") {
        inputValues[value] = +input.value

        return
      }

      inputValues[value] = input.value
    })

    vacancies.push(inputValues)
  })

  return vacancies
}
