import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { Box, Container, Typography } from "@mui/material"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import InnovationsToolbar from "../components/toolbars/InnovationsToolbar"
import Spinner from "../components/Spinner"
import ItemsManage from "../components/ItemsManage"
import ItemsTable from "../components/ItemsTable"
import { INNOVATIONS_PAGE_ROUTE } from "../consts/routes"
import { innovationApi } from "../services/innovationService"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import {
  INNOVATION_SORTS,
  InnovationShort,
  InnovationSortsKeys,
} from "../types/innovations"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { useDebounce } from "../hooks/useDebounce"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import DeleteModal from "../components/modals/DeleteModal"
import CreateModal from "../components/modals/CreateModal"

const InnovationsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchInnovations, { data: fetchedInnovations, isFetching }] =
    innovationApi.useLazyGetInnovationsQuery()
  const { data: fetchedStatuses } = innovationApi.useGetStatusesQuery()
  const [deleteInnovation] = innovationApi.useDeleteInnovationMutation()
  const [changeStatuses] = innovationApi.useChangeStatusesMutation()
  const [createInnovation, { data: createdInnovation }] =
    innovationApi.useCreateInnovationMutation()

  const filter = useTypedSelector((state) => state.filterInnovationReducer)
  const selectedInnovations = useTypedSelector(
    (state) => state.selectReducer.innovations,
  )
  const { deleteModalOpen, createModalOpen } = useTypedSelector(
    (state) => state.modalReducer.innovations,
  )
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: INNOVATION_SORTS.id_innovations, headerName: "ID" },
      { field: INNOVATION_SORTS.innovation_name, headerName: "Название" },
      { field: INNOVATION_SORTS.status_name, headerName: "Статус" },
      { field: INNOVATION_SORTS.id_trls, headerName: "TRL" },
      { field: INNOVATION_SORTS.budget, headerName: "Бюджет" },
      { field: INNOVATION_SORTS.date_creation, headerName: "Добавлено" },
    ],
    [],
  )

  const rowTransform = (innovations: InnovationShort[]) => {
    return innovations.map((innovation) => ({
      ...innovation,
      id: innovation.id_innovations,
      date_creation: <SpanNoWrap>{innovation.date_creation_dd}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedInnovations?.data) return []

    return rowTransform(fetchedInnovations.data)
  }, [fetchedInnovations?.data])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id_innovations)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedInnovations?.count ?? 0) / filter.page_size),
    [fetchedInnovations?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${INNOVATION_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(() => getQueryString(filter), [filter])

  const onAllRowsSelect = () => {
    if (selectedInnovations.length !== rows.length) {
      actions.setSelectAll({ key: "innovations", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "innovations", value: [] })
    }
  }

  const onDelete = () => {
    deleteInnovation(selectedInnovations).then(() => {
      toast.success("Записи удалены")
      actions.clearSelection("innovations")
    })
  }

  const onCreate = (name: string) => {
    createInnovation(name)

    actions.setIsCreateModalOpen({ key: "innovations", value: false })
  }

  const onStatusChange = (statusId: number) => {
    changeStatuses(
      selectedInnovations.map((id) => ({
        id_innovations: id,
        id_innovation_statuses: statusId,
      })),
    ).then(() => {
      toast.success("Статус успешно изменен")
      actions.clearSelection("innovations")
    })
  }

  const onSortChange = (property: string) => {
    actions.setInnovationOrder(property as InnovationSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setInnovationPage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setInnovationPageSize(amount),
    [actions],
  )

  const fetchInnovationsFunc = useCallback(() => {
    fetchInnovations({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        innovation_name: debouncedFilter.search,
        sort: debouncedSort,
        innovation_markets: debouncedFilter.innovation_markets.join(","),
        industries: debouncedFilter.industries.join(","),
        status: debouncedFilter.status.join(","),
        fundraising: debouncedFilter.fundraising ? true : undefined,
        legal_entity: debouncedFilter.legal_entity ? true : undefined,
      },
    })
  }, [debouncedFilter, debouncedSort, fetchInnovations])

  useEffect(() => {
    actions.setInnovationFilter(
      getObjectFromQuery(
        location.search,
        ["innovation_markets", "industries", "status"],
        ["legal_entity", "fundraising"],
      ),
    )
  }, [actions, location.search])

  useEffect(() => {
    fetchInnovationsFunc()
  }, [fetchInnovationsFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    createdInnovation &&
      navigate(INNOVATIONS_PAGE_ROUTE + `/${createdInnovation.id_innovations}`)
  }, [createdInnovation, navigate])

  return (
    <Layout
      title="Инновационные разработки - ЦТТ Админ-панель"
      tabKey="innovations"
    >
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <InnovationsToolbar title="Инновационные разработки" />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="innovations"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedInnovations.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                rows={rows}
                columns={COLUMNS}
                type="innovations"
                order={filter.order}
                orderBy={filter.orderBy}
                route={INNOVATIONS_PAGE_ROUTE}
                onAllSelect={onAllRowsSelect}
                onSortChange={onSortChange}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <CreateModal
        title="Добавить инновационную разработку"
        isOpen={createModalOpen}
        onClose={() =>
          actions.setIsCreateModalOpen({ key: "innovations", value: false })
        }
        onSubmit={onCreate}
      />
      <ChangeStatusModal
        title="Изменить статус"
        property="id_innovation_statuses"
        name="status_name"
        isOpen={isChangeStatusModalOpen}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        statuses={fetchedStatuses?.data ?? []}
        onSubmit={onStatusChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранные разработки?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "innovations", value: false })
        }
      />
    </Layout>
  )
}

export default InnovationsPage
