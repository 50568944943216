import { FC, PropsWithChildren, useMemo, useState } from "react"
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material"

import ShowMoreArrow from "../../icons/ShowMoreArrow"
import ShowLessArrow from "../../icons/ShowLessArrow"
import TrashBin from "../../icons/TrashBin"
import { LegendStyled } from "../styled/StyledComponents"

interface CheckboxListProps {
  title: string
  items: any[]
  property: string
  name: string
  selectedIds: number[]
  width?: string
  collapsed?: boolean
  isControlled?: boolean
  onDelete?: (id: number) => void
  onSelect?: (id: number) => void
  nameFormProp?: string
  unsorted?: boolean
}

const CheckboxList: FC<PropsWithChildren<CheckboxListProps>> = ({
  title,
  items,
  property,
  name,
  selectedIds,
  isControlled,
  children,
  collapsed,
  onDelete,
  onSelect,
  width,
  nameFormProp,
  unsorted,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(() => collapsed ?? true)
  const [hoveredId, setHoveredId] = useState<number | null>(null)

  const sorted = useMemo(() => {
    if (unsorted) return items

    const copy = [...items]

    return copy.sort((prev, cur) =>
      Number(
        selectedIds.includes(prev[property]) >
          selectedIds.includes(cur[property]),
      ) === 0
        ? 1
        : -1,
    )
  }, [items, property, selectedIds, unsorted])

  return (
    <>
      <FormLabel
        component={LegendStyled}
        sx={{ cursor: "pointer", mt: "25px", width: "70%", mb: "10px" }}
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <span style={{ marginRight: 5 }}>{title}</span>
        <IconButton aria-expanded={!isCollapsed}>
          {isCollapsed ? <ShowMoreArrow /> : <ShowLessArrow />}
        </IconButton>
      </FormLabel>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {children}
        <FormGroup
          sx={{
            paddingLeft: "15px",
            borderRadius: "8px",
            border: "1px solid #e5e8ef",
            width: width ? width : "70%",
          }}
        >
          {sorted.length ? (
            sorted.map((item, index) => (
              <FormControlLabel
                key={property ? item[property] : Math.random()}
                control={
                  <Checkbox
                    checked={selectedIds.includes(
                      property ? item[property] : index + 1,
                    )}
                    onChange={() =>
                      onSelect && onSelect(property ? item[property] : index)
                    }
                    size="small"
                    name={nameFormProp}
                    inputProps={{
                      // @ts-ignore
                      "data-id": property ? item[property] : index + 1,
                    }}
                  />
                }
                label={
                  isControlled ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      <Typography component="span">{item[name]}</Typography>
                      <IconButton
                        sx={{
                          display: item[property] === hoveredId ? "" : "none",
                        }}
                        onClick={() => onDelete && onDelete(item[property])}
                      >
                        <TrashBin />
                      </IconButton>
                    </Box>
                  ) : name ? (
                    item[name]
                  ) : (
                    item
                  )
                }
                onMouseEnter={() =>
                  isControlled && setHoveredId(item[property])
                }
                onMouseLeave={() => isControlled && setHoveredId(null)}
              />
            ))
          ) : (
            <Typography>Ничего не найдено</Typography>
          )}
        </FormGroup>
      </Collapse>
    </>
  )
}

export default CheckboxList
