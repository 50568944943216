import { FC, useState } from "react"
import { Typography } from "@mui/material"

import {
  Innovation,
  InnovationAdditionalField,
  InnovationAdditionalFieldSet,
  InnovationData,
} from "../../types/innovations"
import CheckboxList from "../groupedInput/CheckboxList"
import InputGroup from "../groupedInput/InputGroup"

import InnovationSelect from "./Inputs/InnovationSelect"

interface AdditionalInfoProps {
  innovation?: Innovation
  additionalFields: InnovationAdditionalField[]
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({
  innovation,
  additionalFields,
}) => {
  const [idToArray, setIdToArray] = useState(() => {
    if (!innovation) return {}

    const idToArray: Record<string, InnovationData> = {}

    additionalFields.forEach((field) => {
      if (field.id_field_types !== 3) return

      idToArray[field.id_innovation_fields] = {
        id_innovation_data: 0,
        id_innovation_fields: field.id_innovation_fields,
        id_field_types: field.id_field_types,
        data: [],
      }
    })

    innovation.innovation_data.forEach((data) => {
      if (data.id_field_types !== 3) return

      idToArray[data.id_innovation_fields] = data
    })

    return idToArray
  })

  const getInnovationData = (idInnovationField: number) => {
    return innovation?.innovation_data.find(
      (data) => data.id_innovation_fields === idInnovationField,
    )?.data
  }

  const getSelectDefaultValue = (field: InnovationAdditionalField) => {
    if (!field) return

    if (field.id_field_types !== 2) return

    const innovationData = getInnovationData(field.id_innovation_fields)

    if (!innovationData || typeof innovationData === "string") return

    if (!innovationData.length) return

    const set = field.sets?.find(
      (set) => set.id_field_sets === innovationData[0].id_field_sets,
    )

    if (!set) return

    return set.id_field_sets
  }

  const handleSelect = (id: number, idInnovationField: number) => {
    const array = idToArray[idInnovationField]
      ?.data as InnovationAdditionalFieldSet[]

    if (!array) return

    if (!!array.find((v) => v.id_field_sets === id)) {
      setIdToArray({
        ...idToArray,
        [idInnovationField]: {
          ...idToArray[idInnovationField],
          data: array.filter((val) => val.id_field_sets !== id),
        },
      })
    } else {
      setIdToArray({
        ...idToArray,
        [idInnovationField]: {
          ...idToArray[idInnovationField],
          data: [...array, { id_field_sets: id, set_value: "" }],
        },
      })
    }
  }

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ mt: 5 }}>
        Дополнительная информация
      </Typography>

      {additionalFields.map((field) => {
        const fieldType = field.id_field_types

        if (fieldType === 1) {
          return (
            <InputGroup
              key={field.id_innovation_fields}
              title={field.label}
              textFieldProps={{
                name: field.field_name,
                defaultValue:
                  (innovation?.innovation_data.find(
                    (data) =>
                      data.id_innovation_fields === field.id_innovation_fields,
                  )?.data as string) ?? "",
                multiline: true,
                rows: 6,
              }}
            />
          )
        }

        if (fieldType === 2) {
          return (
            <InnovationSelect
              key={field.id_innovation_fields}
              name={field.field_name}
              title={field.label}
              values={field.sets ?? []}
              idProp="id_field_sets"
              valueProp="set_value"
              defaultValue={getSelectDefaultValue(field)}
            />
          )
        }

        if (fieldType === 3) {
          return (
            <CheckboxList
              key={field.id_innovation_fields}
              title={field.label}
              items={field.sets ?? []}
              property="id_field_sets"
              name="set_value"
              selectedIds={
                (
                  idToArray[field.id_innovation_fields]
                    ?.data as InnovationAdditionalFieldSet[]
                )?.map((str) => str.id_field_sets) ?? []
              }
              onSelect={(id) => handleSelect(id, field.id_innovation_fields)}
              collapsed={false}
              width="100%"
              nameFormProp={field.field_name}
              unsorted
            />
          )
        }

        return (
          <div
            key={field.id_innovation_fields}
            style={{ display: "none" }}
          ></div>
        )
      })}
    </>
  )
}

export default AdditionalInfo
