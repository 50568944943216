import { FC, PropsWithChildren } from "react"
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"

import { theme } from "../theme"
import HeadMetaComponent from "../components/HeadMetaComponent"

import DashboardLayout from "./dashboard/DashboardLayout"

interface LayoutProps {
  title: string
  tabKey: string
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  title,
  children,
  tabKey,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <HeadMetaComponent title={title ?? "ЦТТ Админ-панель"} />
      <CssBaseline />
      <DashboardLayout tabKey={tabKey}>{children}</DashboardLayout>
    </ThemeProvider>
  )
}

export default Layout
