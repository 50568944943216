import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { Box, Container, Typography } from "@mui/material"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import SupportsToolbar from "../components/toolbars/SupportsToolbar"
import ItemsTable from "../components/ItemsTable"
import CreateModal from "../components/modals/CreateModal"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { supportApi } from "../services/supportService"
import { SUPPORTS_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import ItemsManage from "../components/ItemsManage"
import { Support, SUPPORT_SORTS, SupportSortsKeys } from "../types/supports"
import Spinner from "../components/Spinner"
import DeleteModal from "../components/modals/DeleteModal"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"

const SupportsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchSupports, { data: fetchedSupports, isFetching }] =
    supportApi.useLazyGetSupportsQuery()
  const { data: fetchedStatuses } = supportApi.useGetSupportStatusesQuery()
  const [createSupport, { data: createdSupport }] =
    supportApi.useCreateSupportMutation()
  const [deleteSupports] = supportApi.useDeleteSupportsMutation()
  const [changeStatuses] = supportApi.useChangeStatusesMutation()

  const filter = useTypedSelector((state) => state.filterSupportReducer)
  const selectedSupports = useTypedSelector(
    (state) => state.selectReducer.supports,
  )
  const { deleteModalOpen, createModalOpen } = useTypedSelector(
    (state) => state.modalReducer.supports,
  )
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: SUPPORT_SORTS.id_supports, headerName: "ID" },
      { field: SUPPORT_SORTS.support_name, headerName: "Название" },
      { field: SUPPORT_SORTS.status_name, headerName: "Статус" },
      { field: SUPPORT_SORTS.amount, headerName: "Размер" },
      { field: SUPPORT_SORTS.date_creation, headerName: "Добавлено" },
      { field: SUPPORT_SORTS.date_end, headerName: "Дедлайн" },
    ],
    [],
  )

  const rowTransform = (measures: Support[]) => {
    return measures.map((measure) => ({
      ...measure,
      id: measure.id_supports,
      support_name: measure.support_name_manual || measure.support_name,
      amount:
        measure.amount !== null ? (
          <SpanNoWrap>{`${measure.amount} т.р.`}</SpanNoWrap>
        ) : (
          <span />
        ),
      date_creation: <SpanNoWrap>{measure.date_creation_dd}</SpanNoWrap>,
      date_end: <SpanNoWrap>{measure.date_end}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedSupports) return []

    return rowTransform(fetchedSupports.list)
  }, [fetchedSupports])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id_supports)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedSupports?.count ?? 0) / filter.page_size),
    [fetchedSupports?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${SUPPORT_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(() => getQueryString(filter), [filter])

  const onAllRowsSelect = () => {
    if (selectedSupports.length !== rows.length) {
      actions.setSelectAll({ key: "supports", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "supports", value: [] })
    }
  }

  const onCreate = (name: string) => {
    createSupport(name).then(() => {
      toast.success("Мера поддержки успешно создана!")
      actions.setIsCreateModalOpen({ key: "supports", value: false })
    })
  }

  const onDelete = () => {
    deleteSupports(selectedSupports).then(() => {
      toast.success("Записи удалены")
      actions.clearSelection("supports")
    })
  }

  const onStatusChange = (statusId: number) => {
    changeStatuses(
      selectedSupports.map((id) => ({
        id_supports: id,
        id_support_statuses: statusId,
      })),
    ).then(() => {
      toast.success("Статус успешно изменен")
      actions.clearSelection("supports")
    })
  }

  const onSortChange = (property: string) => {
    actions.setSupportOrder(property as SupportSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setSupportPage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setSupportPageSize(amount),
    [actions],
  )

  const fetchSupportsFunc = useCallback(() => {
    fetchSupports({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        support_forms: debouncedFilter.forms.join(","),
        support_directions: debouncedFilter.directions.join(","),
        support_members: debouncedFilter.members.join(","),
        support_reasons: debouncedFilter.reasons.join(","),
        status: debouncedFilter.statuses.join(","),
        support_name: debouncedFilter.search,
        sort: debouncedSort,
      },
    })
  }, [fetchSupports, debouncedFilter, debouncedSort])

  useEffect(() => {
    actions.setSupportFilter(
      getObjectFromQuery(location.search, [
        "forms",
        "directions",
        "members",
        "reasons",
        "statuses",
      ]),
    )
  }, [actions, location.search])

  useEffect(() => {
    fetchSupportsFunc()
  }, [fetchSupportsFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    createdSupport &&
      navigate(SUPPORTS_PAGE_ROUTE + `/${createdSupport.id_supports}`)
  }, [createdSupport, navigate])

  return (
    <Layout title="Меры поддержки - ЦТТ Админ-панель" tabKey="support_measures">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <SupportsToolbar title="Меры поддержки" />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="supports"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedSupports.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                rows={rows}
                columns={COLUMNS}
                type="supports"
                order={filter.order}
                orderBy={filter.orderBy}
                route={SUPPORTS_PAGE_ROUTE}
                onAllSelect={onAllRowsSelect}
                onSortChange={onSortChange}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <CreateModal
        title="Добавить меру поддержки"
        isOpen={createModalOpen}
        onClose={() =>
          actions.setIsCreateModalOpen({ key: "supports", value: false })
        }
        onSubmit={onCreate}
      />
      <ChangeStatusModal
        title="Изменить статус"
        property="id_support_statuses"
        name="status_name"
        isOpen={isChangeStatusModalOpen}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        statuses={fetchedStatuses?.data ?? []}
        onSubmit={onStatusChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранные меры поддержки?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "supports", value: false })
        }
      />
    </Layout>
  )
}

export default SupportsPage
