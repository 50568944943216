import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import {
  InnovationAdditionalField,
  InnovationDefaultField,
} from "../types/innovations"

import { getMembers } from "./getMembers"
import { getVacancies } from "./getVacancies"
import { getFormCheckboxValuesByName } from "./getFormCheckboxValuesByName"
import { getFilesArrayFromInputByName } from "./getFilesArrayFromInputByName"
import { getSelectedTitleByName } from "./getSelectedTitleByName"

dayjs.extend(utc)

export async function getInnovationFromForm(
  formValues: Record<string, FormDataEntryValue>,
  defaultFields: Record<string, InnovationDefaultField>,
  additionalFields: InnovationAdditionalField[],
): Promise<Record<string, any>> {
  return {
    id_innovation_statuses: +formValues.id_innovation_statuses,
    date_creation: formValues.date_creation
      ? dayjs(formValues.date_creation as string)
          .utc()
          .format("YYYY-MM-DDTHH:mm")
      : undefined,

    innovation_name: formValues.innovation_name as string,
    wallpaper: (await getFilesArrayFromInputByName("wallpaper"))[0]?.file,
    logo: (await getFilesArrayFromInputByName("logo"))[0]?.file,
    description: defaultFields.description.active
      ? (formValues.description as string)
      : undefined,
    keywords: defaultFields.keywords.active
      ? (formValues.keywords as string).replaceAll(" ", ",")
      : undefined,
    id_stages: +formValues.id_stages,
    site: defaultFields.site.active ? (formValues.site as string) : undefined,

    members: await getMembers(),
    count_members: defaultFields.count_members.active
      ? +(formValues.count_members as string)
      : undefined,

    industries: getFormCheckboxValuesByName("industries").map(({ id }) => ({
      id_industries: id,
    })),
    innovation_markets: getFormCheckboxValuesByName("innovation_markets").map(
      ({ id }) => ({ id_innovation_markets: id }),
    ),
    id_trls: +formValues.id_trls,
    id_innovation_directions: +formValues.id_innovation_directions,
    technologies: getFormCheckboxValuesByName("technologies").map(({ id }) => ({
      id_technologies: id,
    })),
    target_product: defaultFields.target_product.active
      ? (formValues.target_product as string)
      : undefined,
    slider_images: (await getFilesArrayFromInputByName("slider_images", 3)).map(
      (file) => file.file,
    ),
    start_date: defaultFields.start_date.active
      ? (formValues.start_date as string)
      : undefined,
    first_sale_date: defaultFields.first_sale_date.active
      ? (formValues.first_sale_date as string)
      : undefined,
    budget: defaultFields.budget.active ? +formValues.budget : undefined,
    financing: defaultFields.financing.active
      ? formValues.financing === "1"
      : undefined,
    fundraising:
      defaultFields.fundraising.active && formValues.financing === "1"
        ? +formValues.fundraising
        : undefined,
    finance_sources: defaultFields.finance_sources.active
      ? getFormCheckboxValuesByName("finance_sources").map(({ id }) => ({
          id_finance_sources: id,
        }))
      : undefined,
    expected_income: defaultFields.expected_income.active
      ? +formValues.expected_income
      : undefined,
    intellectual_property: defaultFields.intellectual_property.active
      ? formValues.intellectual_property === "1"
      : undefined,
    detailed_info: defaultFields.detailed_info.active
      ? (formValues.detailed_info as string)
      : undefined,
    objectives: [
      formValues.objectives_1 as string,
      formValues.objectives_2 as string,
      formValues.objectives_3 as string,
    ],
    expected_consumer: defaultFields.expected_consumer.active
      ? (formValues.expected_consumer as string)
      : undefined,
    expected_effect: defaultFields.expected_effect.active
      ? (formValues.expected_effect as string)
      : undefined,
    legal_entity: defaultFields.legal_entity.active
      ? formValues.legal_entity === "1"
      : undefined,

    demands: getFormCheckboxValuesByName("demands").map(({ id }) => ({
      id_demands: id,
    })),
    required_resources: defaultFields.required_resources.active
      ? (formValues.required_resources as string)
      : undefined,
    vacancies: getVacancies(),

    innovation_data: additionalFields.map((field) => ({
      id_innovation_fields: field.id_innovation_fields,
      id_field_types: field.id_field_types,
      data:
        field.id_field_types === 1
          ? (formValues[field.field_name] as string)
          : field.id_field_types === 2
          ? [
              {
                id_field_sets: +formValues[field.field_name],
                set_value: getSelectedTitleByName(field.field_name),
              },
            ]
          : getFormCheckboxValuesByName(field.field_name, field.sets ?? []).map(
              ({ id, value }) => ({ id_field_sets: id, set_value: value }),
            ),
    })),

    presentation: defaultFields.presentation.active
      ? (await getFilesArrayFromInputByName("presentation"))[0]
      : undefined,
    video_presentation_link: defaultFields.video_presentation_link.active
      ? (formValues.video_presentation_link as string)
      : undefined,
  }
}
