import { IDefaultSearch } from "./common"

export interface StaticSearch extends IDefaultSearch {
  title: string
}

export interface StaticStatus {
  id_static_page_statuses: number
  status_name: string
}

export interface Static {
  id_static_pages: number
  title: string
  status_name: string
  alt_name: string
  content: string | null
  id_static_page_statuses: number
  static_page_statuses_id_static_page_statuses: number
  date_creation: string
  date_creation_dd: string

  title_meta: string | null
  keywords_meta: string | null
  description_meta: string | null
}

export interface Statics {
  list: Static[]
  count: number
}

export enum STATIC_SORTS {
  id_static_pages = "id_static_pages",
  title = "title",
  status_name = "status_name",
  date_creation = "date_creation",
}

export type StaticSortsKeys = keyof typeof STATIC_SORTS

export interface StaticSave {
  id_static_pages: number
  title: string
  content?: string
  alt_name?: string
  title_meta?: string
  keywords_meta?: string
  description_meta?: string
  date_creation?: string
  id_static_page_statuses?: number
}

export interface CreatedStatic {
  id_static_pages: string
  status: string
}

export interface ChangeStaticStatus {
  id_static_pages: number
  id_static_page_statuses: number
}
