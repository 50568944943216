import {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { toast } from "react-toastify"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

import {
  InnovationAdditionalField,
  InnovationAdditionalFieldSet,
} from "../../types/innovations"
import InputGroup from "../groupedInput/InputGroup"
import { innovationApi } from "../../services/innovationService"
import SelectGroup from "../groupedInput/SelectGroup"
import { SelectChange } from "../../types/SelectGroupChangeEventType"
import { miscApi } from "../../services/miscService"

interface AdditionalFieldModalProps {
  type: "add" | "edit"
  onClose: () => void
  field?: InnovationAdditionalField
}

const AdditionalFieldModal: FC<AdditionalFieldModalProps> = ({
  type,
  onClose,
  field,
}) => {
  const { data: fieldTypes } = miscApi.useGetFieldTypesQuery()
  const [
    editAdditionalField,
    { data: editFieldData, isLoading: isEditLoading },
  ] = innovationApi.useSaveAdditionalFieldMutation()
  const [
    createAdditionalField,
    { data: createFieldData, isLoading: isCreateLoading },
  ] = innovationApi.useCreateAdditionalFieldMutation()

  const [fieldTitle, setFieldTitle] = useState(() => field?.label ?? "")
  const [fieldType, setFieldType] = useState(() => field?.id_field_types ?? 1)
  const [fieldTooltip, setFieldTooltip] = useState(() => field?.tooltip ?? "")
  const [selectSet, setSelectSet] = useState<InnovationAdditionalFieldSet[]>(
    () => field?.sets ?? [],
  )

  const publicCheckboxRef = useRef<HTMLInputElement>(null)
  const visibleCheckboxRef = useRef<HTMLInputElement>(null)
  const requiredCheckboxRef = useRef<HTMLInputElement>(null)

  const sets = useMemo(() => {
    if (fieldType !== 2 && fieldType !== 3) return undefined

    return selectSet.filter((set) => set.set_value.trim().length)
  }, [fieldType, selectSet])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!fieldTitle) {
      return toast.warning("Введите название поля")
    }

    if (!fieldType) {
      return toast.warning("Укажите тип поля")
    }

    if (type === "add") {
      createAdditionalField({
        id_field_types: fieldType,
        label: fieldTitle,
        tooltip: fieldTooltip,
        public: publicCheckboxRef.current?.checked ?? false,
        visible: visibleCheckboxRef.current?.checked ?? false,
        required: requiredCheckboxRef.current?.checked ?? false,
        sets: sets?.map((set) => set.set_value),
      }).then(() => {
        onClose()
      })

      return
    }

    if (!field) return

    if (type === "edit") {
      const currentSets = sets?.map((set) => ({
        id_field_sets:
          set.id_field_sets === Math.trunc(set.id_field_sets)
            ? set.id_field_sets
            : undefined,
        set_value: set.set_value,
      }))

      currentSets
        ?.filter((set) => !set.id_field_sets)
        .forEach((set) => {
          const foundSet = field.sets?.find(
            (fieldSet) => fieldSet.set_value === set.set_value,
          )

          if (foundSet) {
            set.id_field_sets = foundSet.id_field_sets
          }
        })

      editAdditionalField({
        id_innovation_fields: field.id_innovation_fields,
        id_field_types: fieldType,
        label: fieldTitle,
        tooltip: fieldTooltip,
        public: publicCheckboxRef.current?.checked ?? false,
        visible: visibleCheckboxRef.current?.checked ?? false,
        required: requiredCheckboxRef.current?.checked ?? false,
        sets: currentSets,
      }).then(() => {
        onClose()
      })
    }
  }

  const onTypeChange = useCallback((e: SelectChange) => {
    const { value } = e.target

    if (typeof value !== "number") return

    setFieldType(value)
  }, [])

  useEffect(() => {
    if (!createFieldData) return

    if (createFieldData.status === "error") {
      toast.error("Неизвестная ошибка")
      return
    }

    if (createFieldData.status === "successful") {
      toast.success("Поле успешно добавлено")
    }
  }, [createFieldData])

  useEffect(() => {
    if (!editFieldData) return

    if (editFieldData.status === "error") {
      toast.error("Неизвестная ошибка")
      return
    }

    if (editFieldData.status === "successful") {
      toast.success("Сохранено")
    }
  }, [editFieldData])

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
          maxHeight: "100vh",
        }}
      >
        <form onSubmit={onSubmit}>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            {type === "add" ? "Добавление нового поля" : "Редактирование поля"}
          </Typography>
          <InputGroup
            title="Название"
            value={fieldTitle}
            onChange={(e) => setFieldTitle(e.target.value)}
          />
          <FormGroup sx={{ my: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={field?.public ?? false}
                  inputRef={publicCheckboxRef}
                />
              }
              label="публичное"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={field?.visible ?? false}
                  inputRef={visibleCheckboxRef}
                />
              }
              label="видимое для всех"
            />
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={field?.required ?? false}
                  inputRef={requiredCheckboxRef}
                />
              }
              label="обязательное"
            />
          </FormGroup>
          <InputGroup
            title="Подсказка"
            value={fieldTooltip}
            onChange={(e) => setFieldTooltip(e.target.value)}
          />
          <SelectGroup
            title="Тип поля"
            selectedValue={fieldTypes ? fieldType.toString() : ""}
            onChange={onTypeChange}
            values={fieldTypes?.data ?? []}
            property="id_field_types"
            name="description"
          />
          {(fieldType === 2 || fieldType === 3) && (
            <>
              <Typography sx={{ mt: 2 }}>Строки для выбора</Typography>
              <Button
                variant="outlined"
                color="success"
                size="small"
                sx={{ mt: 1 }}
                onClick={() =>
                  setSelectSet([
                    ...selectSet,
                    { id_field_sets: Math.random(), set_value: "" },
                  ])
                }
              >
                Добавить строку
              </Button>
              {selectSet.map((selectValue) => (
                <Box
                  sx={{
                    my: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                  key={selectValue.id_field_sets}
                >
                  <TextField
                    fullWidth
                    size="small"
                    autoComplete="off"
                    value={selectValue.set_value}
                    onChange={(e) =>
                      setSelectSet(
                        selectSet.map((select) => {
                          if (
                            select.id_field_sets === selectValue.id_field_sets
                          ) {
                            return { ...select, set_value: e.target.value }
                          }

                          return select
                        }),
                      )
                    }
                  />
                  <IconButton
                    color="error"
                    onClick={() =>
                      setSelectSet(
                        selectSet.filter(
                          (select) =>
                            select.id_field_sets !== selectValue.id_field_sets,
                        ),
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </>
          )}
          <br />
          {!(isEditLoading || isCreateLoading) && (
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              sx={{ mt: 3 }}
            >
              {type === "add" ? "Создать" : "Сохранить"}
            </Button>
          )}
        </form>
      </Box>
    </Modal>
  )
}

export default AdditionalFieldModal
