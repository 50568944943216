import { FC, memo } from "react"
import {
  Box,
  Chip,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
} from "@mui/material"

import { SelectChange } from "../../types/SelectGroupChangeEventType"
import { LegendStyled } from "../styled/StyledComponents"

interface SelectGroupProps {
  title: string
  values: any[]
  selectedArray?: string[] | number[]
  selectedValue?: string
  property: string
  name: string
  onChange: (e: SelectChange) => void
  multiple?: boolean
  sx?: SxProps
}

const SelectGroup: FC<SelectGroupProps> = ({
  values,
  title,
  property,
  name,
  multiple,
  selectedArray,
  selectedValue,
  onChange,
  ...props
}) => {
  const value = multiple ? selectedArray ?? [] : selectedValue ?? ""

  const selectedName = (id: number | string) => {
    if (!values.length) return ""

    if (typeof id === "string" && !isNaN(+id)) {
      id = +id
    }

    const foundValue = values.find((v) => v[property] === id)

    if (!foundValue) {
      return ""
    }

    const value: string = foundValue[name]

    return value
  }

  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <Select
        fullWidth
        size="small"
        multiple={multiple}
        value={value}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {typeof selected === "string"
              ? selectedName(selected)
              : selected.map((value) => (
                  <Chip key={value} label={selectedName(value)} />
                ))}
          </Box>
        )}
        {...props}
      >
        {values.map((value) => (
          <MenuItem key={value[property]} value={value[property]}>
            {value[name]}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default memo(SelectGroup)
