import { FC, memo, useMemo } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"

import { newsApi } from "../../services/newsService"
import { Search } from "../../icons/Search"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import SelectGroup from "../groupedInput/SelectGroup"
import { isNumberArray, isStringArray } from "../../utils/typeguard"
import { SelectChange } from "../../types/SelectGroupChangeEventType"

interface NewsToolbarProps {
  title: string
}

const NewsToolbar: FC<NewsToolbarProps> = ({ title }) => {
  const actions = useActionCreators()

  const { data: fetchedCategories } = newsApi.useGetNewsCategoriesQuery()
  const { data: fetchedStatuses } = newsApi.useGetNewsStatusesQuery()

  const search = useTypedSelector((state) => state.filterNewsReducer.search)
  const filterCategories = useTypedSelector(
    (state) => state.filterNewsReducer.tags,
  )
  const filterStatuses = useTypedSelector(
    (state) => state.filterNewsReducer.statuses,
  )

  const onModalOpen = () => {
    actions.setIsCreateModalOpen({ key: "news", value: true })
  }

  const categoriesMemo = useMemo(() => {
    return {
      selectedArray: filterCategories,
      values: fetchedCategories?.data ?? [],
      onChange: (e: SelectChange) => {
        const value = e.target.value

        if (isStringArray(value)) actions.setNewsCategories(value)
      },
    }
  }, [actions, fetchedCategories?.data, filterCategories])

  const statusesMemo = useMemo(() => {
    return {
      selectedArray: filterStatuses,
      values: fetchedStatuses?.data ?? [],
      onChange: (e: SelectChange) => {
        const value = e.target.value

        if (isNumberArray(value)) actions.setNewsStatuses(value)
      },
    }
  }, [actions, fetchedStatuses?.data, filterStatuses])

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: "10px" }}
            color="info"
            variant="outlined"
            onClick={() => actions.resetNewsFilters()}
          >
            Сбросить фильтры
          </Button>
          <Button color="primary" variant="contained" onClick={onModalOpen}>
            Создать
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Найти"
                  variant="outlined"
                  value={search}
                  onChange={(e) => actions.setNewsSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Теги"
                  property="alt_name"
                  name="title"
                  multiple
                  {...categoriesMemo}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectGroup
                  title="Статус"
                  property="id_news_statuses"
                  name="status_name"
                  multiple
                  {...statusesMemo}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default memo(NewsToolbar)
