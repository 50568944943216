import { FC, ChangeEvent } from "react"
import { FormLabel, SxProps, TextFieldProps } from "@mui/material"
import TextField from "@mui/material/TextField"

import { LegendStyled } from "../styled/StyledComponents"

interface InputGroupProps {
  title?: string
  Helper?: JSX.Element
  endAdornment?: { endAdornment: JSX.Element }
  disabled?: boolean
  sx?: SxProps
  placeholder?: string

  value?: string | number
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void

  textFieldProps?: TextFieldProps
}

const InputGroup: FC<InputGroupProps> = ({
  title,
  value,
  Helper,
  endAdornment,
  onChange,
  textFieldProps,
  ...props
}) => {
  return (
    <>
      {title && <FormLabel component={LegendStyled}>{title}</FormLabel>}
      <TextField
        size="small"
        fullWidth
        autoComplete="off"
        value={value}
        onChange={onChange}
        helperText={Helper}
        InputProps={endAdornment}
        {...textFieldProps}
        {...props}
      />
    </>
  )
}

export default InputGroup
