import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { Box, Container, Typography } from "@mui/material"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import NewsToolbar from "../components/toolbars/NewsToolbar"
import ItemsTable from "../components/ItemsTable"
import CreateModal from "../components/modals/CreateModal"
import ChangeStatusModal from "../components/modals/ChangeStatusModal"
import { SpanNoWrap } from "../components/styled/StyledComponents"
import { newsApi } from "../services/newsService"
import { NEWS_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import { News, NEWS_SORTS, NewsSortsKeys } from "../types/news"
import ItemsManage from "../components/ItemsManage"
import Spinner from "../components/Spinner"
import DeleteModal from "../components/modals/DeleteModal"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"

const NewsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [fetchNews, { data: fetchedNews, isFetching }] =
    newsApi.useLazyGetNewsQuery()
  const { data: fetchedStatuses } = newsApi.useGetNewsStatusesQuery()
  const [createNews, { data: createdNews }] = newsApi.useCreateNewsMutation()
  const [deleteNews] = newsApi.useDeleteNewsMutation()
  const [changeStatuses] = newsApi.useChangeNewsMutation()

  const filter = useTypedSelector((state) => state.filterNewsReducer)
  const selectedNews = useTypedSelector((state) => state.selectReducer.news)
  const { createModalOpen, deleteModalOpen } = useTypedSelector(
    (state) => state.modalReducer.news,
  )
  const isChangeStatusModalOpen = useTypedSelector(
    (state) => state.modalReducer.isChangeStatusModalOpen,
  )

  const COLUMNS: GridColDef[] = useMemo(
    () => [
      { field: NEWS_SORTS.id_news, headerName: "ID" },
      { field: NEWS_SORTS.title, headerName: "Заголовок" },
      { field: NEWS_SORTS.status_name, headerName: "Статус" },
      { field: NEWS_SORTS.date_creation, headerName: "Добавлено" },
    ],
    [],
  )

  const rowTransform = (news: News[]) => {
    return news.map((n) => ({
      ...n,
      id: n.id_news,
      date_creation: <SpanNoWrap>{n.date_creation}</SpanNoWrap>,
    }))
  }

  const rows = useMemo(() => {
    if (!fetchedNews) return []

    return rowTransform(fetchedNews.list)
  }, [fetchedNews])

  const rowsIds = useMemo(() => {
    return rows.map((row) => row.id_news)
  }, [rows])

  const pageCount = useMemo(
    () => Math.ceil((fetchedNews?.count ?? 0) / filter.page_size),
    [fetchedNews?.count, filter.page_size],
  )

  const sort = useMemo(() => {
    if (filter.orderBy === null) return undefined

    return `${NEWS_SORTS[filter.orderBy]}${
      filter.order === "asc" ? "" : "-inverse"
    }`
  }, [filter.order, filter.orderBy])

  const debouncedFilter = useDebounce(filter, 1000)
  const debouncedSort = useDebounce(sort, 1000)

  const query = useMemo(() => getQueryString(filter), [filter])

  const onAllRowsSelect = () => {
    if (selectedNews.length !== rows.length) {
      actions.setSelectAll({ key: "news", value: rowsIds })
    } else {
      actions.setSelectAll({ key: "news", value: [] })
    }
  }

  const onCreate = (name: string) => {
    createNews(name).then(() => {
      toast.success("Новость успешно создана!")
      actions.setIsCreateModalOpen({ key: "news", value: false })
    })
  }

  const onDelete = () => {
    deleteNews(selectedNews).then(() => {
      toast.success("Записи удалены")
      actions.clearSelection("news")
    })
  }

  const onStatusChange = (statusId: number) => {
    changeStatuses(
      selectedNews.map((id) => ({
        id_news: id,
        id_news_statuses: statusId,
      })),
    ).then(() => {
      toast.success("Статус успешно изменен")
      actions.clearSelection("news")
    })
  }

  const onSortChange = (property: string) => {
    actions.setNewsOrder(property as NewsSortsKeys)
  }

  const onPageChange = useCallback(
    (page: number) => actions.setNewsPage(page),
    [actions],
  )
  const onPageSizeChange = useCallback(
    (amount: number) => actions.setNewsPageSize(amount),
    [actions],
  )

  const fetchNewsFunc = useCallback(() => {
    fetchNews({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        news_categories: debouncedFilter.tags.join(","),
        status: debouncedFilter.statuses.join(","),
        title: debouncedFilter.search,
        sort: debouncedSort,
      },
    })
  }, [fetchNews, debouncedFilter, debouncedSort])

  useEffect(() => {
    actions.setNewsFilter(
      getObjectFromQuery(location.search, ["tags", "statuses"]),
    )
  }, [actions, location.search])

  useEffect(() => {
    fetchNewsFunc()
  }, [fetchNewsFunc])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    createdNews && navigate(NEWS_PAGE_ROUTE + `/${createdNews.id_news}`)
  }, [createdNews, navigate])

  return (
    <Layout title="Новости - ЦТТ Админ-панель" tabKey="news">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <NewsToolbar title="Новости" />
          {isFetching ? (
            <Spinner />
          ) : rows.length ? (
            <>
              <ItemsManage
                type="news"
                page={filter.page}
                pageSize={filter.page_size}
                pageCount={pageCount}
                selectedAmount={selectedNews.length}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
              />
              <ItemsTable
                type="news"
                rows={rows}
                columns={COLUMNS}
                route={NEWS_PAGE_ROUTE}
                order={filter.order}
                orderBy={filter.orderBy}
                onSortChange={onSortChange}
                onAllSelect={onAllRowsSelect}
              />
            </>
          ) : (
            <Typography variant="h6" sx={{ mt: 3, ml: "24px" }}>
              Ничего не найдено
            </Typography>
          )}
        </Container>
      </Box>
      <CreateModal
        title="Добавить новость"
        isOpen={createModalOpen}
        onClose={() =>
          actions.setIsCreateModalOpen({ key: "news", value: false })
        }
        onSubmit={onCreate}
      />
      <ChangeStatusModal
        title="Изменить статус"
        property="id_news_statuses"
        name="status_name"
        isOpen={isChangeStatusModalOpen}
        statuses={fetchedStatuses?.data ?? []}
        onClose={() => actions.setIsChangeStatusModalOpen(false)}
        onSubmit={onStatusChange}
      />
      <DeleteModal
        text="Вы точно хотите удалить выбранные новости?"
        isOpen={deleteModalOpen}
        onDelete={onDelete}
        onClose={() =>
          actions.setIsDeleteModalOpen({ key: "news", value: false })
        }
      />
    </Layout>
  )
}

export default NewsPage
