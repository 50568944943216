import { ChangeEvent, FC } from "react"
import { Box, Button, FormLabel, IconButton, Typography } from "@mui/material"

import { LegendStyled } from "../../styled/StyledComponents"
import TrashBin from "../../../icons/TrashBin"
import { InnovationSliderImage } from "../../../types/innovations"
import { HOST } from "../../../consts/hosts"

type ExtendedInnovationImage = InnovationSliderImage & { fromEdit?: boolean }

interface MultipleFileInputGroupProps {
  name: string
  title: string
  fetchedFiles: ExtendedInnovationImage[]
  onDelete: (file: ExtendedInnovationImage) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  max: number
}

const MultipleFileInputGroup: FC<MultipleFileInputGroupProps> = ({
  name,
  title,
  fetchedFiles,
  max,
  onDelete,
  onChange,
}) => {
  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
          mb: "15px",
          pl: "3px",
        }}
      >
        {fetchedFiles.map((file) => (
          <Box
            key={file.id_innovation_images}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {file.fromEdit ? (
              <Typography>{file.url_file}</Typography>
            ) : (
              <Typography
                component="a"
                href={HOST + file.url_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.url_file.split("/").at(-1)}
              </Typography>
            )}

            <IconButton color="error" onClick={() => onDelete(file)}>
              <TrashBin />
            </IconButton>
          </Box>
        ))}

        {fetchedFiles.length < 3 && (
          <Button variant="outlined" component="label" htmlFor="files">
            Загрузить {fetchedFiles.length + 1 === max ? "файл" : "файлы"}
          </Button>
        )}

        <input
          id="files"
          type="file"
          accept="image/png, image/jpeg"
          hidden
          name={name}
          onChange={onChange}
          multiple
        />
      </Box>
    </>
  )
}

export default MultipleFileInputGroup
