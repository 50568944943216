import { IDefaultSearch } from "./common"

export interface User {
  id: number
  fio: string
  email: string
  phone: string | null
  date_reg: string
  group_name: string
  groups_id_groups: number
  activate_email: boolean
  temporary: boolean | null
}

export interface DetailedUser extends User {
  date_of_birth: string | null
  locality: string | null
  place_of_work_study: string | null
  position: string | null
  id_regions: number
  region_code: number | null
  region_name: string | null
}

export interface CurrentUser {
  id: number
  id_group: number
  locality: string
  email: string
  phone: string
  date_reg: string
  date_of_birth: string
  place_of_work_study: string
  position: string
  activate_email: boolean
  temporary: boolean | null
  id_regions: number
}

export interface Users {
  list: User[]
  count: number
}

export interface SearchUser extends IDefaultSearch {
  fio: string
  phone: string
  email: string
  locality: string
  position: string
  region: string
  activate_email: boolean
  subscription: boolean
  start_date_reg: string | null
  end_date_reg: string | null
}

export interface UserGroup {
  id_groups: number
  group_name: string
}

export enum USER_SORTS {
  id = "id",
  fio = "fio",
  email = "email",
  phone = "phone",
  activate_email = "activate_email",
  date_reg = "date_reg",
}

export type UserSortsKeys = keyof typeof USER_SORTS

export interface UserFilterStateStrings {
  fio: string
  phone: string
  email: string
  locality: string
  place_of_work_study: string
  position: string
}

export interface UsersFilter extends UserFilterStateStrings {
  activate_email: boolean
  region: number[]
  subscription: boolean
  start_date_reg: string | null
  end_date_reg: string | null
}

export interface ChangeUserRole {
  id: number
  id_groups: number
}

export interface UserSubscription {
  id_subscriptions: number
  subscription_name: string
  url: string
}

export interface SaveUser {
  id: number
  fio: string
  id_regions: number
  locality: string
  date_of_birth: string | null
  place_of_work_study: string
  position: string
  phone?: string
  email: string
}

export interface ChangeSubscriptionTitleRequest {
  id_subscriptions: number
  subscription_name: string
}

export interface ExportUsersResponse {
  status: "successful" | "error"
  file: string
}
