import { FC } from "react"
import { Box, CircularProgress, SxProps } from "@mui/material"

interface SpinnerProps {
  sx?: SxProps
}

const Spinner: FC<SpinnerProps> = ({ sx }) => {
  return (
    <Box
      sx={
        sx ?? {
          position: "relative",
          top: "20px",
          left: "50%",
          transform: "translateX(-20px)",
          display: "inline-block",
        }
      }
    >
      <CircularProgress />
    </Box>
  )
}

export default Spinner
