import { FC } from "react"
import { FormLabel } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"
import { Dayjs } from "dayjs"

import { LegendStyled } from "../styled/StyledComponents"

interface DateGroupProps {
  title: string
  value: Dayjs | null
  onChange: (newValue: any) => void
  disabled?: boolean
  minDate?: Dayjs
  maxDate?: Dayjs
}

const DateGroup: FC<DateGroupProps> = ({
  title,
  value,
  onChange,
  ...props
}) => {
  return (
    <>
      <FormLabel component={LegendStyled}>{title}</FormLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          inputFormat="DD.MM.YYYY"
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          {...props}
        />
      </LocalizationProvider>
    </>
  )
}

export default DateGroup
