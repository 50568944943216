import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
  UserFilterStateStrings,
  UsersFilter,
  UserSortsKeys,
} from "../../types/users"

interface InitialState {
  filter: UsersFilter
  order: "asc" | "desc"
  orderBy: UserSortsKeys | null
  page: number
  page_size: number
}

type StringPayload = { key: keyof UserFilterStateStrings; value: string }
type DatePayload = {
  key: "start_date_reg" | "end_date_reg"
  value: string | null
}
type BooleanPayload = {
  key: "activate_email" | "subscription"
  value: boolean
}

const initialState: InitialState = {
  filter: {
    fio: "",
    phone: "",
    email: "",
    activate_email: false,
    region: [],
    locality: "",
    place_of_work_study: "",
    position: "",
    subscription: false,
    start_date_reg: null,
    end_date_reg: null,
  },
  order: "asc",
  orderBy: null,
  page: 1,
  page_size: 20,
}

export const filterUserSlice = createSlice({
  name: "filterUser",
  initialState,
  reducers: {
    setUserFilterString(state, action: PayloadAction<StringPayload>) {
      const { key, value } = action.payload

      state.filter[key] = value
    },
    setUserFilterOrder(state, action: PayloadAction<UserSortsKeys | null>) {
      const orderBy = action.payload

      if (orderBy === state.orderBy) {
        state.order = state.order === "asc" ? "desc" : "asc"

        return
      }

      state.orderBy = orderBy
      state.order = "asc"
    },
    setUserFilterRegions(state, action: PayloadAction<number[]>) {
      state.filter.region = action.payload
    },
    setUserFilterPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setUserFilterPageSize(state, action: PayloadAction<number>) {
      state.page_size = action.payload
    },
    setUserFilterDate(state, action: PayloadAction<DatePayload>) {
      const { key, value } = action.payload

      state.filter[key] = value
    },
    setUserFilterBoolean(state, action: PayloadAction<BooleanPayload>) {
      const { key, value } = action.payload

      state.filter[key] = value
    },
    setUserFilter(state, action: PayloadAction<Partial<InitialState>>) {
      for (const key in action.payload) {
        if (!key) continue

        if (initialState.hasOwnProperty(key)) {
          //@ts-ignore
          state[key] = action.payload[key]
          continue
        }

        if (initialState.filter.hasOwnProperty(key)) {
          //@ts-ignore
          state.filter[key] = action.payload[key]
        }
      }
    },
    resetUserFilters(state) {
      state.filter = initialState.filter
    },
  },
})

export const filterUserReducer = filterUserSlice.reducer
