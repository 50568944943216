import { InnovationAdditionalFieldSet } from "../types/innovations"

export function getFormCheckboxValuesByName(
  name: string,
  sets?: InnovationAdditionalFieldSet[],
) {
  const inputs = document.querySelectorAll(`[name=${name}]`)

  const values: { id: number; value: string }[] = []

  inputs.forEach((element) => {
    const input = element as HTMLInputElement

    if (!input.checked) return

    if (!input.dataset.id || !+input.dataset.id) return

    const id = +input.dataset.id
    const value = sets?.find((set) => set.id_field_sets === id)

    values.push({
      id,
      value: value?.set_value ?? "",
    })
  })

  return values
}
