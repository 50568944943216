import { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"

export function useFileSelect(initName?: string) {
  const [logoFileName, setLogoFileName] = useState(() => initName ?? "")
  const [logoFile, setLogoFile] = useState<string | null>("")

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]

    if (!file) {
      return toast.error("Файл не найден!")
    }

    setLogoFileName(file.name)

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      setLogoFile(reader.result as string)
      toast.success("Файл успешно выбран")
    }

    reader.onerror = () => {
      toast.error("Ошибка при чтении файла!")
    }
  }

  return {
    logoFileName,
    setLogoFileName,
    logoFile,
    setLogoFile,
    onFileSelect,
  }
}
