import styled from "@emotion/styled"
import { AppBar } from "@mui/material"
import { Link } from "react-router-dom"

export const SpanNoWrap = styled.span`
  white-space: nowrap;
`

export const FormStyled = styled.form`
  padding: 25px;
  text-align: left;
`

export const LegendStyled = styled.legend`
  margin: 15px 0 8px 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.6) !important;
`

export const LabelStyled = styled.div`
  text-align: left;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  position: "relative",
  paddingRight: "0 !important",
}))

export const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}))
