import { FC, useState } from "react"
import { Box, Button, Modal, TextField, Typography } from "@mui/material"

interface CreateModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (name: string) => void
}

const CreateModal: FC<CreateModalProps> = ({
  title,
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [name, setName] = useState("")

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <br />
        <TextField
          fullWidth
          placeholder="Название"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <br />
        <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
          <Button variant="outlined" color="info" onClick={onClose}>
            Отменить
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => onSubmit(name)}
          >
            Добавить
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateModal
