import {
  FORM_EDIT_ROUTE,
  INNOVATION_PAGE_ROUTE,
  INNOVATIONS_PAGE_ROUTE,
  INSTITUTE_PAGE_ROUTE,
  INSTITUTES_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  NEWS_DETAILED_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  STATIC_PAGE_ROUTE,
  STATICS_PAGE_ROUTE,
  SUPPORT_PAGE_ROUTE,
  SUPPORTS_PAGE_ROUTE,
  USER_PAGE_ROUTE,
  USERS_PAGE_ROUTE,
} from "../consts/routes"
import MainPage from "../pages/MainPage"
import LoginPage from "../pages/LoginPage"
import SupportsPage from "../pages/SupportsPage"
import InstitutesPage from "../pages/InstitutesPage"
import NewsPage from "../pages/NewsPage"
import StaticsPage from "../pages/StaticsPage"
import UsersPage from "../pages/UsersPage"
import SupportPage from "../pages/SupportPage"
import InstitutePage from "../pages/InstitutePage"
import NotFoundPage from "../pages/NotFoundPage"
import NewsDetailedPage from "../pages/NewsDetailedPage"
import StaticPage from "../pages/StaticPage"
import UserPage from "../pages/UserPage"
import InnovationsPage from "../pages/InnovationsPage"
import InnovationPage from "../pages/InnovationPage"
import FormEditorPage from "../pages/FormEditorPage"

export const publicRoutes = [
  {
    path: MAIN_PAGE_ROUTE,
    Component: MainPage,
  },
  {
    path: LOGIN_PAGE_ROUTE,
    Component: LoginPage,
  },
  {
    path: "*",
    Component: NotFoundPage,
  },
]

export const authRoutes = [
  {
    path: SUPPORTS_PAGE_ROUTE,
    Component: SupportsPage,
  },
  {
    path: SUPPORT_PAGE_ROUTE,
    Component: SupportPage,
  },
  {
    path: INSTITUTES_PAGE_ROUTE,
    Component: InstitutesPage,
  },
  {
    path: INSTITUTE_PAGE_ROUTE,
    Component: InstitutePage,
  },
  {
    path: NEWS_PAGE_ROUTE,
    Component: NewsPage,
  },
  {
    path: NEWS_DETAILED_PAGE_ROUTE,
    Component: NewsDetailedPage,
  },
  {
    path: STATICS_PAGE_ROUTE,
    Component: StaticsPage,
  },
  {
    path: STATIC_PAGE_ROUTE,
    Component: StaticPage,
  },
  {
    path: USERS_PAGE_ROUTE,
    Component: UsersPage,
  },
  {
    path: USER_PAGE_ROUTE,
    Component: UserPage,
  },
  {
    path: INNOVATIONS_PAGE_ROUTE,
    Component: InnovationsPage,
  },
  {
    path: INNOVATION_PAGE_ROUTE,
    Component: InnovationPage,
  },
  {
    path: FORM_EDIT_ROUTE,
    Component: FormEditorPage,
  },
]
